import {useState, useEffect} from "react";
import ParkingItem from "./ParkingItem";
import Pagination from "./Pagination";

import "../../Styles/Components/ParkingList/ParkingList.scss";

const ParkingList = (props) => {
  const {
    parkingListArray,
    imgArray,
    isEvent,
    rentPeriods,
    eventStartTime,
    eventEndTime,
    eventName,
    eventPrice,
    eventId,
    isLoaded = false,
  } = props;

  //  console.log("ParkingList - Consoling props  ", props);

  const [pagination, setPagination] = useState({
    currentPage: 1,
    postsPerPage: 5,
    pageNumbers: [],
    minRange: 3,
    minPages: 9,
  });

  const [currentPosts, setCurrentPosts]=useState([])

  useEffect(() => {
    if (!isEvent && isLoaded) {
      // Pushing the content up
      var position = document.getElementById("parkinglist");
      position.scrollIntoView(true);
      window.scrollBy({
        top: -50,
        left: 0,
        behaviour: "smooth",
      });
    }
  }, [pagination.currentPage]);

  useEffect(()=>{

    setCurrentPosts(
      parkingListArray?.slice(
        (pagination.currentPage - 1) * pagination.postsPerPage,
        pagination.currentPage * pagination.postsPerPage
      )
    )
  },[pagination,parkingListArray])

  // const currentPosts = parkingListArray?.slice(
  //   (pagination.currentPage - 1) * pagination.postsPerPage,
  //   pagination.currentPage * pagination.postsPerPage
  // );

 // console.log("These are",currentPosts)

  const eventParkeringList = isEvent?(
    <div className="eventParkingListComponent">
      {parkingListArray?.length > 1 ? (
        <h2 className="parkingListHeader">
          Ledige parkeringspladser til {eventName}
        </h2>
      ) : null}
      {rentPeriods?.map((rentPeriod) => {
        // console.log("Attempt",rentPeriod)
        let spotID = rentPeriod.parkingSpot.parkingSpotId;
        let img;
        imgArray.forEach((element) => {
          if (spotID === element.id) {
            if (element.img) {
              img = element.img;
            }
          }
        });
        return (
          <ParkingItem
            key={rentPeriod.parkingSpot.parkingSpotId}
            spot={rentPeriod.parkingSpot}
            isRented={rentPeriod.isRentedOut}
            img={img}
            isEvent={isEvent}
            eventStartTime={eventStartTime}
            eventEndTime={eventEndTime}
            eventName={eventName}
            price={eventPrice}
            eventId={eventId}
          />
        );
      })}
    </div>
  ):<></>;

  const findParkingList = (
    <div className="parkingList">
      {currentPosts?.map((spot) => {
        let spotID = spot.parkingSpotId;
        let img;
        imgArray.forEach((element) => {
          if (spotID === element.id) {
            if (element.img) {
              img = element.img;
            }
          }
        });
        return (
          <ParkingItem
            key={spot.parkingSpotId}
            spot={spot}
            img={img}
            isEvent={isEvent}
            price={spot.price}
          />
        );
      })}
      <Pagination
        parkingListArray={parkingListArray}
        pagination={pagination}
        setPagination={setPagination}
      />
    </div>
  );

  const parkingItemContainer = isEvent ? eventParkeringList : findParkingList;

  return <div className="parkingListComponent">{parkingItemContainer}</div>;
};

export default ParkingList;
