//#region - Imports 
// Libraries
import React from "react";
import { Button } from "react-bootstrap";
import { HashLink } from "react-router-hash-link";
// import imageCompression from 'browser-image-compression';

// Styling
import "../Styles/Pages/AboutUs.scss";

// Componentns
import Brand1 from "../Components/AboutUsPage/Brand1";
import Brand2 from "../Components/AboutUsPage/Brand2";
import Instagram from "../Components/AboutUsPage/Instagram";
import Meta from "../Components/Meta";
import { COOKIE_NAMES, getCookieValue } from "../Components/Tracking/Cookie";
import { initGA, pageView } from "../Components/Tracking/Tracking";

// Assets
import Logo from "../parkshare-full-logo.png";
import Astrid_img from "../Assets/Compressed/parkshare-ceo.JPG"; // compressed image
import ParkeringProblem1 from "../Assets/Aboutus/grey-clock-arrow.png";
import ParkeringProblem2 from "../Assets/Aboutus/grey-co2-clouds.png";
import ParkeringProblem3 from "../Assets/Aboutus/grey-cars-traffic.png";
import ParkeringProblem4 from "../Assets/Aboutus/grey-car-pollution-smoke.png";
import HandShake from "../Assets/Aboutus/parkshare-hands-blue-pink.png";
import Car from "../Assets/Aboutus/parkshare-car-blue.png";
import House from "../Assets/Aboutus/parkshare-house-pink.png";
import Team from "../Assets/Compressed/parkshare-team-eating.jpg"; // compressed image
//#endregion

const AboutUs = () => {
  // Track pageview
  if (getCookieValue(COOKIE_NAMES.PERFORMANCE)) {
    initGA();
    pageView();
  }

  const INSTAGRAM_USERID = "9543959328";
  const THUMBNAIL_WIDTH = 640;
  const PHOTO_COUNT = 10;

  // async function handleImageUpload(event) {

  //   const imageFile = event.target.files[0];
  //   console.log('originalFile instanceof Blob', imageFile instanceof Blob); // true
  //   console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);
  
  //   const options = {
  //     maxSizeMB: 1,
  //     maxWidthOrHeight: 1920,
  //     useWebWorker: true
  //   }
  //   try {
  //     const compressedFile = await imageCompression(imageFile, options);
  //     console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
  //     console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
  
  //   } catch (error) {
  //     console.log(error);
  //   }
  
  // }

  return (
    <div className="aboutus">
      <Meta
        title="Hvem er Vi? | ParkShare "
        description="ParkShare forbinder mennesker, der ønsker nem og bekvem parkering, eller en ekstra indtægt. Her kan du komme tættere på ParkShare, hvem vi er, mission og vision."
      />
      <section className="top">
        <div className="bg">
          <div className="content">
            <h1 className="pageHeading">Vil du vide mere om ParkShare?</h1>
            <p className="subHeading">
              Her på siden kan du lære mere om os, vores rejse og konceptet
              bedre at kende
            </p>
          </div>
        </div>
      </section>
      {/* "Where it all started" section */}
      <section className="whereitallstarted">
        <div className="content">
          <h2 className="sectionHeading">Der hvor det hele startede</h2>
          <div className="container">
            <div className="element1">
              <img
                src={Astrid_img}
                alt="Astrid - ParkShares CEO foran en farverig væg"
              />
              {/* compressed image */}
               {/* <input type="file" accept="image/*" onchange="handleImageUpload(event);"></input> */}
            </div>
            <div className="element2">
              <p className="paragraph">
                ParkShare er til dagligt drevet af Astrid. Astrid startede
                allerede som barn, med at udleje sine forældres parkeringsplads,
                når der var events på Aarhus Stadion. Det er også Aarhus Stadion
                og de mange events i Aarhus, samt at Astrid selv blev bilist, og
                oplevede frustrationen med parkering, der fik Astrid til at
                starte ParkShare.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* "Hvor stort er parkeringsproblemet i dk?" section */}
      <section className="parkeringproblem">
        <div className="content">
          <h2 className="sectionHeading">
            Hvor stort er parkeringsproblemet i Danmark?
          </h2>
          <div className="container">
            <div className="element1">
              <img
                src={ParkeringProblem1}
                alt="Grafik af Grå ur med en pil omkring"
              />
              <p className="iconParagraph">
                I gennemsnit bruger en dansker i større byer 54 timer årligt på
                at lede efter en parkeringsplads
              </p>
            </div>
            <div className="element2">
              <img
                src={ParkeringProblem2}
                alt="Grafik af 2 grå skyer med CO2 skrevet på en af dem"
              />
              <p className="iconParagraph">
                Det udleder ca. 158 kg CO<sub>2</sub> årligt. Hvilket svarer til
                at køre fra Aarhus til Italien, hvert år
              </p>
            </div>
            <div className="element3">
              <img
                src={ParkeringProblem3}
                alt="Grafik af 3 grå biler på linje"
              />
              <p className="iconParagraph">
                Parkeringsproblemer skaber mere trafik, stressede bilister og
                farlige situationer
              </p>
            </div>
            <div className="element4">
              <img
                src={ParkeringProblem4}
                alt="Grafik af Bagdelen af en bil med udstødningsrøg"
              />
              <p className="iconParagraph">
                Når biler cirkulerer rundt, udleder det flere skadelige
                partikler, som forringer luften
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* "Hvordan løser ParkShare parkeringsproblemet? & Images" section */}
      <section className="problemsolving spaceBelow">
        <div className="content">
          <h2 className="sectionHeading">
            Hvordan løser ParkShare parkeringsproblemet?
          </h2>
          <div className="textContainer spaceBelow">
            <p className="paragraph">
              Hos ParkShare er vi drevet af problemløsning og optimering. Vi så
              at mange parkeringspladser står tomme hver dag. Samtidig så vi
              rigtig mange bilister, der havde svært ved at finde parkering.
              Derfor ønsker vi at tilbyde en service, hvor vi kan matche disse
              to sider, og skabe værdi for begge.
              <br />
              ParkShare giver både private og virksomheder muligheden for at
              udleje deres parkeringspladser, når de ikke selv bruger dem.
              Herved kan de også få en ekstra indtægt, og dermed et større
              rådighedsbeløb.
              <br />
              <br />
              Manglen på parkeringspladser betyder, at bilister cirkler rundt
              efter en ledig plads, bruger ekstra brændstof, spilder tiden, øger
              trafikken og skaber farlige situationer. Med større udbud af
              parkeringspladser siger vi farvel til alle problemerne, og vi
              tilbyder også at man kan booke sin parkering på forhånd, inden man
              tager hjemmefra.
              <br />
              <br />
              Med ParkShare bliver du en del af et netværk af bilister og ejere
              af parkeringspladser. I fællesskab kan vi takle de problemer, der
              opstår ved parkering, og med effektivisering af
              parkeringsprocessen, kan vi skabe mere mobilitet, effektivitet og
              klimavenlig trafik.
              <br />
              <br />
              Sammen skaber vi en deleøkonomi til gavn for både lejere og
              udlejere. Med ParkShare bliver parkering nemt og bekvemt.
            </p>
          </div>
          <div className="imgContainer">
            <div className="row1">
              <div>
                <img src={Car} alt="Blå bil" />
              </div>
              <div>
                <img
                  src={HandShake}
                  alt="Blå og lyserød hænder der holder hånd"
                />
              </div>
              <div>
                <img src={House} alt="Lyserød hus" />
              </div>
            </div>
            <img className="row2" src={Logo} alt="ParkShare logo" />
          </div>
        </div>
      </section>
      {/* "Mission and vision" section */}
      <section className="mission_vision">
        <div className="content">
          <div className="missionContainer">
            <h2 className="missionHeading">Vores mission</h2>
            <p className="missionText">
              Vores mission er at muliggøre udlejning af parkeringspladser for
              private og virksomheder, samt gøre parkering nemt og bekvemt for
              bilister
            </p>
          </div>
          <div className="visionContainer">
            <h2 className="visionHeading">Vores vision</h2>
            <p className="visionText">
              Vores vision er at skabe en verden uden parkeringsproblemer. En
              verden hvor bilister ikke spilder tid eller CO
              <span id="smallNumber">2</span>, og hvor eksisterende
              parkeringspladser deles optimalt
            </p>
          </div>
        </div>
      </section>
      {/* "Priser, nomineringer og konkurrencer" section */}
      <section section="brands">
        <div className="content">
          <Brand1></Brand1>
        </div>
      </section>
      {/* "Du har måske set os her" section */}
      <section section="brands">
        <div className="content">
          <Brand2></Brand2>
        </div>
      </section>
      {/* "Would you like to join the team" section - DONT DELETE*/}
      <section className="joinTeam spaceBelow">
        <div className="content">
          <h2 className="sectionHeading">
            Vil du i praktik eller ønsker du at blive en del af <br />
            Team ParkShare?
          </h2>
          <div className="container">
            <div className="element1">
              <p className="paragraph">
                Hvert semester har vi flere studerende i praktik. Som praktikant
                i ParkShare behøver du ikke komme fra et bestemt studie, da vi
                har flere praktikpladser, og det vigtigste er, at du passer i
                vores team og har et drive. Vi elsker vores arbejde, og derfor
                er det vigtigt, at du også ønsker at bidrage til en god stemning
                og god energi på kontoret. Du er altid velkommen til at sende en
                ansøgning.
              </p>
              <div className="regular-button">
                <HashLink to="/kontakt#kontaktos">
                  <Button id="btn-submit">Kontakt os</Button>
                </HashLink>
              </div>
            </div>
            <div className="element2">
              .
              <img
                src={Team}
                alt="ParkShare teamet spiser og drikker udenfor"
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AboutUs;
