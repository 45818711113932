// Libraries
import { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

// Styling
import "../../Styles/Components/RentPopUps/RentConfirmModal.scss";

// Components
import RentConfirmCard from "./RentConfirmCard";
import RentConfirmForm from "./RentConfirmForm";

const RentConfirmModal = (props) => {
  const [show, setShow] = useState(false);
  const pSpot = props.data;
  const img = props.img;

  const [confirmed, setConfirmed] = useState(false);

  const handleClose = () => {
    setShow(false);
    setConfirmed(false);
  };

  const handleShow = () => setShow(true);

  const rentConfirm = (
    <Modal show={show} onHide={handleClose} className="rentConfirmModal">
      <Modal.Header closeButton>
        <Modal.Title>Send ønske om leje af parkering</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <RentConfirmCard spot={pSpot} img={img} />

        <h2 className="paragraphHeading a">
          Udfyld felterne med dine oplysninger herunder
        </h2>

        <div className="formContainer">
          <RentConfirmForm spot={pSpot} setConfirmed={setConfirmed} />
        </div>
      </Modal.Body>
    </Modal>
  );

  const rentConfirmation = (
    <Modal show={show} onHide={handleClose} className="rentConfirmModal">
      <Modal.Header closeButton>
        <Modal.Title>Ønske registreret</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="checkIcon">
          <FontAwesomeIcon icon={faCheckCircle} color="#0063db" size="10x" />
        </div>
        <h2 className="paragraphHeading b">
          Du har nu sendt et ønske om leje af parkering
        </h2>
        <RentConfirmCard spot={pSpot} img={img} />

        <div className="confirmationContainer">
          <h2 className="paragraphHeading c">Hvad nu?</h2>
          <p className="paragraph">
            ParkShare kontakter dig inden længe, hvor du vil høre nærmere om
            betaling og modtage en endelig bekræftelse på parkeringspladsen, da
            den først er din, når du har en endelig bekræftelse fra os. Vi
            ringer til dig fra nummeret 42 72 99 88.
          </p>
          <div className="regular-button">
            <Button id="btn-submit" type="submit" onClick={handleClose}>
              Videre
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
  const modalPicker = confirmed ? rentConfirmation : rentConfirm;
  return (
    <div className="rentConfirmPopUp">
      <Button variant="primary" onClick={handleShow} className="lej">
        Lej
      </Button>
      {modalPicker}
    </div>
  );
};

export default RentConfirmModal;
