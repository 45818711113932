//#region - Import
import { Col, Form, Button, InputGroup } from "react-bootstrap";
import { useState } from "react";

// Styling
import "../Styles/Components/CompaniesForm.scss";

// Components
import FormModal from "./FormModal";
import { validateInfo } from "./Validation/InputFormValidation";
import { client } from './Api/Client'

//#endregion

const CompaniesForm = () => {

  
  //#region - States
  const initialUserData = {
    fullName: "",
    phoneNumber: "",
    email: "",
    address: "",
    zipCode: "",
    city: "",
    numberOfPspots: "",
    rentPeriodType: "",
    msg: "",
    terms: false,
  };
  const [formData, setFormData] = useState(initialUserData);
  const [show, setShow] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  //#endregion

  //#region - Handlers
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleChange = (e) => {
    const updatedData = { ...formData, [e.target.id]: e.target.value };
    setFormData(updatedData);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsValid(true);
    setLoading(true);
    handleShow();
    if (validate()) {
      try {
        client
          .post("Contact/RentYourOwnParkingSpotsCompany", {
            body: {
              fullName: formData.fullName,
              email: formData.email,
              phoneNumber: formData.phoneNumber,
              address: formData.address,
              city: formData.city,
              zipCode: Number(formData.zipCode),
              numberOfPspots: Number(formData.numberOfPspots),
              type: formData.rentPeriodType,
              message: formData.msg,
            }
          })
          .then(
            (res) => {
              console.log("RESPONSEE", res);
              setIsValid(true);
              setLoading(false);
              setFormData(initialUserData);
            },
            (error) => {
              console.log(error.response.data);
            }
          );
      } catch (error) {
        setIsValid(false);
        setLoading(false);
        console.log(error);
      }
    } else {
      setIsValid(false);
      setLoading(false);
      handleClose();
    }
  };
  //#endregion

  //#region - Validation
  const validate = () => {
    let isValid = true;
    const validationObject = {
      fullName: formData.fullName,
      phoneNumber: formData.phoneNumber,
      email: formData.email ? formData.email : null,
      address: formData.address,
      zipCode: formData.zipCode,
      city: formData.city,
      numberOfPspots: formData.numberOfPspots,
      msg: formData.msg ? formData.msg : null,
    };
    let companyValidation = validateInfo(validationObject);
    if (Object.keys(companyValidation).length !== 0) {
      isValid = false;
    }
    setErrors(companyValidation);
    console.log(companyValidation);
    return isValid;
  };
  //#endregion

  //#region - Return
  return (
    // takes styles from the form on Contact page because they're very similar + its own SCSS
    <div className="contact-form companies-form">
      <Form onSubmit={handleSubmit} noValidate>
        <Form.Row>
          <Form.Group as={Col} controlId="fullName">
            <Form.Label className="formlabel">Navn</Form.Label>
            <Form.Control
              type="text"
              placeholder="Indtast navn"
              onChange={handleChange}
              value={formData.fullName}
              isInvalid={!!errors.fullName}
            />
            {errors.fullName ? (
              <Form.Control.Feedback type="invalid">
                {errors.fullName}
              </Form.Control.Feedback>
            ) : null}
          </Form.Group>
          <Form.Group as={Col} controlId="phoneNumber">
            <Form.Label className="formlabel">Telefonnummer</Form.Label>
            <InputGroup hasValidation>
              <InputGroup.Prepend>
                <InputGroup.Text>+45</InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                type="tel"
                placeholder="Indtast telefonnummer"
                aria-describedby="inputGroupPrepend"
                onChange={handleChange}
                value={formData.phoneNumber}
                isInvalid={!!errors.phoneNumber}
                className={errors.phoneNumber ? "inputgroup-right-radius" : ""}
              />
              {errors.phoneNumber ? (
                <Form.Control.Feedback type="invalid">
                  {errors.phoneNumber}
                </Form.Control.Feedback>
              ) : null}
            </InputGroup>
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} controlId="email">
            <Form.Label className="formlabel">
              Email <span className="formlabel-optional">(valgfri)</span>
            </Form.Label>
            <Form.Control
              type="email"
              placeholder="Indtast email"
              onChange={handleChange}
              value={formData.email}
              isInvalid={!!errors.email}
            />
            {errors.email ? (
              <Form.Control.Feedback type="invalid">
                {errors.email}
              </Form.Control.Feedback>
            ) : null}
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} controlId="address">
            <Form.Label className="formlabel">
              Adresse på virksomheden
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Indtast adresse"
              onChange={handleChange}
              value={formData.address}
              isInvalid={!!errors.address}
            />
            {errors.address ? (
              <Form.Control.Feedback type="invalid">
                {errors.address}
              </Form.Control.Feedback>
            ) : null}
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} controlId="zipCode">
            <Form.Label className="formlabel">Postnummer</Form.Label>
            <Form.Control
              type="text"
              placeholder="Indtast postnummer"
              onChange={handleChange}
              value={formData.zipCode}
              isInvalid={!!errors.zipCode}
            />
            {errors.zipCode ? (
              <Form.Control.Feedback type="invalid">
                {errors.zipCode}
              </Form.Control.Feedback>
            ) : null}
          </Form.Group>
          <Form.Group as={Col} controlId="city">
            <Form.Label className="formlabel">By</Form.Label>
            <Form.Control
              type="text"
              placeholder="Indtast by"
              onChange={handleChange}
              value={formData.city}
              isInvalid={!!errors.city}
            />
            {errors.city ? (
              <Form.Control.Feedback type="invalid">
                {errors.city}
              </Form.Control.Feedback>
            ) : null}
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} controlId="numberOfPspots">
            <Form.Label className="formlabel">
              Indtast ca. antal p-pladser I vil udleje
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Indtast ca. antal p-pladser I vil udleje"
              onChange={handleChange}
              value={formData.numberOfPspots}
              isInvalid={!!errors.numberOfPspots}
            />
            {errors.numberOfPspots ? (
              <Form.Control.Feedback type="invalid">
                {errors.numberOfPspots}
              </Form.Control.Feedback>
            ) : null}
          </Form.Group>
          <Form.Group as={Col} controlId="type">
            <Form.Label className="formlabel">
              Hvordan vil I udleje
              <span className="formlabel-optional">(valgfri)</span>
            </Form.Label>
            <Form.Control
              as="select"
              onChange={handleChange}
              value={formData.type}
              isInvalid={!!errors.type}
              className={errors.type ? "erroricon" : ""}
            >
              <option selected disabled value="">
                Vælg
              </option>
              <option value="Månedsudlejning">Månedsudlejning</option>
              <option value="Events">Events</option>
              <option value="En kombination">En kombination</option>
              <option disabled>Timeudlejning (Kommer snart)</option>
            </Form.Control>
            {errors.type ? (
              <Form.Control.Feedback type="invalid">
                {errors.type}
              </Form.Control.Feedback>
            ) : null}
          </Form.Group>
        </Form.Row>
        <Form.Group controlId="msg">
          <Form.Label className="formlabel">
            Besked <span className="formlabel-optional">(valgfri)</span>
          </Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            placeholder="Skriv gerne en bedsked, særligt hvis I har specielle ønsker, spørgsmål eller andet"
            onChange={handleChange}
            value={formData.msg}
          />
        </Form.Group>
        <Form.Group>
          <div className="regular-button">
            {loading ? (
              <Button disabled id="btn-submit" type="submit">
                Sender...
              </Button>
            ) : (
              <Button id="btn-submit" type="submit">
                Send
              </Button>
            )}
          </div>
        </Form.Group>
      </Form>
      <FormModal
        isValid={isValid}
        handleClose={handleClose}
        show={show}
        text="Din besked er nu sendt afsted. Vi kontakter dig i den nærmeste fremtid."
      />
    </div>
  );
  //#endregion
};
export default CompaniesForm;
