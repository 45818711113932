import React, { Component } from "react";
import {
  Form,
  Col,
  InputGroup,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import InfoBox from "../Components/InfoBox";
import InfoBox2 from "../Components/InfoBox2";
import moment from "moment";
// Styling
import "../Styles/Components/RentOutForm2.scss";
import { validateInfo } from "./Validation/InputFormValidation";
import FormModal from "./FormModal";
import { client } from './Api/Client';

import { format, addYears } from "date-fns";
export default class RentOutForm2 extends Component {
  state = {
    //User data
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    //Pspot data
    address: "",
    zipCode: "",
    city: "",
    numberOfPspots: "",
    // Type parkering (True = Monthly, False = Event)
    rentPeriodType: true,
    // Monthly
    startDato: "",
    endDato: "",
    /* Timetype:
    0 = Custom
    1 = Kontortider
    2 = Udenfor kontortider
    3 = 24/7 */
    timeType: 4,
    /* 
    1 = Custom time
    2 = Fri parkering (Udlejet hele døgnet)
    3 = Ingen parkering (Ikke udlejet) */
    manFre: 1,
    saturday: 1,
    sunday: 1,
    /* CUSTOM MONTHLY TIMESLOTS
    start1 & end1 = Start and endtime for Mondy to Friday
    start2 & end2 = Start and endtime for Saturday
    start3 & end3 = Start and endtime for Sunday */
    start1: "",
    end1: "",
    start2: "",
    end2: "",
    start3: "",
    end3: "",
    //div
    msg: "",
    terms: false,
    rightOfDisposal: false,
    btnClick: false,
    //Array of errors if there are any
    errors: [],
    //Window width
    firstWidth: 0,
    width: 0,
    checkedItems: new Map(),
    //Modal/Pop up
    show: false,
    isValid: false,
    loading: false,
  };
  handleClose = () => this.setState({ show: false });
  handleShow = () => this.setState({ show: true });
  updateDimensions = () => {
    const width = window.innerWidth;
    this.setState({ width: width });
    this.setState({ firstWidth: width });
  };
  componentDidMount() {
    this.timeTypeBtnTracking();

    this.updateDimensions();
    const handleResize = () => {
      this.setState({ width: window.innerWidth });
    };
    window.addEventListener("resize", handleResize);
  }
  componentDidUpdate() {
    if (this.state.width !== this.state.firstWidth) {
      this.updateDimensions();
    }
  }
  timeTypeBtnTracking = () => {
    console.log("Listening for btnClick events");
    var clickHandler = () => {
      console.log("Inside clickHandler function");
      this.setState(
        {
          btnClick: true,
        },
        () => {
          console.log(
            "state of btn has been changed  --> ",
            this.state.btnClick
          );
        }
      );
    };
    var btn1 = document.getElementById("btn1"),
      btn2 = document.getElementById("btn2"),
      btn3 = document.getElementById("btn3"),
      btn4 = document.getElementById("btn4");
    btn1.addEventListener("click", clickHandler);
    btn2.addEventListener("click", clickHandler);
    btn3.addEventListener("click", clickHandler);
    btn4.addEventListener("click", clickHandler);
  };
  // Function to clear checkbox
  clearCheckbox() {
    document
      .querySelectorAll("input[type=checkbox]")
      .forEach((el) => (el.checked = false));
  }
  //Validation form
  validate = () => {
    let isValid = true;
    var validationObject = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      email: this.state.email,
      phoneNumber: this.state.phoneNumber,
      address: this.state.address,
      zipCode: this.state.zipCode,
      city: this.state.city,
      numberOfPspots: this.state.numberOfPspots,
      terms: this.state.terms,
      rightOfDisposal: this.state.rightOfDisposal,
    };

    var monthlyRentOut = {
      startDato: this.state.startDato,
      endDato: this.state.endDato ? this.state.endDato : null,
      rentPeriodType: this.state.rentPeriodType,
      btnClick: this.state.btnClick ? this.state.btnClick : false,
      timeType: this.state.timeType,

      manFre: this.state.manFre === 1 ? this.state.manFre : null,
      moFiStartTime: this.state.start1,
      moFiEndTime: this.state.end1,

      saturday: this.state.saturday === 1 ? this.state.saturday : null,
      satStart: this.state.start2,
      satEnd: this.state.end2,

      sunday: this.state.sunday === 1 ? this.state.sunday : null,
      sunStart: this.state.start3,
      sunEnd: this.state.end3,
    };

    if (this.state.rentPeriodType === true) {
      validationObject = { ...validationObject, ...monthlyRentOut };
    }
    const rentoutValidation = validateInfo(validationObject);
    // If the object isn't empty then it it means that there are some sort of error(s)
    if (Object.keys(rentoutValidation).length !== 0) {
      isValid = false;
    }
    this.setState(
      {
        errors: rentoutValidation,
      },
      () => {
        console.log("Consoling state   ", this.state);
        console.log("Consoling errors    ", this.state.errors);
      }
    );
    return isValid;
  };
  //function that handles which parking type the user picks "monthly" & "event"
  handleClickPType = (type) => {
    if (type === "month") {
      this.setState({
        rentPeriodType: true,
      });
    } else if (type === "event") {
      this.setState({
        rentPeriodType: false,
      });
    }
  };
  //function that time type for monthly
  handleTypeChange = (type) => {
    this.setState({
      timeType: type,
    });
  };
  handleTooltip = (props) => {
    //create tooltip with message
    const toolTip = (
      <Tooltip id="button-tooltip" {...props}>
        Kommer snart!
      </Tooltip>
    );
    //return tooltip instance
    return toolTip;
  };
  //Function for clearing forms in "lav selv" in monthly time type
  clearFormsManFre = () => {
    if (this.state.manFre === 2) {
      this.setState({
        start1: "00:00",
        end1: "23:59",
      });
    } else if (this.state.manFre === 1 || this.state.manFre === 3) {
      this.setState({
        start1: "",
        end1: "",
      });
    }
  };
  clearFormsSat = () => {
    if (this.state.saturday === 2) {
      this.setState({
        start2: "00:00",
        end2: "23:59",
      });
    } else if (this.state.saturday === 1 || this.state.saturday === 3) {
      this.setState({
        start2: "",
        end2: "",
      });
    }
  };
  clearFormsSun = () => {
    if (this.state.sunday === 2) {
      this.setState({
        start3: "00:00",
        end3: "23:59",
      });
    } else if (this.state.sunday === 1 || this.state.sunday === 3) {
      this.setState({
        start3: "",
        end3: "",
      });
    }
  };
  // Functions for "lav selv" monthly time type
  handleCustomChange1 = (value) => {
    this.setState(
      {
        manFre: value,
      },
      this.clearFormsManFre
    );
  };
  handleCustomChange2 = (value) => {
    this.setState(
      {
        saturday: value,
      },
      this.clearFormsSat
    );
  };
  handleCustomChange3 = (value) => {
    this.setState(
      {
        sunday: value,
      },
      this.clearFormsSun
    );
  };
  //Handle change of input fields
  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };
  //handle change of "accept servicevilkår"
  handleChangeTerms = (e) => {
    this.setState({
      terms: e.target.checked,
    });
  };
  handleChangeDisposal = (e) => {
    this.setState({
      rightOfDisposal: e.target.checked,
    });
  };
  //function that handles submit
  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({
      isValid: true,
      loading: true,
    });
    this.handleShow();
    if (this.validate()) {
      console.log("DATAAAAA", this.state);
      if (this.state.rentPeriodType === false) {
        try {
          client
            .post(
              "Contact/RentYourOwnParkingSpotsEventType",
              {
                body: {
                  firstName: this.state.firstName,
                  lastName: this.state.lastName,
                  email: this.state.email,
                  phoneNumber: this.state.phoneNumber,
                  address: this.state.address,
                  city: this.state.city,
                  zipCode: Number(this.state.zipCode),
                  numberOfPspots: Number(this.state.numberOfPspots),
                  message: this.state.msg,
                }
              }
            )
            .then((res) => {
              console.log("RESPONSE FROM REQUEST", res);
              // alert("Mange tak! :) Vi kontakter dig indenfor den nærmeste tid");
              if (res.status === 200) {
                console.log("200 statuscode");
                this.setState(
                  {
                    firstName: "",
                    lastName: "",
                    email: "",
                    phoneNumber: "",
                    address: "",
                    city: "",
                    zipCode: "",
                    numberOfPspots: "",
                    msg: "",
                    terms: false,
                    rightOfDisposal: false,
                    loading: false,
                    isValid: true,
                  },
                  () => this.clearCheckbox()
                );
              }
            });
        } catch (error) {
          // alert(
          //   "Kunne ikke sende lige nu. Tjek eventuelt om felterne er udfyldt korrekt eller prøv igen senere."
          // );
          this.setState({ isValid: false, loading: false });
          console.log("OH NOOOOO, ERRRRORRR", error);
        }
      } else if (this.state.rentPeriodType === true) {
        console.log(this.state);
        try {
          client
            .post("Contact/RentYourOwnParkingSpotsMonthlyType",
              {
                body: {
                  firstName: this.state.firstName,
                  lastName: this.state.lastName,
                  email: this.state.email,
                  phoneNumber: this.state.phoneNumber,
                  address: this.state.address,
                  city: this.state.city,
                  zipCode: Number(this.state.zipCode),
                  numberOfPspots: Number(this.state.numberOfPspots),
                  rentPeriodStart: this.state.startDato,
                  rentPeriodEnd: this.state.endDato,
                  monthlyRentPeriodType: this.state.timeType,
                  message: this.state.msg,
                  moFiStartTime: this.state.start1,
                  moFiEndTime: this.state.end1,
                  satStart: this.state.start2,
                  satEnd: this.state.end2,
                  sunStart: this.state.start3,
                  sunEnd: this.state.end3,
                }
              }
            )
            .then((res) => {
              console.log("RESPONSE FROM REQUEST", res);
              // alert("Mange tak! :) Vi kontakter dig indenfor den nærmeste tid");
              this.setState(
                {
                  firstName: "",
                  lastName: "",
                  email: "",
                  phoneNumber: "",
                  address: "",
                  zipCode: "",
                  city: "",
                  numberOfPspots: "",
                  startDato: "",
                  endDato: "",
                  timeType: 4,
                  manFre: 1,
                  saturday: 1,
                  sunday: 1,
                  start1: "",
                  end1: "",
                  start2: "",
                  end2: "",
                  start3: "",
                  end3: "",
                  msg: "",
                  terms: false,
                  rightOfDisposal: false,
                  isValid: true,
                  loading: false,
                },
                () => this.clearCheckbox()
              );
            });
        } catch (error) {
          // alert(
          //   "Kunne ikke sende lige nu. Tjek eventuelt om felterne er udfyldt korrekt eller prøv igen senere."
          // );
          this.setState({ isValid: false, loading: false });
          console.log("OH NOOOOO, ERRRRORRR", error);
        }
      } else {
        //FIX THIS!!!!!!!!!!!!!
        this.setState({ isValid: false, loading: false });
        console.log("ERROR");
      }
    } else {
      this.setState({ isValid: false, loading: false });
      this.handleClose();
    }
  };
  render() {
    const infoBoxText1 =
      "Hvis du ønsker at udleje din parkering ubegrænset, dvs. uden slutdato, kan du lade dette felt være tomt. Hvis der så i fremtiden skulle opstå et behov om at du gerne vil stoppe med at udleje din parkeringsplads ud, så kan du ligeledes også det.";
    const infoBoxText2 =
      "Parkeringspladsen der udlejes, er ikke en parkeringsplads, der må deles med andre. Du har råderetten over pladsen, og ved hvornår den er ledig eller bliver brugt, og kan dermed sikre den er tom, når den udlejes";
    //Styling for p typer
    let monthStyle = this.state.rentPeriodType
      ? "monthBtn leftBtn blueBtn"
      : "monthBtn leftBtn greyBtn";
    let eventStyle = !this.state.rentPeriodType
      ? "eventBtn midBtn blueBtn"
      : "eventBtn midBtn greyBtn";
    let timeStyle = "timeBtn rightBtn";
    // Styling for months typer
    let style0 = this.state.timeType === 0 ? "type0 activeType" : "type0";
    let style1 = this.state.timeType === 1 ? "type1 activeType" : "type1";
    let style2 = this.state.timeType === 2 ? "type2 activeType" : "type2";
    let style3 = this.state.timeType === 3 ? "type3 activeType" : "type3";
    //Styling for "lav din egen" valg
    let one1 = this.state.manFre === 1 ? "valg1 activeType" : "valg1 greyBtn";
    let one2 = this.state.manFre === 2 ? "valg2 activeType" : "valg2 greyBtn";
    let one3 = this.state.manFre === 3 ? "valg3 activeType" : "valg3 greyBtn";
    let two1 = this.state.saturday === 1 ? "valg1 activeType" : "valg1 greyBtn";
    let two2 = this.state.saturday === 2 ? "valg2 activeType" : "valg2 greyBtn";
    let two3 = this.state.saturday === 3 ? "valg3 activeType" : "valg3 greyBtn";
    let three1 = this.state.sunday === 1 ? "valg1 activeType" : "valg1 greyBtn";
    let three2 = this.state.sunday === 2 ? "valg2 activeType" : "valg2 greyBtn";
    let three3 = this.state.sunday === 3 ? "valg3 activeType" : "valg3 greyBtn";
    let grey1 =
      this.state.manFre === 2 || this.state.manFre === 3 ? "passive" : "";
    let grey2 =
      this.state.saturday === 2 || this.state.saturday === 3 ? "passive" : "";
    let grey3 =
      this.state.sunday === 2 || this.state.sunday === 3 ? "passive" : "";
    // Tider content
    let type0Content;

    var monthlyRentOutError = {
      manFre:
        this.state.errors.manFre === "Err" ? this.state.errors.manFre : null,
      sat: this.state.errors.sat === "Err" ? this.state.errors.sat : null,
      sun: this.state.errors.sun === "Err" ? this.state.errors.sun : null,
    };

    // * If the screen width is over 750px
    if (this.state.width >= 750) {
      type0Content = (
        <div>
          <div className="tider">
            <div className="box1Custom">
              <p
                className={
                  monthlyRentOutError.manFre !== null ? "invalidReqColor" : null
                }
              >
                Man-Fre
              </p>
              <p
                className={
                  monthlyRentOutError.sat !== null ? "invalidReqColor" : null
                }
              >
                Lørdag
              </p>
              <p
                className={
                  monthlyRentOutError.sun !== null ? "invalidReqColor" : null
                }
              >
                Søndag
              </p>
            </div>
            <div className="box2">
              Start tid
              <br />
              <input
                type="time"
                onChange={this.handleChange}
                value={this.state.start1}
                id="start1"
                className={grey1}
              />
              <br />
              <br />
              <input
                type="time"
                value={this.state.start2}
                id="start2"
                onChange={this.handleChange}
                className={grey2}
              />
              <br />
              <br />
              <input
                type="time"
                value={this.state.start3}
                id="start3"
                onChange={this.handleChange}
                className={grey3}
              />
            </div>
            <div className="box3">
              Slut tid
              <br />
              <input
                type="time"
                value={this.state.end1}
                id="end1"
                onChange={this.handleChange}
                className={grey1}
              />
              <br />
              <br />
              <input
                type="time"
                value={this.state.end2}
                id="end2"
                onChange={this.handleChange}
                className={grey2}
              />
              <br />
              <br />
              <input
                type="time"
                value={this.state.end3}
                id="end3"
                onChange={this.handleChange}
                className={grey3}
              />
            </div>
            <div className="box4">
              <div className="row1">
                <div
                  className={one1}
                  onClick={() => this.handleCustomChange1(1)}
                >
                  Sæt tiden selv
                </div>
                <div
                  className={one2}
                  onClick={() => this.handleCustomChange1(2)}
                >
                  Udlej hele døgnet
                </div>
                <div
                  className={one3}
                  onClick={() => this.handleCustomChange1(3)}
                >
                  Ingen udlejning
                </div>
              </div>
              <div className="row2">
                <div
                  className={two1}
                  onClick={() => this.handleCustomChange2(1)}
                >
                  Sæt tiden selv
                </div>
                <div
                  className={two2}
                  onClick={() => this.handleCustomChange2(2)}
                >
                  Udlej hele døgnet
                </div>
                <div
                  className={two3}
                  onClick={() => this.handleCustomChange2(3)}
                >
                  Ingen udlejning
                </div>
              </div>
              <div className="row3">
                <div
                  className={three1}
                  onClick={() => this.handleCustomChange3(1)}
                >
                  Sæt tiden selv
                </div>
                <div
                  className={three2}
                  onClick={() => this.handleCustomChange3(2)}
                >
                  Udlej hele døgnet
                </div>
                <div
                  className={three3}
                  onClick={() => this.handleCustomChange3(3)}
                >
                  Ingen udlejning
                </div>
              </div>
            </div>
          </div>

          {monthlyRentOutError.manFre !== null ||
          monthlyRentOutError.sat !== null ||
          monthlyRentOutError.sun !== null ? (
            <p className="invalid">
              Du mangler at sætte start- og sluttiden for nogle af ovenstående
              dage.
            </p>
          ) : null}
        </div>
      );
    } else if (this.state.width < 750) {
      // * If the screen width is under 750px
      type0Content = (
        <div className="tider2">
          <div className="timeBox">
            <div className="container1">
              <p>MAN-FRE</p>
            </div>
            <div className="container2">
              <label> Start tid: </label>
              <input
                type="time"
                onChange={this.handleChange}
                value={this.state.start1}
                id="start1"
                className={grey1}
              />
              <label> Slut tid: </label>
              <input
                type="time"
                value={this.state.end1}
                id="end1"
                onChange={this.handleChange}
                className={grey1}
              />
            </div>
            <div className="container3">
              <div className={one1} onClick={() => this.handleCustomChange1(1)}>
                Sæt tiden selv
              </div>
              <div className={one2} onClick={() => this.handleCustomChange1(2)}>
                Udlej hele døgnet
              </div>
              <div className={one3} onClick={() => this.handleCustomChange1(3)}>
                Ingen udlejning
              </div>
            </div>
            {monthlyRentOutError.manFre !== null ? (
              <p className="invalid">
                Ops, det ser ud til du har glemt at inkludere start- og sluttid
                for Mandag-Fredag
              </p>
            ) : null}
          </div>
          <div className="timeBox">
            <div className="container1">
              <p>LØRDAG</p>
            </div>
            <div className="container2">
              <label> Start tid: </label>
              <input
                type="time"
                onChange={this.handleChange}
                value={this.state.start2}
                id="start2"
                className={grey2}
              />
              <label> Slut tid: </label>
              <input
                type="time"
                value={this.state.end2}
                id="end2"
                onChange={this.handleChange}
                className={grey2}
              />
            </div>
            <div className="container3">
              <div className={two1} onClick={() => this.handleCustomChange2(1)}>
                Sæt tiden selv
              </div>
              <div className={two2} onClick={() => this.handleCustomChange2(2)}>
                Udlej hele døgnet
              </div>
              <div className={two3} onClick={() => this.handleCustomChange2(3)}>
                Ingen udlejning
              </div>
            </div>
            {monthlyRentOutError.sat !== null ? (
              <p className="invalid">
                Ops, det ser ud til du har glemt at inkludere start- og sluttid
                for Lørdag
              </p>
            ) : null}
          </div>
          <div className="timeBox">
            <div className="container1">
              <p>SØNDAG</p>
            </div>
            <div className="container2">
              <label> Start tid: </label>
              <input
                type="time"
                onChange={this.handleChange}
                value={this.state.start3}
                id="start3"
                className={grey3}
              />
              <label> Slut tid: </label>
              <input
                type="time"
                value={this.state.end3}
                id="end3"
                onChange={this.handleChange}
                className={grey3}
              />
            </div>
            <div className="container3">
              <div
                className={three1}
                onClick={() => this.handleCustomChange3(1)}
              >
                Sæt tiden selv
              </div>
              <div
                className={three2}
                onClick={() => this.handleCustomChange3(2)}
              >
                Udlej hele døgnet
              </div>
              <div
                className={three3}
                onClick={() => this.handleCustomChange3(3)}
              >
                Ingen udlejning
              </div>
            </div>
          </div>
          {monthlyRentOutError.sun !== null ? (
            <p className="invalid">
              Ops, det ser ud til du har glemt at inkludere start- og sluttid
              for Søndag
            </p>
          ) : null}
        </div>
      );
    }
    let type1Content = (
      <div className="tider">
        <div className="box1">
          <p>Man-Fre</p>
          <p>Lørdag</p>
          <p>Søndag</p>
        </div>
        <div className="box2">
          <p>07:30 - 16:30</p>
          <p>Ikke udlejet</p>
          <p>Ikke udlejet</p>
        </div>
      </div>
    );
    let type2Content = (
      <div className="tider">
        <div className="box1">
          <p>Man-Fre</p>
          <p>Lørdag</p>
          <p>Søndag</p>
        </div>
        <div className="box2">
          <p>16:30 - 07:30</p>
          <p>Udlejet hele døgnet</p>
          <p>Udlejet hele døgnet</p>
        </div>
      </div>
    );
    let type3Content = (
      <div className="tider">
        <div className="box1">
          <p>Man-Fre</p>
          <p>Lørdag</p>
          <p>Søndag</p>
        </div>
        <div className="box2">
          <p>Udlejet hele døgnet</p>
          <p>Udlejet hele døgnet</p>
          <p>Udlejet hele døgnet</p>
        </div>
      </div>
    );
    let showTimeType =
      this.state.timeType === 0 ? (
        type0Content
      ) : this.state.timeType === 1 ? (
        type1Content
      ) : this.state.timeType === 2 ? (
        type2Content
      ) : this.state.timeType === 3 ? (
        type3Content
      ) : (
        <div></div>
      );
    //p typer content
    let monthContent = (
      <div>
        <p className="typeDesc">
          Månedsudlejning er hvor du udlejer din parkeringsplads en måned ad
          gangen. Du bestemmer selv hvor mange måneder, og hvornår på en given
          uge den skal lejes ud.
        </p>
        <Form.Row>
          <Form.Group as={Col} controlId="startDato">
            <Form.Label className="formlabel">
              Startdato for udlejning
            </Form.Label>
            <Form.Control
              type="date"
              placeholder="Indtast start måned eller dato for udlejning"
              // name="startdato"
              // required
              min={format(new Date(), "y-MM-dd")}
              max={format(addYears(new Date(), 10), "y-MM-dd")}
              onChange={this.handleChange}
              value={this.state.startDato}
              isInvalid={!!this.state.errors.startDato}
            />
            {this.state.errors.startDato ? (
              <Form.Control.Feedback type="invalid">
                {this.state.errors.startDato}
              </Form.Control.Feedback>
            ) : null}
          </Form.Group>
          <Form.Group as={Col} controlId="endDato">
            <Form.Label className="formlabel">
              Slutdato for udlejning{" "}
              <span className="formlabel-optional">(valgfri)</span>
            </Form.Label>
            <InfoBox2 placement="topPlacement" infoText={infoBoxText1} />
            <Form.Control
              type="date"
              placeholder="Indtast slut måned eller dato for udlejning"
              min={format(new Date(), "y-MM-dd")}
              max={format(addYears(new Date(), 5), "y-MM-dd")}
              onChange={this.handleChange}
              value={this.state.endDato}
              isInvalid={!!this.state.errors.endDato}
            />
            {this.state.errors.endDato ? (
              <Form.Control.Feedback type="invalid">
                {this.state.errors.endDato}
              </Form.Control.Feedback>
            ) : null}
          </Form.Group>
        </Form.Row>
        <div className="tidsTyper">
          <p className="bold">Vælg tidstype</p>
          <div className="timeOptions">
            <div
              className={style3}
              onClick={() => this.handleTypeChange(3)}
              id="btn1"
            >
              <div className="typeBox">
                <span className="typeHeading">24/7</span>
                <br />
                <span className="typeContent">
                  Udlej din parkeringsplads døgnet rundt
                </span>
              </div>
            </div>
            <div
              className={style1}
              onClick={() => this.handleTypeChange(1)}
              id="btn2"
            >
              <div className="typeBox">
                <span className="typeHeading">Kontortider</span>
                <br />
                <span className="typeContent">
                  Udlej din parkeringplads når du er på kontoret
                </span>
              </div>
            </div>
            <div
              className={style2}
              onClick={() => this.handleTypeChange(2)}
              id="btn3"
            >
              <div className="typeBox">
                <span className="typeHeading">Udenfor Kontortider</span>
                <br />
                <span className="typeContent">
                  Udlej din parkeringplads udenfor kontortiderne
                </span>
              </div>
            </div>
            <div
              className={style0}
              onClick={() => this.handleTypeChange(0)}
              id="btn4"
            >
              <div className="typeBox">
                <span className="typeHeading">Lav din egen</span>
                <br />
                <span className="typeContent">
                  {/* Bestem selv hvornår din parkeringsplads skal udlejes i ugen */}
                  Design selv hvornår din parkeringsplads skal udlejes
                </span>
              </div>
            </div>
          </div>
        </div>
        {/* {this.timeTypeBtnTracking()}  */}
        {this.state.errors.btnClickMsg === "Tidstype er ikke valgt" ? (
          <div className="invalid">{this.state.errors.btnClickMsg}</div>
        ) : null}
        {showTimeType}
      </div>
    );
    let eventContent = (
      <div>
        <p className="typeDesc">
          Eventparkering er når du udlejer din parkeringsplads tæt på et event.
          Disse events er nogle ParkShare har udvalgt. Skriv gerne hvilket event
          du gerne vil udleje din parkeringsplads til
        </p>
        <p>
          *Vi udlejer kun til events i Aarhus lige nu, men vi udvider snart!
        </p>
      </div>
    );
    //p type which to show
    let showPType = this.state.rentPeriodType ? monthContent : eventContent;
    return (
      <div className="rentOutForm2">
        <Form onSubmit={this.handleSubmit} noValidate>
          <Form.Row>
            <Form.Group as={Col} controlId="firstName">
              <Form.Label className="formlabel">Fornavn</Form.Label>
              <Form.Control
                type="text"
                placeholder="Indtast dit fornavn"
                required
                onChange={this.handleChange}
                value={this.state.firstName}
                isInvalid={!!this.state.errors.firstName}
              />
              {this.state.errors.firstName ? (
                <Form.Control.Feedback type="invalid">
                  {this.state.errors.firstName}
                </Form.Control.Feedback>
              ) : null}
            </Form.Group>
            <Form.Group as={Col} controlId="lastName">
              <Form.Label className="formlabel">Efternavn</Form.Label>
              <Form.Control
                type="text"
                placeholder="Indtast dit efternavn"
                required
                onChange={this.handleChange}
                value={this.state.lastName}
                isInvalid={!!this.state.errors.lastName}
              />
              {this.state.errors.lastName ? (
                <Form.Control.Feedback type="invalid">
                  {this.state.errors.lastName}
                </Form.Control.Feedback>
              ) : null}
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} controlId="email">
              <Form.Label className="formlabel">Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Indtast din email"
                required
                onChange={this.handleChange}
                value={this.state.email}
                isInvalid={!!this.state.errors.email}
              />
              {this.state.errors.email ? (
                <Form.Control.Feedback type="invalid">
                  {this.state.errors.email}
                </Form.Control.Feedback>
              ) : null}
            </Form.Group>
            <Form.Group as={Col} controlId="phoneNumber">
              <Form.Label className="formlabel">Telefonnummer</Form.Label>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>+45</InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  type="tel"
                  placeholder="Indtast dit telefonnummer"
                  aria-describedby="inputGroupPrepend"
                  required
                  onChange={this.handleChange}
                  value={this.state.phoneNumber}
                  isInvalid={!!this.state.errors.phoneNumber}
                />
                {this.state.errors.phoneNumber ? (
                  <Form.Control.Feedback type="invalid">
                    {this.state.errors.phoneNumber}
                  </Form.Control.Feedback>
                ) : null}
              </InputGroup>
            </Form.Group>
          </Form.Row>
          <br />
          {/* PSPOT INFO */}
          <p className="sub-heading">
            Informationer om den eller de parkeringspladser du ønsker at udleje
          </p>
          <Form.Row>
            <Form.Group as={Col} controlId="address">
              <Form.Label className="formlabel">Adresse</Form.Label>
              <Form.Control
                placeholder="Indtast din adresse"
                required
                type="text"
                onChange={this.handleChange}
                value={this.state.address}
                isInvalid={!!this.state.errors.address}
              />
              {this.state.errors.address ? (
                <Form.Control.Feedback type="invalid">
                  {this.state.errors.address}
                </Form.Control.Feedback>
              ) : null}
            </Form.Group>
            <Form.Group as={Col} controlId="zipCode">
              <Form.Label className="formlabel">Postnummer</Form.Label>
              <Form.Control
                required
                type="text"
                onChange={this.handleChange}
                placeholder="Indtast dit postnummer"
                value={this.state.zipCode}
                isInvalid={!!this.state.errors.zipCode}
              />
              {this.state.errors.zipCode ? (
                <Form.Control.Feedback type="invalid">
                  {this.state.errors.zipCode}
                </Form.Control.Feedback>
              ) : null}
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} controlId="city">
              <Form.Label className="formlabel">By</Form.Label>
              <Form.Control
                required
                type="text"
                onChange={this.handleChange}
                placeholder="Indtast din by"
                value={this.state.city}
                isInvalid={!!this.state.errors.city}
              />
              {this.state.errors.city ? (
                <Form.Control.Feedback type="invalid">
                  {this.state.errors.city}
                </Form.Control.Feedback>
              ) : null}
            </Form.Group>
            <Form.Group as={Col} controlId="numberOfPspots">
              <Form.Label className="formlabel">
                Hvor mange parkeringspladser?
              </Form.Label>
              <Form.Control
                required
                type="text"
                onChange={this.handleChange}
                placeholder="Indtast antal parkeringspladser"
                value={this.state.numberOfPspots}
                isInvalid={!!this.state.errors.numberOfPspots}
              />
              {this.state.errors.numberOfPspots ? (
                <Form.Control.Feedback type="invalid">
                  {this.state.errors.numberOfPspots}
                </Form.Control.Feedback>
              ) : null}
            </Form.Group>
          </Form.Row>
          {/* Different type of parking */}
          <div className="typeParking">
            <div className="content2">
              <p className="sub-heading">
                Hvilken type parkering ønsker du at udleje?
              </p>
              <div className="btnContainer">
                <div
                  onClick={() => this.handleClickPType("month")}
                  className={monthStyle}
                >
                  Måned
                </div>
                <div
                  onClick={() => this.handleClickPType("event")}
                  className={eventStyle}
                >
                  Event
                </div>
                <OverlayTrigger
                  placement="right"
                  delay={{ show: 250, hide: 400 }}
                  overlay={this.handleTooltip}
                >
                  <div className={timeStyle}>Time</div>
                </OverlayTrigger>
              </div>
            </div>
          </div>
          {showPType}
          <Form.Group controlId="msg" className="msg">
            <Form.Label className="formlabel">
              Besked <span className="formlabel-optional">(valgfri)</span>
            </Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="Skriv gerne en besked, særligt hvis du har specielle ønsker, spørgsmål eller andet"
              onChange={this.handleChange}
              value={this.state.msg}
              isInvalid={!!this.state.errors.msg}
            />
            {this.state.errors.msg ? (
              <Form.Control.Feedback type="invalid">
                {this.state.errors.msg}
              </Form.Control.Feedback>
            ) : null}
          </Form.Group>
          <Form.Group controlId="rightOfDisposal">
            <Form.Check
              type="checkbox"
              id="checkbox1"
              onChange={this.handleChangeDisposal}
              label={
                <span>
                  Jeg har råderetten over parkingspladsen
                  <InfoBox infoText={infoBoxText2} />
                </span>
              }
              // value={this.state.rightOfDisposal}
              isInvalid={!!this.state.errors.rightOfDisposal}
              feedback={this.state.errors.rightOfDisposal}
            />
          </Form.Group>
          <Form.Group controlId="terms">
            <Form.Check
              type="checkbox"
              // id="checkbox2"
              onChange={this.handleChangeTerms}
              label={
                <span>
                  Accepter{" "}
                  <a target="_blank" href="/Terms">
                    Servicevilkår
                  </a>
                  *
                </span>
              }
              // value={this.state.terms}
              isInvalid={!!this.state.errors.terms}
              feedback={this.state.errors.terms}
            />
          </Form.Group>
          <Form.Group>
            {Object.keys(this.state.errors).length > 0 ? (
              <div className="invalid">
                OBS: Formularen kunne ikke sendes, da der er nogle ting, du
                mangler at udfylde ovenfor
              </div>
            ) : null}
            <div className="regular-button">
              {this.state.loading ? (
                <Button disabled id="btn-submit" type="submit">
                  Sender...
                </Button>
              ) : (
                <Button id="btn-submit" type="submit">
                  Send
                </Button>
              )}
            </div>
          </Form.Group>
        </Form>
        <FormModal
          isValid={this.state.isValid}
          handleClose={this.handleClose}
          show={this.state.show}
          text="Din besked er nu sendt afsted. Vi kontakter dig i den nærmeste fremtid."
        />
      </div>
    );
  }
}
