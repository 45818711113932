//#region - Imports
// Libraries
import {useState} from "react";
import {Form, Button, Col} from "react-bootstrap";

// Styling
import "../Styles/Components/EventForm.scss";

// Components
import {validateInfo} from "./Validation/InputFormValidation";
import FormModal from "./FormModal";
import {client} from "./Api/Client";

//#endregion

const Eventform = () => {
  //#region - States
  const initialUserData = {
    eventFormCity: "",
    eventFormEvent: "",
  };

  const [formData, setFormData] = useState(initialUserData);
  const [show, setShow] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  //#endregion

  //#region - Handlers
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleChange = (e) => {
    const updatedData = {...formData, [e.target.id]: e.target.value};
    setFormData(updatedData);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsValid(true);
    setLoading(true);
    handleShow();
    if (validate()) {
      try {
        client
          .post("Contact/FutureEventsRequest", {
            body: {
              eventName: formData.eventFormEvent,
              city: formData.eventFormCity,
            },
          })
          .then((res) => {
            console.log("WUUHUUUU DATA FROM REQUEST", res);
            setIsValid(true);
            setLoading(false);
            setFormData(initialUserData);
          });
      } catch (error) {
        setIsValid(false);
        setLoading(false);
      }
    } else {
      setIsValid(false);
      setLoading(false);
      handleClose();
    }
  };
  //#endregion

  //#region - Validation

  const validate = () => {
    let isValid = true;
    const validationObject = {
      eventFormCity: formData.eventFormCity,
      eventFormEvent: formData.eventFormEvent,
    };
    const validation = validateInfo(validationObject);
    if (Object.keys(validation).length !== 0) {
      isValid = false;
    }
    setErrors(validation);
    return isValid;
  };

  //#endregion

  //#region - Return
  return (
    <div className="eventInputForm">
      <Form onSubmit={handleSubmit} noValidate>
        <Form.Group as={Col} controlId="eventFormCity">
          <Form.Label className="formlabel">By</Form.Label>
          <Form.Control
            type="text"
            placeholder="Indtast den by hvor eventet er"
            onChange={handleChange}
            value={formData.eventFormCity}
            isInvalid={!!errors.eventFormCity}
          />
          {errors.eventFormCity ? (
            <Form.Control.Feedback type="invalid">
              {errors.eventFormCity}
            </Form.Control.Feedback>
          ) : null}
        </Form.Group>
        <Form.Group as={Col} controlId="eventFormEvent">
          <Form.Label className="formlabel">Event</Form.Label>
          <Form.Control
            type="text"
            placeholder="Indtast navnet på eventet eller stedet"
            onChange={handleChange}
            value={formData.eventFormEvent}
            isInvalid={!!errors.eventFormEvent}
          />
          {errors.eventFormEvent ? (
            <Form.Control.Feedback type="invalid">
              {errors.eventFormEvent}
            </Form.Control.Feedback>
          ) : null}
        </Form.Group>
        <Form.Group>
          <div className="regular-button">
            {loading ? (
              <Button disabled id="btn-submit" type="submit">
                Sender...
              </Button>
            ) : (
              <Button id="btn-submit" type="submit">
                Send
              </Button>
            )}
          </div>
        </Form.Group>
      </Form>
      <FormModal
        isValid={isValid}
        handleClose={handleClose}
        show={show}
        text="Vi sætter stor pris på dit ønske, og det kan være vi kommer til netop dette event i fremtiden."
      />
    </div>
  );

  //#endregion
};

export default Eventform;
