//#region - Imports
// Libraries
import Button from "react-bootstrap/Button";
import {useRef, useState} from "react";
import {HashLink} from "react-router-hash-link";
// Styles
import "bootstrap/dist/css/bootstrap.min.css";
import "../Styles/Pages/FindP.scss";
// Components
import FindParkingList from "../Components/ParkingList/FindParkingList";
import MonthlyWaitinglist from "../Components/Waitinglist/MonthlyWaitinglist";
import FAQ from "../Components/FAQ";
import Meta from "../Components/Meta";
import {findParkeringFAQ} from "../Components/FAQWidgetDatas";
import {COOKIE_NAMES, getCookieValue} from "../Components/Tracking/Cookie";
import {initGA, pageView} from "../Components/Tracking/Tracking";
import useWindowDimensions from "../Components/Hooks/useWindowDimensions";
// Images
import Checkbox from "../Assets/Findparkering/blue-pink-tick-checkmark.png";
import Gray_Checkbox from "../Assets/Findparkering/grey-square-box.png";
import FindParkingStep1 from "../Assets/Findparkering/bubbles/pink-circle-outline.png";
import FindParkingStep2 from "../Assets/Findparkering/bubbles/pink-circle-outline-blue-car.png";
import FindParkingStep3 from "../Assets/Findparkering/bubbles/pink-circle-outline-blue-car-house.png";
import Ticket from "../Assets/Findparkering/white-event-tickets-music.png";
import Ball from "../Assets/Findparkering/white-football.png";
import Tivoli from "../Assets/Findparkering/white-theme-park-carosuel-rollercoaster.png";
import Event_Type from "../Assets/Findparkering/black-parking-concert-tickets.png";
import Monthly_Type from "../Assets/Findparkering/black-parking-calendar.png";
import Hourly_Type from "../Assets/Findparkering/black-parking-clock.png";
import Keys from "../Assets/Findparkering/grey-blue-pink-keys.png";
//#endregion

const FindP = () => {
  if (getCookieValue(COOKIE_NAMES.PERFORMANCE)) {
    initGA();
    pageView();
  }

  const {width} = useWindowDimensions();
  const scrollRef = useRef(null);
  const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop - 100);
  const executeScroll = () => scrollToRef(scrollRef);

  return (
    <div className="findparkering">
      <Meta
        title="Find & Lej Parkering - ParkShare"
        description="Find parkering med ParkShare. Vi har parkering til events, på timebasis og fast månedsparkering. Vi tilbyder privat parkering, hvor du kan booke på forhånd."
      />
      {/* TOP */}
      <section className="top">
        <div className="bg">
          <div className="content">
            <div className="element1">
              <h1 className="pageHeading">
                Lej parkering <br /> nemt og bekvemt
              </h1>
              <Button className="topButton" onClick={executeScroll}>
                Find Parkering
              </Button>{" "}
            </div>
            <div className="element2">
              <iframe
                src="https://www.youtube.com/embed/dKdnNxkuftM"
                title="findParkeringVideo"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              ></iframe>
            </div>
          </div>
        </div>
      </section>
      <section className="mobileVideo">
        <iframe
          height="196px"
          width="350px"
          src="https://www.youtube.com/embed/dKdnNxkuftM"
          title="findParkeringVideo"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        ></iframe>
      </section>
      {/* Fordele */}
      <section className="fordele">
        <div className="content">
          <h2 className="sectionHeading">Fordele ved at bruge ParkShare</h2>
          <div className="container">
            <div className="element1">
              <div className="text">
                <p className="paragraph">
                  Hos ParkShare har vi gjort det nemt og hurtigt at finde en
                  parkeringsplads. Uanset om problemet og tidspresset i
                  forbindelse med parkering opstår i hverdagen eller før en
                  event, så har vi løsningen.
                </p>
                <p className="paragraph">
                  Du behøver ikke længere at cirkulere rundt og lede efter
                  parkering – med ParkShare kan du spare både tid, brændstof og
                  CO<sub>2</sub>.
                </p>
                <br></br>
                <p className="paragraph">
                  Når du bruger ParkShare, er det os, der sætter priserne. Det
                  gør, at du som bilist har faste og fair priser, og ved hvad du
                  kan forvente.
                </p>
                <br></br>
                <p className="paragraph">
                  Det kan ofte være svært og kompliceret at forstå de
                  forskellige parkeringsregler og skilte. Hos os behøver du ikke
                  bekymre dig om skilte. Vi gør parkering simpelt, nemt og
                  bekvemt.
                </p>
                <br></br>
                <p className="paragraph">
                  Book din parkering på forhånd, så kender du din
                  parkeringsplads allerede hjemmefra.
                </p>
              </div>
            </div>
            <div className="vertical-line" />
            <div className="element2">
              <div className="check">
                <div className="hidden"></div>
                <img
                  className="checkbox"
                  src={Checkbox}
                  alt="Blå firkantet afkrydsningsfelt med lyserød flueben"
                />
                <p>Spar tid</p>
              </div>
              <p className="fordeleMobileOnly">
                Hos ParkShare har vi gjort det nemt at finde en parkeringsplads.
                Uanset om problemet og tidspresset med parkering opstår i
                hverdagen eller før et event, så har vi løsningen. Du behøver
                ikke længere cirkulere rundt og lede efter parkering, med
                ParkShare kan du spare meget tid, brændstof og CO<sub>2</sub>.
              </p>
              <div className="check">
                <img
                  className="checkbox"
                  src={Checkbox}
                  alt="Blå firkantet afkrydsningsfelt med lyserød flueben"
                />
                <p>Spar penge</p>
              </div>
              <p className="fordeleMobileOnly">
                Når du bruger ParkShare er det os der sætter priserne. Det gør
                at du som bilist, har faste og fair priser, og ved hvad du kan
                forvente.
              </p>
              <div className="check">
                <img
                  className="checkbox"
                  src={Checkbox}
                  alt="Blå firkantet afkrydsningsfelt med lyserød flueben"
                />
                <p>
                  {width > 300
                    ? "Slip for parkeringsbøder"
                    : "Slip for parkerings- bøder"}
                </p>
              </div>
              <p className="fordeleMobileOnly">
                Det kan ofte være svært og kompliceret at forstå de forskellige
                parkeringsregler og skilte. Hos os behøver du ikke bekymre dig
                om skilte. Vi gør parkering simpelt, nemt og bekvemt. Book din
                parkering på forhånd, så kender du din parkeringsplads allerede
                hjemmefra.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* Så nemt at leje */}
      <section className="lejnemt">
        <div className="content">
          <h2 className="sectionHeading">
            {width > 250
              ? "Så nemt er det at leje en parkeringsplads med ParkShare"
              : "Så nemt er det at leje en parkerings- plads med ParkShare"}
          </h2>
          <div className="bubblesContainer">
            <div className="element">
              <img
                src={FindParkingStep1}
                alt="Lyserød cirkel med blå bil"
                className="img1"
              />
              <p>Vælg by og klik "Lej" på den ønskede parkering i listen.</p>
            </div>
            <div className="element">
              <img
                src={FindParkingStep2}
                alt="Lyserød cirkel med blå bil"
                className="img2"
              />
              <p>
                Udfyld informationerne i formularen og indsend dit ønske om
                parkering til os.
              </p>
            </div>
            <div className="element">
              <img
                src={FindParkingStep3}
                alt="Lyserød cirkel med blå bil og lyserød hus"
                className="img3"
              />
              <p>
                Du modtager en bekræftelse fra ParkShare, når pladsen er din.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* PARKINGLIST COMPONENT */}
      <section className="parkingList" ref={scrollRef}>
        <div className="content">
          <FindParkingList />
        </div>
      </section>
      {/* REDIRECT TO EVENT PARKING  */}
      <section className="eventLink1">
        <div className="bg"></div>
        <div className="content">
          <h2 className="bannerHeading white">
            Skal du bruge parkering til events?
          </h2>
          <div className="eventContainer">
            <div className="element1">
              <img src={Ticket} alt="Hvid koncert billetter med musik node" />
            </div>
            <div className="element2">
              <img src={Ball} alt="Hvid fodbold" />
            </div>
            <div className="regular-button">
              <HashLink smooth to="/event#eventlist">
                <Button id="btn-submit" type="submit">
                  Klik her
                </Button>
              </HashLink>
            </div>
            <div className="element3">
              <img src={Tivoli} alt="Hvid forlystelsespark" />
            </div>
          </div>
          {/* Under 1200px for EventsLink */}
          <div className="containerUnder592px">
            <div className="icons">
              <div className="tickets">
                <img src={Ticket} alt="Hvid koncert billetter med musik node" />
              </div>
              <div className="ball">
                <img src={Ball} alt="Hvid fodbold" />
              </div>
              <div className="tivoli">
                <img src={Tivoli} alt="Hvid forlystelsespark" />
              </div>
            </div>
            <div className="regular-button">
              <HashLink smooth to="/event#eventlist">
                <Button id="btn-submit" type="submit">
                  Klik her
                </Button>
              </HashLink>
            </div>
          </div>
        </div>
      </section>
      {/* "Hvilken type parkering kan man leje" section  */}
      <section className="pspotstyper">
        <div className="content">
          <div className="box">
            <h2 className="sectionHeading">
              Hvilke type parkering kan man leje?
            </h2>
            <div className="container">
              <div className="element1">
                <p className="iconHeading">Månedsparkering</p>
                <div className="fadingBackground">
                  <img src={Monthly_Type} alt="Stort sort P og en kalender" />
                </div>
                <p className="paragraph">
                  Månedsparkering er en fast parkeringsplads, der udlejes en
                  måned af gangen. Den kan have en slutdato eller være
                  ubegrænset, og med forskellige tidstyper og intervaller.
                </p>
              </div>
              <div className="element2">
                <p className="iconHeading">Eventparkering</p>
                <div className="fadingBackground">
                  <img
                    src={Event_Type}
                    alt="Stort sort P og koncert billetter med musik node"
                  />
                </div>
                <p className="paragraph">
                  <span style={{display: "none"}}>
                    {" "}
                    Eventparkering er til events f.eks. fodboldkampe, koncerter,
                    løb, specielle arrangementer eller andet. Det er en
                    parkeringsplads, der bookes på forhånd og i den tid eventet
                    tager.{" "}
                  </span>
                  Eventparkering er til events f.eks. fodboldkampe, koncerter,
                  løb eller andet. Det er en parkeringsplads, der bookes på
                  forhånd og i den tid eventet tager.
                </p>
              </div>
              <div className="element3">
                <p className="iconHeading">Timeparkering</p>
                <div className="wrapping">
                  <div className="overlappingMessage">
                    <p>
                      Kommer <br />
                      snart
                    </p>
                  </div>
                  <div className="fadingBackground">
                    <img src={Hourly_Type} alt="Stort sort P og et ur" />
                  </div>
                </div>
                <p className="paragraph">
                  Timeparkering er leje af en parkeringsplads for en kortere
                  periode, f.eks. til ærinder, møder eller indkøbsture. Denne er
                  ikke tilgængelig endnu, men kommer senere.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* WAITINGLIST COMPONENT */}
      <section className="waitinglist" id="monthlywaitinglist">
        <div className="content">
          <h2 className="sectionHeading">
            Ønsker du at komme på venteliste til en parkeringsplads?
          </h2>
          <div className="waitinglistContainer">
            <div className="waitinglist1200pxUnder">
              <div className="paragraph">
                Hvis du ikke kan finde den parkeringsplads, du ønsker dig, så
                kan du komme på en venteliste til månedsparkering. Vi vil så
                kontakte dig, hvis der i fremtiden opstår en parkeringsplads,
                der matcher dit ønske.
              </div>
              <MonthlyWaitinglist />
            </div>
            <div className="waitinglist1200pxOver">
              <div className="element1">
                <p className="paragraph">
                  Hvis du ikke kan finde den parkeringsplads, du ønsker dig, så
                  kan du komme på en venteliste til månedsparkering. Vi vil så
                  kontakte dig, hvis der i fremtiden opstår en parkeringsplads,
                  der matcher dit ønske.
                </p>
                <div className="keys">
                  <img
                    src={Keys}
                    alt="Tre grå blå og lyserød nøgler med tal 1,2,3 (kan ikke se)"
                  ></img>
                </div>
              </div>
              <div className="element2">
                <MonthlyWaitinglist />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* "Hvilken type biler kan leje parkering" section */}
      <section className="carType">
        <div className="content">
          <h2 className="sectionHeading">
            Hvilke type biler kan leje parkering?
          </h2>
          <div className="container">
            <div className="element1">
              <div className="paragraph">
                <p>
                  {" "}
                  Vi har også særlig parkering til små biler, så de har flere
                  parkeringspladser at vælge imellem, da små biler også kan
                  bruge de normale parkeringspladser.
                </p>
                <p>
                  Vores standard størrelse på parkeringspladser passer også til
                  stationcars og store personbiler som f.eks. Range Rovers.
                </p>
                <p>
                  På nuværende tidspunkt tilbyder vi ikke parkering til
                  varebiler, minibusser eller lastbiler. Det skyldes, at de ofte
                  er for tunge til fliserne i en normal forhave.
                </p>
              </div>
            </div>
            <div className="vertical-line" />
            <div className="element2">
              <div className="check">
                <img
                  className="checkbox"
                  src={Checkbox}
                  alt="Blå firkantet afkrydsningsfelt med lyserød flueben"
                />
                <p>Små og normale biler</p>
              </div>
              <p className="carTypeMobileOnly">
                Vi har også særlig parkering til små biler, så de har flere
                parkeringspladser at vælge imellem, da små biler også kan brude
                de normale parkeringspladser.
              </p>
              <div className="check">
                <img
                  className="checkbox"
                  src={Checkbox}
                  alt="Blå firkantet afkrydsningsfelt med lyserød flueben"
                />
                <p>Stationcars og store personbiler</p>
              </div>
              <p className="carTypeMobileOnly">
                Vores standard størrelse på parkeringspladser passer også til
                stationcars og store personbiler som f.eks Range Rovers.
              </p>
              <div className="check">
                <img
                  className="grayCheckbox"
                  src={Gray_Checkbox}
                  alt="Grå firkantet afkrydsningsfelt der er tom"
                />
                <p>
                  {width > 250
                    ? "Varebiler, minibusser og autocampere"
                    : "Varebiler, minibusser og auto- campere"}
                </p>
              </div>
              <p className="carTypeMobileOnly">
                På nuværende tidspunkt tilbyder vi ikke parkering til varebiler,
                minibusser eller lastbiler. Det skyldes, at de ofte er for tunge
                til fliserne i en normal forhave.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* FAQ  */}
      <section className="faq spaceBelow">
        <div className="content">
          <h2 className="sectionHeading">Ofte stillede spørgsmål</h2>
          <FAQ tabs={findParkeringFAQ} />
        </div>
      </section>
    </div>
  );
};

export default FindP;
