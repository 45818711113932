// Library
import Slider from "react-slick";

// Styling
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../Styles/Components/AboutUs/Brand2.scss";

// Assets
import Img1 from "../../Assets/Aboutus/brand2/tv2_2.png";
import Img2 from "../../Assets/Aboutus/brand2/savvy.png";
import Img3 from "../../Assets/Aboutus/brand2/DrP4.png";
import Img4 from "../../Assets/Aboutus/brand2/stiftstidende.png";
import Img5 from "../../Assets/Aboutus/brand2/Tv2_oj_2.png";
import Img6 from "../../Assets/Aboutus/brand2/Iværksætteren.png";

const Brand2 = () => {
  const settings = {
    lazyload: true,
    slidesToShow: 4,
    slidesToScroll: 2,
    autoplay: true,
    autoplaySpeed: 4000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 1082,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 620,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="brand2">
      <h2 className="sectionHeading">Du har måske set os her</h2>
      <Slider {...settings}>
        <div className="itemContainer">
          <div>
            <img src={Img1} alt="tv2 logo" />
          </div>
        </div>
        <div className="itemContainer">
          <div>
            <img src={Img2} alt="techsavvy logo" />
          </div>
        </div>
        <div className="itemContainer">
          <div>
            <img src={Img3} alt="drp4 logo" />
          </div>
        </div>
        <div className="itemContainer">
          <div>
            <img src={Img4} alt="stiftstidende logo" />
          </div>
        </div>
        <div className="itemContainer">
          <div>
            <img src={Img5} alt="tv2oj logo" />
          </div>
        </div>
        <div className="itemContainer">
          <div>
            <img src={Img6} alt="iværksætteren logo" />
          </div>
        </div>
      </Slider>
    </div>
  );
};

export default Brand2;
