// Libraries
import { useState } from "react";
import { Form, Col, InputGroup, Button } from "react-bootstrap";
import { format, addYears } from "date-fns";
import process from "process";

// Styling
import "../../Styles/Components/Waitinglist/EventWaitinglist.scss";

// Components
import FormModal from "../FormModal";
import { validateInfo } from "../Validation/InputFormValidation";
import { client } from '../Api/Client'

const EventWaitinglist = () => {

  const initialUserData = {
    fullName: "",
    email: "",
    phoneNumber: "",
    eventName: "",
    eventDate: "",
    eventPlace: "",
    msg: "",
  };

  const [formData, setFormData] = useState(initialUserData);
  const [show, setShow] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleChange = (e) => {
    const updatedData = { ...formData, [e.target.id]: e.target.value };
    setFormData(updatedData);
  };

  const validate = () => {
    let isValid = true;
    const validationObject = {
      fullName: formData.fullName,
      email: formData.email,
      phoneNumber: formData.phoneNumber,
      eventName: formData.eventName,
      eventDate: formData.eventDate,
      eventPlace: formData.eventPlace,
      msg: formData.msg ? formData.msg : null,
    };
    const validation = validateInfo(validationObject);

    if (Object.keys(validation).length !== 0) {
      isValid = false;
    }
    setErrors(validation);
    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsValid(true);
    setLoading(true);
    handleShow();
    if (validate()) {
      try {
        client
          .post("Contact/EventWaitingList", {
            body: {
              fullName: formData.fullName,
              email: formData.email,
              phoneNumber: formData.phoneNumber,
              eventName: formData.eventName,
              eventDato: formData.eventDate,
              eventPlace: formData.eventPlace,
              message: formData.msg,
            }
          })
          .then((res) => {
            console.log("WUUHUUUU DATA FROM REQUEST", res);
            setIsValid(true);
            setLoading(false);
            setFormData(initialUserData);
          });
      } catch (error) {
        setIsValid(false);
        setLoading(false);
        console.log("OH NOOOOO, ERRRRORRR", error);
      }
    } else {
      setIsValid(false);
      setLoading(false);
      handleClose();
    }
  };

  return (
    <div className="waitingList">
      <Form onSubmit={handleSubmit} noValidate>
        <Form.Row>
          <Form.Group as={Col} controlId="fullName">
            <Form.Label className="formlabel">Navn</Form.Label>
            <Form.Control
              type="text"
              placeholder="Indtast dit navn"
              // required
              onChange={handleChange}
              value={formData.fullName}
              isInvalid={!!errors.fullName}
            />
            {errors.fullName ? (
              <Form.Control.Feedback type="invalid">
                {errors.fullName}
              </Form.Control.Feedback>
            ) : null}
          </Form.Group>
          <Form.Group as={Col} controlId="email">
            <Form.Label className="formlabel">Email</Form.Label>
            <Form.Control
              type="email"
              placeholder="Indtast din email"
              required
              onChange={handleChange}
              value={formData.email}
              isInvalid={!!errors.email}
            />
            {errors.email ? (
              <Form.Control.Feedback type="invalid">
                {errors.email}
              </Form.Control.Feedback>
            ) : null}
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} controlId="phoneNumber">
            <Form.Label className="formlabel">Telefonnummer</Form.Label>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text>+45</InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                type="tel"
                placeholder="Indtast dit telefonnummer"
                aria-describedby="inputGroupPrepend"
                required
                onChange={handleChange}
                value={formData.phoneNumber}
                isInvalid={!!errors.phoneNumber}
                className={errors.phoneNumber ? "inputgroup-right-radius" : ""}
              />
              {errors.phoneNumber ? (
                <Form.Control.Feedback type="invalid">
                  {errors.phoneNumber}
                </Form.Control.Feedback>
              ) : null}
            </InputGroup>
          </Form.Group>
          <Form.Group as={Col} controlId="eventPlace">
            <Form.Label className="formlabel">Event sted</Form.Label>
            <Form.Control
              as="select"
              required
              onChange={handleChange}
              value={formData.eventPlace}
              defaultValue="Vælg"
              isInvalid={!!errors.eventPlace}
              className={errors.eventPlace ? "erroricon" : ""}
            >
              <option selected disabled value="">
                Vælg
              </option>
              <option value="Ceres Park ">Ceres Park &#38; Arena</option>
              <option value="Tivoli Friheden">Tivoli Friheden</option>
              <option value="Jydsk Væddeløbsbane">Jydsk Væddeløbsbane</option>
              <option value="Mindeparken">Mindeparken</option>
              <option value="Aarhus 1900 Hallen">Aarhus 1900 Hallen</option>
              <option value="Andet sted i Marselisområdet">
                Andet sted i Marselisområdet
              </option>
            </Form.Control>
            {errors.eventPlace ? (
              <Form.Control.Feedback type="invalid">
                {errors.eventPlace}
              </Form.Control.Feedback>
            ) : null}
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} controlId="eventName">
            <Form.Label className="formlabel">Navnet på eventet</Form.Label>
            <Form.Control
              placeholder="Indtast navnet på eventet"
              required
              type="text"
              onChange={handleChange}
              value={formData.eventName}
              isInvalid={!!errors.eventName}
            />
            {errors.eventName ? (
              <Form.Control.Feedback type="invalid">
                {errors.eventName}
              </Form.Control.Feedback>
            ) : null}
          </Form.Group>
          <Form.Group as={Col} controlId="eventDate">
            <Form.Label className="formlabel">Dato for eventet</Form.Label>
            <Form.Control
              type="date"
              required
              min={format(new Date(), "y-MM-dd")}
              max={format(addYears(new Date(), 5), "y-MM-dd")}
              placeholder="Indtast hvilken dato eventet er"
              onChange={handleChange}
              value={formData.eventDate}
              isInvalid={!!errors.eventDate}
            />
            {errors.eventDate ? (
              <Form.Control.Feedback type="invalid">
                {errors.eventDate}
              </Form.Control.Feedback>
            ) : null}
          </Form.Group>
        </Form.Row>
        <Form.Group controlId="msg">
          <Form.Label className="formlabel">
            Besked <span className="formlabel-optional">(valgfri)</span>
          </Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            placeholder="Skriv gerne en besked, særligt hvis du har specielle ønsker, spørgsmål eller andet"
            onChange={handleChange}
            value={formData.msg}
            isInvalid={!!errors.msg}
          />
          {errors.msg ? (
            <Form.Control.Feedback type="invalid">
              {errors.msg}
            </Form.Control.Feedback>
          ) : null}
        </Form.Group>
        <Form.Group className="noSpace">
          <div className="regular-button">
            {loading ? (
              <Button disabled id="btn-submit" type="submit">
                Sender...
              </Button>
            ) : (
              <Button id="btn-submit" type="submit">
                Send
              </Button>
            )}
          </div>
        </Form.Group>
      </Form>
      <FormModal
        isValid={isValid}
        handleClose={handleClose}
        show={show}
        text="Din besked er nu sendt afsted. Vi kontakter dig i den nærmeste fremtid."
      />
    </div>
  );
};

export default EventWaitinglist;
