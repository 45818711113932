//#region - Imports
// Libraries
import Button from "react-bootstrap/Button";
import {useRef} from "react";
import {Link} from "react-router-dom";

// Styling
import "../Styles/Pages/RentOutP.scss";

// Components
import RentOutForm from "../Components/RentOutForm";
import RentOutForm2 from "../Components/RentOutForm2";
import Meta from "../Components/Meta";
import FAQ from "../Components/FAQ";
import {rentOutFAQ} from "../Components/FAQWidgetDatas";
import {COOKIE_NAMES, getCookieValue} from "../Components/Tracking/Cookie";
import {initGA, pageView} from "../Components/Tracking/Tracking";

// Assets
import House from "../Assets/Compressed/cars-parked-house.jpg";// compressed image
import Apartment from "../Assets/Compressed/cars-parked-apartment-building.jpg";// compressed image
import Company from "../Assets/Compressed/cars-parked-company-office.jpg";// compressed image
import Decision_Icon from "../Assets/Udlejparkering/hand-clock-arrows.png";
import Booking_Icon from "../Assets/Udlejparkering/laptop-calendar-clock.png";
import HouseParking_Icon from "../Assets/Udlejparkering/house-parking-spot.png";
import Event_Type from "../Assets/Udlejparkering/pspot_types/black-parking-concert-tickets.png";
import Monthly_Type from "../Assets/Udlejparkering/pspot_types/black-parking-calendar.png";
import Hourly_Type from "../Assets/Udlejparkering/pspot_types/black-parking-clock.png";
import DK_Map from "../Assets/Udlejparkering/grey-denmark-map.png";
import plantsAndCoins from "../Assets/Compressed/plants-coins-house.jpg";// compressed image
import Cleaning_icon from "../Assets/Udlejparkering/cleaning-man-mop-bucket.png";
import Dog_icon from "../Assets/Udlejparkering/person-dog-walking.png";
import TV_icon from "../Assets/Udlejparkering/tv-television-screen.png";
import Tivoli_icon from "../Assets/Udlejparkering/white-theme-park-carosuel-rollercoaster.png";
import Ticket from "../Assets/Udlejparkering/white-event-tickets-music.png";
import SharedEconomy_Illustration from "../Assets/Udlejparkering/house-circle-arrows-car.png";
import Checkbox from "../Assets/Udlejparkering/blue-pink-tick-checkmark.png";
import Gray_Checkbox from "../Assets/Udlejparkering/grey-square-box.png";
//#endregion

const RentOutP = () => {
  if (getCookieValue(COOKIE_NAMES.PERFORMANCE)) {
    initGA();
    pageView();
  }

  const scrollRef = useRef(null);
  const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop - 100);
  const executeScroll = () => scrollToRef(scrollRef);

  return (
    <div className="udlejparkering">
      <Meta
        title="Udlej din Parkering & Tjen Penge | ParkShare"
        description="Udlej parkering med ParkShare. Med ParkShare kan du udleje din parkeringsplads, og tjene penge når den er tom. Du bestemmer hvornår og hvordan den skal udlejes."
      />
      {/* TOP */}
      <section className="top">
        <div className="bg">
          <div className="content">
            <div className="element1">
              <h1 className="pageHeading">
                Udlej din parkering og <br /> tjen penge nemt og bekvemt
              </h1>
              <Button className="topButton" onClick={executeScroll}>
                Udlej Parkering
              </Button>
            </div>
            <div className="element2">
              <iframe
                title="udlejVideo"
                src="https://www.youtube.com/embed/WCJ_Mfs3Y7o"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
      </section>
      <section className="mobileVideo">
        <iframe
          height="196px"
          width="350px"
          title="udlejVideo"
          src="https://www.youtube.com/embed/WCJ_Mfs3Y7o"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        ></iframe>
      </section>
      {/* "Hvem kan udleje en parkeringsplads" section */}
      <section className="whocanrent spaceBelow">
        <div className="content">
          <div className="box">
            <h2 className="sectionHeading">
              Hvem kan udleje en parkeringsplads?
            </h2>
            <p className="paragraph">
              Alle der har råderetten over en parkeringsplads, kan udleje den.
              Det er derfor kun private parkeringspladser, der kan udlejes
              gennem ParkShare. Man kan både udleje sin forhave, baghave,
              græsmark, parkeringskælder, ved et sommerhus eller andet, hvor en
              bil kan holde sikkert. De typiske udlejninger er hos:
            </p>
            <div className="container">
              <div className="element1">
                <p className="iconHeading">Hus</p>
                <div className="fadingBackground">
                  <img
                    src={House}
                    alt="Biler og parkeringsplads foran et privat hjem"
                  />
                </div>
                <p className="iconParagraph">
                  En parkering ved et hus. Det kan f.eks. være en indkørsel,
                  carport, garage, græsplæne, forhave, baghave, gårdsplads eller
                  andet på privat grund
                </p>
              </div>
              <div className="element2">
                <p className="iconHeading">Lejlighed</p>
                <div className="fadingBackground">
                  <img
                    src={Apartment}
                    alt="Biler og parkeringsplads foran en lejlighed"
                  />
                </div>
                <p className="iconParagraph">
                  En parkering tilknyttet en lejlighed, f.eks i en baggård eller
                  P-kælder. Det må ikke være en parkering der deles, da du skal
                  sikre, den står ledig ved udlejning
                </p>
              </div>
              <div className="element3">
                <p className="iconHeading">Virksomhed</p>
                <div className="fadingBackground">
                  <img
                    src={Company}
                    alt="Biler og parkeringsplads foran en kontorbygning"
                  />
                </div>
                <p className="iconParagraph">
                  En parkering tilknyttet en virksomhed. De bruges ofte kun i
                  dagstimerne, og kan derfor udlejes om natten og i weekenden.
                  &nbsp;
                  <Link to="/for-virksomheder">Klik her</Link> for mere info.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="whenCanYouRent">
        <div className="shadowBox spaceBelow spaceAbove">
          <h2 className="sectionHeading">
            Hvornår kan man udleje en parkeringsplads?
          </h2>
          <div className="content spaceBelow">
            <div className="leftElement">
              <p className="paragraph">
                Hvis du har flere parkeringspladser, kan du vælge kun at udleje
                nogle af dem, eller udleje dem som forskellige typer af
                parkering (Måneds-, Event- eller Timeparkering).
              </p>
              <br></br>
              <p className="paragraph">
                Som udlejer bestemmer du selv hvilke dage, timer, tidsrum og
                type du vil udleje.
              </p>
              <br></br>
              <p className="paragraph">
                Som udlejer bestemmer du selv hvornår og hvordan du ønsker at
                udleje din parkeringsplads, samt hvor mange parkeringspladser du
                ønsker at udleje. Det vigtigste er at det er din
                parkeringsplads, eller at du har råderetten over den, sådan at
                andre ikke bruger den, når den er udlejet.
              </p>
              <br></br>
            </div>
            <div className="iconAndText">
              <div className="rightElements">
                <img
                  id="decisionIcon"
                  src={Decision_Icon}
                  alt="Hånd med lyserød pil peger på et ur"
                />
                <p className="paragraph bold">
                  Du bestemmer selv hvor mange pladser du vil udleje, og i
                  hvilke tidsrum hver plads skal udlejes i
                </p>
              </div>
              <div className="rightElements">
                <img
                  id="bookingIcon"
                  src={Booking_Icon}
                  alt="Sort laptop med en kalender og et ur på en skærm"
                />
                <p className="paragraph bold">
                  Du bestemmer selv tidspunktet, perioden, dagene, og det kan
                  både være nat og dag
                </p>
              </div>
              <div className="rightElements">
                <img
                  id="houseParkingIcon"
                  src={HouseParking_Icon}
                  alt="Sort hus med lyserød parkeringsplads foran"
                />
                <p className="paragraph bold">
                  Du skal enten være ejer af én eller flere parkeringspladser,
                  eller have råderetten over den
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* "Hvilke type parkering kan man udleje?" section */}
      <section className="pspotstyper">
        <div className="content">
          <div className="box">
            <h2 className="sectionHeading">
              Hvilke type parkering kan man udleje?
            </h2>
            <div className="container">
              <div className="element1">
                <p className="iconHeading">Månedsparkering</p>
                <div className="fadingBackground">
                  <img src={Monthly_Type} alt="Stort sort P og en kalender" />
                </div>
                <p className="paragraph">
                  Månedsparkering er en fast parkeringsplads, der udlejes en
                  måned ad gangen. Den kan have en slutdato eller være
                  ubegrænset, og med forskellige tidstyper og intervaller.
                </p>
              </div>
              <div className="element2">
                <p className="iconHeading">Eventparkering</p>
                <div className="fadingBackground">
                  <img
                    src={Event_Type}
                    alt="Stort sort P og koncert billetter med musik node"
                  />
                </div>
                <p className="paragraph">
                  Eventparkering er til events f.eks. fodboldkampe, koncerter,
                  løb, etc. Den har en højere timepris, og er ofte 1 time før og
                  efter eventet.
                  <span style={{display: "none"}}>
                    Du kan udlej kun til events, men også timeparkering.
                  </span>
                </p>
              </div>
              <div className="element3">
                <p className="iconHeading">Timeparkering</p>
                <div className="wrapping">
                  <div className="overlappingMessage">
                    <p>
                      Kommer <br />
                      snart
                    </p>
                  </div>
                  <div className="fadingBackground">
                    <img src={Hourly_Type} alt="Stort sort P og et ur" />
                  </div>
                </div>
                <p className="paragraph">
                  Timeparkering er udlejning til bilister, der ønsker parkering
                  for en kortere periode, f.eks. til ærinder, møder eller
                  indkøbsture. Du kan selv fastsætte de dage og tidspunkter som
                  du ønsker.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* "Hvem sætter prisen for parkering" section */}
      <section className="pris spaceBelow">
        <div className="content">
          <h2 className="sectionHeading">Hvem sætter prisen for parkering?</h2>
          <div className="container">
            <div className="element1">
              <h3 className="paragraphHeading">Hvem</h3>
              <p className="paragraph">
                Prisen for parkering er fastsat af ParkShare.
              </p>
              <h3 className="paragraphHeading">Hvordan</h3>
              <p className="paragraph">
                Priserne for parkering er fastsat og afhænger af flere ting. Det
                afhænger af typen af parkeringen (Månedsparkering,
                Eventparkering eller Timeparkering), samt tidspunkt på dagen.
                Derudover sætter ParkShare prisen efter hvilken by, hvilket
                område i byen og hvad andre parkeringspriser er i det pågældende
                område.
              </p>
              <h3 className="paragraphHeading">Hvorfor</h3>
              <p className="paragraph">
                ParkShare fastsætter prisen sådan at du som udlejer ikke behøver
                at bekymre dig om hvad dine naboer tager, om du sætter prisen
                for højt eller lavt, eller hvad det ellers koster - det arbejde
                har ParkShare allerede lavet for dig, og vi gør hvad vi kan for
                at sikre udlejer bedst mulig pris, og samtidig flest mulige
                lejere. Samtidig gør det også, at bilisterne får en fair pris,
                at de ved hvad de kan forvente og at de får en bedre oplevelse.
              </p>
            </div>
            <div className="element2">
              <img src={DK_Map} alt="Grå kort af Danmark" />
            </div>
          </div>
        </div>
      </section>
      {/* "Hvor meget kan man tjene" section */}
      <section className="earnings">
        <div className="content">
          <div className="earnings-image">
            <img
              src={plantsAndCoins}
              alt="Papirhus og stakker af mønter med planter"
            />
            <div className="overlay"></div>
          </div>
          <div className="earnings-details spaceBelow">
            <h2 className="sectionHeading">Hvor meget kan man tjene?</h2>
            <div className="earningsText">
              <p className="paragraph">
                Indtægterne fra parkering varierer efter typen du udlejer, hvor
                ofte, og hvor i landet du udlejer. Månedsparkering giver en fast
                indtægt og en fast lejer, hvor timeparkering kan give en højere
                indtægt, men med større usikkerhed om hvor ofte den udlejes.
              </p>
              <h3 className="paragraphHeading moreSpace">Månedsparkering</h3>
              <p className="paragraph">
                Indtægterne for månedsparkering er forskellige afhængigt af
                typen. Udlejes en parkering på månedsbasis 24/7 i Aarhus C, vil
                det give en månedlig indtægt på 800-1.200 kr. alt efter
                placering, og ca. 12.000 kr. årligt.
              </p>
              <h3 className="paragraphHeading">Timeparkering</h3>
              <p className="paragraph">
                Hvis du f.eks. bor i Aarhus C og udlejer en parkeringsplads i de
                8 timer i hverdagen, hvor du alligevel er på arbejde, vil du
                potentielt kunne tjene over 20.000 kr. årligt.
                <br />
                Hvis du samtidigt udlejer på andre tidspunkter, weekender eller
                ferier, vil du kunne tjene endnu mere.
              </p>
              <h3 className="paragraphHeading">Eventparkering</h3>
              <p className="paragraph lastParagraph">
                Hvis du eksempelvis bor i Aarhus, omkring Ceres Park og Tivoli
                Friheden, vil du kunne tjene fra 41 kr. per parkeringsplads til
                et event. Årligt vil det give ca. 3.000 kr. per parkeringsplads.
                Der er flest events om sommeren, og du vælger selv de events, du
                ønsker at udleje til.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* "Hvad 20.000kr svarer til" */}
      <section className="infografik spaceAbove spaceBelow">
        <div className="content">
          <h2 className="sectionHeading">Hvad 20.000 kr. svarer til</h2>
          <div className="container">
            <div className="element">
              <img
                src={Cleaning_icon}
                alt="Grafik af Rengøringsmand der holder en spand og moppe"
              />
              <p className="iconParagraph">
                5 timers rengøring <br /> hver uge hvert år
              </p>
            </div>
            <div className="element">
              <img
                src={Dog_icon}
                alt="Grafik af En person der går med en hund"
              />
              <p className="iconParagraph">
                400 timers <br /> hundeluftning
              </p>
            </div>
            <div className="element">
              <img src={TV_icon} alt="Grafik af En skærm med en play knap" />
              <p className="iconParagraph">
                155 måneders abonnement af Netflix
              </p>
            </div>
            <div className="element">
              <img src={Tivoli_icon} alt="Grafik af Hvid forlystelsespark" />
              <p className="iconParagraph">52 ture i Tivoli KBH med turpas</p>
            </div>
            <div className="element">
              <img
                src={Ticket}
                alt="Grafik af Hvid koncert billetter med musik node"
              />
              <p className="iconParagraph">8 billetter til Roskilde Festival</p>
            </div>
          </div>
        </div>
      </section>
      {/* Input fields to renting a pspot out  */}
      <section className="udlejPladser" ref={scrollRef} id="form">
        <h2 className="sectionHeading">Udlej din parkeringsplads</h2>
        <p className="paragraph">
          Du kan starte med at udleje din parkeringsplads nu, alt du skal gøre
          er at udfylde formen herunder, og så kontakter vi dig
        </p>
        <RentOutForm2 />
      </section>
      {/* "Hvorfor udleje sin parkering" section */}
      <section className="whyrentout">
        <div className="content">
          <h2 className="sectionHeading">Hvorfor udleje sin parkering?</h2>
          <div className="container">
            <div className="element1">
              <p className="paragraph">
                Udover den økonomiske gevinst, kan du være med til at skabe en
                verden uden parkeringsproblemer. Du hjælper samfundet, trafikken
                og miljøet. <br /> <br />
                Hvis du selv er bilist eller passager, så ved du sikkert hvor
                tidskrævende og besværligt det kan være, at finde en
                parkeringsplads. Ved at udleje din egen parkering, er du med til
                at opbygge et større udbud af parkeringspladser, og dermed
                hjælpe andre med nem og bekvem parkering. Det gør også, at det
                vil være lettere for dig, næste gang du mangler en
                parkeringsplads. <br /> <br />
                Sammen løser vi parkeringsproblemet!
              </p>
            </div>
            <div className="element2">
              <img
                src={SharedEconomy_Illustration}
                alt="Grafik af et hus en bil og ParkShare logo forbundet med pile"
              />
            </div>
          </div>
        </div>
      </section>
      {/* "Hvilke type biler kan leje parkering" section */}
      <section className="carType">
        <div className="content">
          <h2 className="sectionHeading">
            Hvilke type biler kan leje parkering?
          </h2>
          <div className="container">
            <div className="element1">
              <div className="paragraph">
                <p>
                  Hvis du har en parkering, som ikke kan indeholde en større
                  eller normal bil, så kan du udleje den til mindre bybiler og
                  stadig få en indtjening.
                </p>
                <br></br>
                <p>
                  En normal standard størrelse på parkeringspladser skal passe
                  til normale biler, stationcars og store personbiler som f.eks
                  Range Rovers.
                </p>
                <br></br>
                <p>
                  Mange private parkeringspladser er i forhaver, hvor der ofte
                  ligger fliser. Det tager vi hensyn til, og derfor udlejer vi
                  ikke til tunge køretøjer.
                </p>
              </div>
            </div>
            <div className="vertical-line" />
            <div className="element2">
              <div className="check">
                <img
                  className="checkbox"
                  src={Checkbox}
                  alt="Blå firkantet afkrydsningsfelt med lyserød flueben"
                />
                <p>Små og normale biler</p>
              </div>
              <p className="carTypeMobileOnly">
                Hvis du har en parkering, som ikke kan indeholde en større eller
                normal bil, så kan du udleje den til mindre city biler og stadig
                få en indtjening.
              </p>
              <div className="check">
                <img
                  className="checkbox"
                  src={Checkbox}
                  alt="Blå firkantet afkrydsningsfelt med lyserød flueben"
                />
                <p>Stationcars og store personbiler</p>
              </div>
              <p className="carTypeMobileOnly">
                En normal standardstørrelse på parkeringspladser skal passe til
                normale biler, stationcars og store personbiler som f.eks. Range
                Rovers.
              </p>
              <div className="check">
                <img
                  className="grayCheckbox"
                  src={Gray_Checkbox}
                  alt="Grå firkantet afkrydsningsfelt der er tom"
                />
                <p>Varebiler, minibusser og autocampere</p>
              </div>
              <p className="carTypeMobileOnly">
                Mange private parkeringspladser er i forhaver, hvor der ofte
                ligger fliser. Det tager vi hensyn til, og derfor udlejer vi
                ikke til tunge køretøjer.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* FAQ */}
      <section className="udlejFaq spaceBelow">
        <div className="content">
          <h2 className="sectionHeading">Ofte stillede spørgsmål</h2>
          <FAQ tabs={rentOutFAQ}></FAQ>
        </div>
      </section>
    </div>
  );
};

export default RentOutP;
