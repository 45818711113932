import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "../Styles/Components/ReviewCarousel.scss";
const ReviewCarousel = () => {
  const settings = {
    dots: false,
    lazyload: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 7000,
    cssEase: "linear",
    arrows: false,
  };
  return (
    <Slider className="reviewCarousel" {...settings}>
      <div>
        <p>
          ParkShare har hjulpet mig med at tjene nogle ekstra penge hver måned
          uden, at jeg har skulle gøre noget for det.
        </p>
        <p>-Bodil (udlejer)</p>
      </div>
      <div>
        <p>
          Det er dejligt at man ved præcis, hvor man skal parkere. Jeg behøver
          ikke længere køre rundt og lede, men kan køre direkte hen på en plads.
        </p>
        <p>-Zilas (lejer)</p>
      </div>
      <div>
        <p>
          Det er dejligt at se hvor glade andre bliver, for at de kan leje ens
          indkørsel, som vi alligevel ikke skulle bruge.
        </p>
        <p>-Kurt (udlejer)</p>
      </div>
      <div>
        <p>
          Det giver en stor sikkerhed, at jeg ved hvor jeg skal parkere allerede
          inden jeg kører hjemmefra.
        </p>
        <p>-Camilla (lejer)</p>
      </div>
      <div>
        <p>
          Det er dejligt at kunne støtte om det lokale liv og events i byen. Vi
          vil derfor også gerne hjælpe med at gøre det lettere som gæst til
          events.
        </p>
        <p>-Anders (udlejer)</p>
      </div>
      <div>
        <p>
          Vi tager til mange events, og derfor er det dejligt, at vi kan booke
          den samme parkering. Der er intet bøvl, og vi kan hygge os og have det
          sjovt.
        </p>
        <p>-Jørgen (lejer)</p>
      </div>
    </Slider>
  );
};
export default ReviewCarousel;
