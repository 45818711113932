import info from "../Assets/Forbusiness/info-icon.png";
import "../Styles/Components/InfoBox2.scss";
function InfoBox2(props) {
  return (
    <span className="info">
      <img src={info} alt="Info" className="info-icon" />
      <div className={props.placement}>
        <div className="info-box">
          <p className="text">{props.infoText}</p>
        </div>
  
      </div>
    </span>
  );
}
export default InfoBox2;
