import React, { useState } from "react";
import Cookies from "js-cookie";
import "../../Styles/Components/Tracking/Cookie.scss";
import cookieClosedImage from "../../Assets/Cookies/cookies-closed.png";
import cookieOpenImage from "../../Assets/Cookies/cookies-open.png";
import expandButtonImage from "../../Assets/Cookies/down-arrow.svg";
import { Checkbox, FormControlLabel, FormGroup } from "@material-ui/core";
import { initGA } from "./Tracking";
import { Link } from "react-router-dom";
import { pageview } from "react-ga";
export const SAME_SITE_OPTIONS = {
  STRICT: "strict",
  LAX: "lax",
  NONE: "none",
};
export const COOKIE_NAMES = {
  CONSENT: "CookieConsent", // Whether the user has accepted cookies
  PREFERENCE: "CookiePreference", // For storing user-specific things like settings across sessions
  PERFORMANCE: "CookiePerformance", // For tracking purposes
};
/**
 * Get the legacy cookie by the regular cookie name
 * @param {string} name of the cookies to get
 */
const getLegacyCookieName = (name) => {
  return name + "-legacy";
};
/**
 * Returns the value of the cookie, default being the consent cookie
 * Retrieves the regular value first and if not found the legacy one
 * @param {string} name optional name of the cookies
 */
export const getCookieValue = (name = COOKIE_NAMES.CONSENT) => {
  let cookieValue = Cookies.get(name);
  // if the cookie value is undefined check for a legacy cookie
  if (cookieValue === undefined) {
    cookieValue = Cookies.get(getLegacyCookieName(name));
  }
  return cookieValue;
};
/**
 * Function to set cookies, will set two cookies to handle incompatible browser, more details:
 * https://web.dev/samesite-cookie-recipes/#handling-incompatible-clients
 * @param {string} cookieName
 * @param {*} cookieValue
 */
export const setCookie = (
  cookieName,
  cookieValue,
  sameSite = SAME_SITE_OPTIONS.LAX
) => {
  // Fallback for older browsers
  if (sameSite === SAME_SITE_OPTIONS.NONE) {
    Cookies.set(getLegacyCookieName(cookieName), cookieValue, {
      sameSite,
      path: "",
      secure: true,
      expires: 31,
    });
  }
  // Regular cookie
  Cookies.set(cookieName, cookieValue, {
    sameSite,
    path: "",
    secure: true,
    expires: 31,
  });
};
/**
 * Function to remove cookies, will remove both the legacy and regular version.
 * @param {*} cookieName Name of the cookie to remove
 */
export const removeCookie = (cookieName) => {
  Cookies.remove(getLegacyCookieName(cookieName));
  Cookies.remove(cookieName);
};
const Cookie = (props) => {
  const [state, setState] = useState({
    visible: !getCookieValue(),
    expanded: false,
    nessecaryConsent: true,
    preferenceConsent: true,
    performanceConsent: true,
  });
  /**
   * Set state to accept all cookies, then update cookies
   */
  const acceptAll = () => {
    setCookie(COOKIE_NAMES.CONSENT, true);
    setCookie(COOKIE_NAMES.PREFERENCE, {});
    setCookie(COOKIE_NAMES.PERFORMANCE, true);
    initGA();
    pageview();
    setState({ ...state, visible: false, expanded: false });
  };
  /**
   * Set state to decline all but nessecary, then update cookies
   */
  const acceptNessecary = () => {
    setCookie(COOKIE_NAMES.CONSENT, true);
    setCookie(COOKIE_NAMES.PREFERENCE, false);
    setCookie(COOKIE_NAMES.PERFORMANCE, false);
    removeCookie("_ga");
    removeCookie("_gat");
    removeCookie("_gid");
    setState({ ...state, visible: false, expanded: false });
  };
  /**
   * Handle form input change with React
   */
  const handleInputChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
  };
  /**
   * Handle expand button click
   */
  const handleExpand = (event) => {
    setState({
      ...state,
      expanded: !state.expanded,
    });
  };
  const handleCookieUpdate = (event) => {
    setCookie(COOKIE_NAMES.CONSENT, true);
    if (state.preferenceConsent) {
      setCookie(COOKIE_NAMES.PREFERENCE, {});
    } else {
      setCookie(COOKIE_NAMES.PREFERENCE, false);
    }
    if (state.performanceConsent) {
      setCookie(COOKIE_NAMES.PERFORMANCE, true);
      initGA();
      pageview();
    } else {
      setCookie(COOKIE_NAMES.PERFORMANCE, false);
      removeCookie("_ga");
      removeCookie("_gat");
      removeCookie("_gid");
    }
    setState({ ...state, visible: false, expanded: false });
  };
  // Handle minimized cookiebar click
  const handleMinimized = (event) => {
    setState({
      ...state,
      visible: true,
    });
  };
  // Extracted form control for conditional rendering
  const cookieOptions = state.expanded ? (
    <div className="cookie-expand-container">
      <FormGroup row>
        <FormControlLabel
          className="cookie-checkbox-text"
          control={
            <Checkbox
              disabled
              name="nessecaryConsent"
              checked={state.nessecaryConsent}
              onChange={handleInputChange}
            />
          }
          label="Nødvendige"
        />
        <FormControlLabel
          className="cookie-checkbox-text"
          control={
            <Checkbox
              name="preferenceConsent"
              checked={state.preferenceConsent}
              onChange={handleInputChange}
            />
          }
          label="Præferencer"
        />
        <FormControlLabel
          className="cookie-checkbox-text"
          control={
            <Checkbox
              name="performanceConsent"
              checked={state.performanceConsent}
              onChange={handleInputChange}
            />
          }
          label="Statistik"
        />
      </FormGroup>
      <div className="cookie-expand-content">
        <Link to="/privacy-policy" className="cookie-expand-privacy-policy">
          Privatlivspolitik
        </Link>
        <button
          className="cookie-expand-confirm-button"
          onClick={handleCookieUpdate}
        >
          Gem ændringer
        </button>
      </div>
    </div>
  ) : null;
  // Extracted expand button for conditional rendering
  const expandButton = state.expanded ? (
    <img className="cookie-expand-button-reverse" src={expandButtonImage} />
  ) : (
    <img className="cookie-expand-button" src={expandButtonImage} />
  );
  // Extracted image for conditional rendering
  const cookieImage = state.expanded ? (
    <img
      src={cookieOpenImage}
      alt="Cookies holding hands"
      className="cookie-image cookie-image-open"
    />
  ) : (
    <img
      src={cookieClosedImage}
      alt="Cookies holding hands"
      className="cookie-image cookie-image-closed"
    />
  );
  return (
    <section className="cookie-bar">
      <div
        className={
          state.visible
            ? "cookie-container maximize"
            : "cookie-container hidden"
        }
      >
        {cookieImage}
        <div className="cookie-content">
          <div className="cookie-text-container">
            <h3 className="cookie-header">Cookies</h3>
            <p className="cookie-text">
              Vi bruger cookies for at give dig den bedste oplevelse.
            </p>
          </div>
          <div className="cookie-button-container">
            <button className="cookie-decline-button" onClick={acceptNessecary}>
              Kun nødvendige
            </button>
            <button className="cookie-accept-button" onClick={acceptAll}>
              Accepter alle
            </button>
          </div>
          <div className="cookie-control-container">
            <div className="cookie-expand-controls" onClick={handleExpand}>
              {expandButton}
              <p className="cookie-expand-text">Cookie præferencer</p>
            </div>
          </div>
          {cookieOptions}
        </div>
      </div>
      <div
        className={
          state.visible ? "cookie-options hidden" : "cookie-options minimize"
        }
      >
        <div className="cookie-options-col">
          <a className="cookie-options-text" onClick={handleMinimized}>
            Cookie indstillinger
          </a>
        </div>
        <div className="cookie-options-col">
          <a className="terms-text" href="/Terms">
            Servicevilkår
          </a>
        </div>
      </div>
    </section>
  );
};
export default Cookie;
