// IMPORT ROUTING
import { BrowserRouter, Route, Switch } from "react-router-dom";
import {Redirect} from "react-router";
import RouteChangeTracker from "./Utils/RouteChangeTracker"
import ReactGA from 'react-ga';
import { useEffect } from "react";

// DIV
import Navbar from "./Layouts/Header.jsx";
import Footer from "./Layouts/Footer.jsx";
import ScrollToTop from "./Components/ScrollToTop";
// STATIC
import Frontpage from "./Pages/Frontpage";
import FindP from "./Pages/FindP";
import RentOutP from "./Pages/RentOutP";
import ForCompanies from "./Pages/ForCompanies";
import Event from "./Pages/Event";
import Contact from "./Pages/Contact";
import FAQPage from "./Pages/FAQPage";
import Error from "./Pages/Error";
import PrivacyPolicy from "./Pages/PrivacyPolicy.jsx";
import Terms from "./Pages/Terms";
import AboutUs from "./Pages/AboutUs";
import './App.css'
function App() {

  const TRACKING_ID = "UA-189483547-1"; 
  ReactGA.initialize(TRACKING_ID);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://www.googletagmanager.com/gtag/js?id=G-V16XGV6DP3%22%3E";
    script.async = true;
    document.body.appendChild(script);

    const configScript = document.createElement('script');
    script.src = "./Utils/GA4config.js";
    document.body.appendChild(configScript);
  return () => {
      document.body.removeChild(script);
    }
  }, []);

  return (
    
    <BrowserRouter>
    <RouteChangeTracker ></RouteChangeTracker>
      <Navbar></Navbar>
      <ScrollToTop />
      <div className="App">
        <Switch>
          <Route exact path="/" component={Frontpage} /> 
          <Route path="/find-parkering" component={FindP} /> 
          <Route path="/udlej-parkering" component={RentOutP} /> 
          <Route path="/event" component={Event} />
          <Route path="/for-virksomheder" component={ForCompanies} />
          <Route path="/kontakt" component={Contact} />
          <Route path="/faq" component={FAQPage} />
          <Route path="/om-os" component={AboutUs} />
          <Route path="/terms" component={Terms} />
          <Route path='/privacy-policy' component={PrivacyPolicy} />
          <Route path='/blind-vej' component={Error}/>
            <Redirect to='/blind-vej'/>
        </Switch>
      </div>
      <Footer></Footer>
    </BrowserRouter>
  );
}
export default App;
