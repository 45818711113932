import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const findParkeringFAQ = [
  {
    id: 1,
    label: "Hvordan lejer jeg en parkeringsplads?",
    description: (
      <p>
        For at leje en parkeringsplads igennem ParkShare skal du finde den
        parkeringsplads, der bedst matcher dine ønsker. Hvis der er et match, så
        skal du trykke på ”Lej”-knappen og udfylde formularen herunder og sende
        dit ønske om parkering. Herefter kontakter ParkShare dig for yderligere
        informationer og betaling. Efter betaling modtager du en bekræftelse via
        email på din leje.
        <br></br>
        Du er også meget velkommen til at kontakte os pr. telefon, email eller
        sende en besked gennem vores{" "}
        <HashLink smooth to="/kontakt#kontaktos">
          formular
        </HashLink>{" "}
        på ”Kontakt os”-siden, og høre om vi evt. har andre parkeringspladser
        til rådighed end dem på siden. Du kan også blive skrevet op på en{" "}
        <HashLink to="/find-parkering#monthlywaitinglist">venteliste</HashLink>{" "}
        , sådan at vi kontakter dig, når der bliver en ledig parkeringsplads i
        forhold til dit ønske.
        <br />
      </p>
    ),
  },
  {
    id: 2,
    label: "Kan jeg komme på venteliste til en parkeringsplads?",
    description: (
      <p>
        Ja, du kan godt blive skrevet på en venteliste eller ønskeliste til en
        parkeringsplads. Det gør du ved at udfylde inputfeltet{" "}
        <HashLink to="/find-parkering#monthlywaitinglist">her</HashLink>, og
        hermed sende dit ønske om at komme på en venteliste. Hvis vi får en
        ledig parkeringsplads, der matcher dit ønske, kontakter vi dig, og du
        vil derfor komme forrest i køen.
      </p>
    ),
  },
  {
    id: 3,
    label: "Hvor længe kan jeg holde parkeret?",
    description: (
      <p>
        Udlejere hos ParkShare bestemmer selv, hvor længe de ønsker at udleje
        deres parkeringsplads. Som lejer kan du derfor kun leje og benytte
        parkeringspladsen i det tidsrum, som udlejer har gjort den tilgængelig.
        Du må derfor heller ikke benytte pladsen inden din lejeperiode begynder,
        eller efter den er slut.
        <br />
        Du er velkommen til at kontakte ParkShare, hvis du ønsker at leje
        pladsen uden for de angivne tidsrum, og så vil vi forsøge om vi kan
        finde en løsning.
      </p>
    ),
  },
  {
    id: 4,
    label: "Kan jeg risikere, at min bil er blokeret?",
    description: (
      <p>
        Nej. Når du parkerer gennem ParkShare, skal alle biler altid kunne komme
        til og fra parkeringspladsen, og må ikke være blokeret.
      </p>
    ),
  },
  {
    id: 5,
    label: "Hvem sætter prisen for parkeringspladserne?",
    description: (
      <p>
        For at gøre din oplevelse så god som muligt, så er det ParkShare, der
        sætter priserne. Det gør, at du som bilist, ikke skal klikke rundt på
        flere pladser for at få den bedste pris. Samtidig er du sikret en fast
        pris, og ved hvad parkeringen koster.
        <br />
        Priserne er bestemt ud fra type af parkering, by og område.
      </p>
    ),
  },
  {
    id: 6,
    label:
      "Er der nogen bindingsperiode på betaling af parkeringspladsen for månedsparkering med ubegrænset slutperiode?",
    description: (
      <p>
        Hvis du som lejer ønsker at opsige din ubegrænsede månedsparkering, skal
        der betales for indeværende måned, samt næste måned.
      </p>
    ),
  },
  {
    id: 7,
    label: "Jeg ejer ikke bilen, kan jeg stadig booke en parkeringsplads?",
    description: (
      <p>
        Ja, det kan du sagtens! Du kan både booke en parkeringsplads hvis du er
        ejer, lejer eller bruger af en bil, samt hvis du er passager. Du kan
        også booke for andre, selvom det ikke er dig der kører den.
      </p>
    ),
  },
  {
    id: 8,
    label: "Hvilke køretøjer kan jeg parkere med?",
    description: (
      <p>
        Du kan parkere med personbiler. Der må ikke parkeres med busser,
        minibusser, handicap busser eller trailere.
        <br /> <br />
        Størrelsen på parkeringspladsen er hvad der kan forventes for en normal
        parkeringsplads, medmindre andet er anført.
      </p>
    ),
  },
  {
    id: 9,
    label: "Kan jeg komme inden parkeringen starter?",
    description: (
      <p>
        Nej. Parkeringspladsen står først til din rådighed i den periode du har
        lejet den. Du må derfor ikke parkere på parkeringspladsen inden din
        tidsperiode starter. Du kan risikere at blokere for udlejeren eller
        andre, der måske har lejet parkeringspladsen.
      </p>
    ),
  },
];

const rentOutFAQ = [
  {
    id: 1,
    label: "Hvordan udlejer jeg min parkeringsplads?",
    description: (
      <p>
        Du kan udleje din parkeringsplads ved at udfylde formularen{" "}
        <HashLink smooth to="/udlej-parkering#form">
          her
        </HashLink>
        . Efter du har sendt dit ønske om at udleje din(e) parkeringsplads(er),
        så vil vi vende personligt tilbage til dig for at tjekke, om alle
        oplysninger er korrekte. Her vil vi også bede dig om dine
        bankoplysninger, således at du kan modtage betaling for din udlejning af
        din(e) parkeringsplads(er). Det er gratis at blive oprettet hos
        ParkShare, og du bestemmer selv, hvilke dage og tidspunkter, du ønsker
        at udleje din parkeringsplads.
        <br></br>
        På nuværende tidspunkt kan du udleje din parkeringsplads på måneds- og
        event basis, men i fremtiden vil det også være muligt at udleje ens
        parkeringspladser på timebasis.
        <br></br>
        Du er også meget velkommen til at ringe til os eller sende en mail, så
        kan vi også hjælpe dig med oprettelse af din parkeringsplads.
      </p>
    ),
  },
  {
    id: 2,
    label: "Hvem bestemmer prisen på min parkeringsplads?",
    description: (
      <p>
        ParkShare fastsætter priserne. Det betyder, at du som udlejer ikke
        behøver at spekulere over prisen. Du behøver ikke bruge tid på at tjekke
        andre priser i dit område, tjekke hvad dine naboer tager, eller
        spekulere over om det er for dyrt eller billigt i forhold til at finde
        en lejer. Du skal blot sige ja til at udleje din parkeringsplads, og så
        gør vi resten.
      </p>
    ),
  },
  {
    id: 3,
    label: "Kan jeg selv bestemme, hvornår jeg vil udleje min parkeringsplads?",
    description: (
      <p>
        Ja. Det er faktisk kun dig som udlejer, der bestemmer hvornår og hvor
        længe du ønsker at udleje din parkeringsplads. Du kan helt selv
        bestemme, om du vil udleje på månedsbasis eller til events, og du
        bestemmer selv tidsrum og dage.
      </p>
    ),
  },
  {
    id: 4,
    label:
      "Må jeg selv benytte parkeringspladsen, når jeg har sat den tilgængelig til udlejning?",
    description: (
      <p>
        Nej. Når du har sat din parkeringsplads tilgængelig til udlejning, kan
        du ikke selv bruge den i samme tidsrum. Dette skyldes, at din
        parkeringsplads kan bookes af en bilist, som skal være sikret adgang til
        pladsen
      </p>
    ),
  },
  {
    id: 5,
    label: "Kan jeg udleje flere parkeringspladser på samme adresse?",
    description: (
      <p>
        Ja. Så længe at det er muligt at parkere flere køretøjer på adressen,
        eksempelvis flere biler i en indkørsel, og de ikke spærrer for hinanden,
        er det muligt at udleje flere parkeringspladser og dermed tjene ekstra.{" "}
      </p>
    ),
  },
  {
    id: 6,
    label:
      "Jeg har flere parkeringspladser i min indkørsel. Kan jeg nøjes med kun at udleje nogle af dem?",
    description: (
      <p>
        Ja. Du kan selv vælge hvor mange parkeringspladser du ønsker at udleje.
        Det kan være én, flere eller alle, det er helt op til dig.
      </p>
    ),
  },
  {
    id: 7,
    label: "Hvornår får jeg mine penge udbetalt af ParkShare?",
    description: (
      <p>
        ParkShare udbetaler samlet din fortjeneste fra udlejningerne en gang om
        måneden. Dette gøres i starten af hver måned. Bemærk at behandlings- og
        transaktionstiden er 1-5 hverdage.
      </p>
    ),
  },
  {
    id: 8,
    label:
      "Hvor lang opsigelsesperiode har jeg på månedsparkering, hvis slutdato er ubegrænset?",
    description: (
      <p>
        Som udlejer er der på månedsparkering to forskellige opsigelsesperioder.
        Hvis du ønsker at stoppe din udlejning og opsige din udlejning i
        perioden 1.-14. i måneden, er den kun udlejet i indeværende måned, og
        vil være tilgængelig for dig selv i den efterfølgende måned. Opsiger du
        udlejningen 15.-31. i måneden, er den udlejet i den resterende del af
        indeværende måned, samt efterfølgende måned.
        <br /> <br />
        Kontakt ParkShare, hvis du ønsker at opsige din udlejning.
      </p>
    ),
  },
];

const eventDriverFAQ = [
  {
    id: 1,
    label: "Hvor lang tid kan jeg holde parkeret?",
    description: (
      <p>
        Du kan holde parkeret i det tidsrum, der står opgivet på
        parkeringspladsen til eventet.
        <br />
        Ønsker du at holde parkeret i længere tid, kan du kontakte os, og vi kan
        ofte finde en løsning, mod en mindre ekstra betaling for at du kan holde
        der længere. Jo bedre tid i forvejen du kontakter os, desto mere
        sandsynligt er det at vi kan finde en parkeringsplads til din bil.
      </p>
    ),
  },
  {
    id: 2,
    label: "Hvad koster det?",
    description: (
      <p>
        Det koster som udgangspunkt 50 kr. for et normalt event i Aarhus som en
        fodboldkamp på Ceres Park eller en koncert i Tivoli Friheden, hvor du
        har pladsen i 2.5 timer.
        <br />
        Ønsker du at parkere længere end 2.5 timer, kan det som ofte godt lade
        sig gøre, men så skal du kontakte ParkShare. Så vi vil gøre, hvad vi kan
        for at finde en parkeringsplads, hvor det kan lade sig gøre. Skal du
        holde længere på parkeringspladsen, vil det koste ekstra.
      </p>
    ),
  },
  {
    id: 3,
    label:
      "Kan jeg leje parkeringspladsen i et andet tidsrum, end der står angivet på siden?",
    description: (
      <p>
        Som udgangspunkt nej. Du har kun parkeringspladsen til rådighed i den
        periode, der er tilgængelig, og din bil skal dermed være flyttet fra
        adressen ved deadline for indgået aftale. Du er velkommen til at
        kontakte os inden påbegyndelse af parkering, hvis du ønsker at parkere i
        et andet tidsrum end det der er angivet. Jo bedre tid du kontakter os
        inden din parkering, jo større er sandsynligheden for at vi kan finde en
        parkeringsplads, hvor det kan lade sig gøre.
      </p>
    ),
  },
  {
    id: 4,
    label: "Hvad sker der, hvis jeg er forsinket?",
    description: (
      <p>
        Hvis du er forsinket når din parkering starter, gør det ikke noget. Da
        du har betalt for parkeringspladsen, så er den din, så der er ikke
        andre, der kan købe den i samme periode, selvom den står tom.
        <br></br>
        Hvis du er forsinket når du skal afhente din bil, og overskrider den
        tidsgrænse du har betalt for, bedes du kontakte ParkShare på telefon så
        snart du kan se du er forsinket. Det kan være pladsen er udlejet efter
        dig, og du dermed blokere for en anden bilist, eller at udlejer selv
        skal bruge parkeringspladsen. Når du kontakter os over telefon, vil vi
        undersøge om det er muligt at holde der længere. Du vil vil blive
        opkrævet et ekstra gebyr på 10 kr. pr. påbegyndt kvarter. Dette fremgår
        af vores <Link to="/terms">servicevilkår</Link>.
      </p>
    ),
  },
  {
    id: 5,
    label: "Kan jeg lade bilen stå?",
    description: (
      <p>
        Efter afslutningen af eventet og tidsfristen for din parkering er
        udløbet, skal din bil fjernes fra parkeringspladsen. Du kan dog kontakte
        os, hvis du ønsker en plads, hvor du kan lade bilen stå, evt. til næste
        dag mod ekstra betaling. Det er vigtigt, at du kontakter os i god tid,
        sådan at vi kan finde en parkeringsplads, hvor det kan lade sig gøre, da
        du ikke blot må lade bilen stå uden aftale med os.
      </p>
    ),
  },
  {
    id: 6,
    label: "Hvor langt væk ligger parkeringspladsen fra eventet?",
    description: (
      <p>
        Når du booker din parkeringsplads gennem vores hjemmeside, kan du se
        adressen. Som udgangspunkt ligger vores eventparkeringspladser alle
        inden for en radius af 1 km fra eventstedet, og dermed inden for 5
        minutters gang. Det er ofte bedre med 5 minutters gang end lange køer
        for at komme ud fra en større parkeringsplads.
      </p>
    ),
  },
  {
    id: 7,
    label: "Kan min bil blive blokeret, sådan at jeg ikke kan få den ud?",
    description: (
      <p>
        Nej. Hos ParkShare sikrer vi, at alle de udlejninger vi laver, ikke
        blokere for andre biler. Du kan altid komme til og fra
        parkeringspladsen, når du ønsker det.
      </p>
    ),
  },
  {
    id: 8,
    label: "Er parkeringspladserne handicapvenlige?",
    description: (
      <p>
        Ja. Vi har flere parkeringspladser til events, der er handicapvenlige.
        Med handicapvenlige pladser menes der, at der er asfalt hele vejen hen
        til eventstedet, så det er let at gå med krykker og køre med kørestol.
        Nogle af vores pladser har ekstra bredde, så kontakt os, såfremt du
        ønsker dette.
        <br />
        Vi gør opmærksom på, at der ikke må holde handicapbusser på
        parkeringspladserne, grundet deres vægt.
      </p>
    ),
  },
  {
    id: 9,
    label: "Hvordan ved jeg, at pladsen er booket til mig?",
    description: (
      <p>
        Når vi modtager din forespørgsel, så kontakter vi dig angående betaling.
        Når betalingen er gennemført, og du har modtaget en bekræftelse fra os,
        ved du at parkeringspladsen er booket til dig.
      </p>
    ),
  },
  {
    id: 10,
    label:
      "Vi skal til event og kommer i flere biler. Kan vi parkere flere biler på samme adresse?",
    description: (
      <p>
        Ja sagtens! Flere af vores udlejere har indkørsler med flere
        parkeringspladser, så det er ofte muligt at holde flere biler, såfremt
        at flere pladser er ledige. Du er velkommen til at kontakte os, hvis du
        vil være sikker på at I kan holde sammen. Jo tidligere du booker, jo
        større sandsynlighed er der for at I kan holde på samme adresse.
      </p>
    ),
  },
  {
    id: 11,
    label: "Kan jeg booke for andre?",
    description: (
      <p>
        Ja. Så længe du har en aftale med bilisten og informationen om bilen,
        kan du booke for en anden. Det er dig, som booker pladsen, som også står
        for betaling. Vi holder ikke en plads booket, hvis der ikke er betalt
        for den.
      </p>
    ),
  },
];

const eventOwnerFAQ = [
  {
    id: 1,
    label: "Kan jeg udleje min parkeringsplads kun til events?",
    description: (
      <p>
        Ja. Du kan selv bestemme hvornår du ønsker at udleje din
        parkeringsplads, og kan sagtens vælge kun at udleje den til events.
      </p>
    ),
  },
  {
    id: 2,
    label: "Hvordan kan jeg vide hvornår der er events?",
    description: (
      <p>
        Du kan vælge selv at holde dig opdateret med events i dit lokalområdet
        og sætte din parkeringsplads til rådighed der, eller du kan vælge at
        modtage alle oplysninger fra ParkShare. Flere af vores event udlejere
        har valgt at modtage en mail, en gang om måneden med alle events for den
        kommende måned. Herefter ønsker de fleste at sende en mail tilbage, hvor
        de tilkendegiver hvilke events og hvor mange parkeringspladser de ønsker
        at have stille rådighed, og så gør ParkShare arbejdet for dem, og får
        det ind i systemet. Vi kan også kontakte dig et par dage inden eventet
        og høre om du ønsker at udleje. Vi kan sammensætte præcist den løsning
        der passer bedst til dig!
        <br />
        Hvis du ønsker at få en påmindelse om eventet, og at dine
        parkeringspladser står til rådighed, direkte i din kalender, kan vi også
        sagtens sende det. Dette er kun noget der gøres, hvis du selv har bedt
        om det.
      </p>
    ),
  },
  {
    id: 3,
    label: "Hvor mange events er der årligt i Marselis området i Aarhus?",
    description: (
      <p>
        I Marselis området i Aarhus ligger der flere steder, hvor der afholdes
        events, eksempelvis Ceres Park, Tivoli Friheden, Jydsk Væddeløbsbane,
        Tangkrogen og Mindeparken. Der er ca. 50-80 eventdage årligt, og det kan
        variere meget, hvor mange der er hver måned.
        <br />
        Som udlejer bestemmer du helt selv hvilke events og hvor mange
        parkeringspladser du ønsker at udleje til.
      </p>
    ),
  },
  {
    id: 4,
    label: "Hvad kan jeg tjene på at udleje min parkeringsplads til events?",
    description: (
      <p>
        De fleste events koster 50 kr. for bilisten for 2,5-3 timers parkering.
        Som udlejer får du 82% af beløbet og tjener dermed 41 kr. pr.
        parkeringsplads du udlejer, hvis den bliver lejet. I Aarhus er der
        omkring 70 events årligt i Marselis området, og du vil dermed kunne
        tjene op mod 2.900 kr. årligt pr. parkeringsplads. Nogle events koster
        mere, og nogle gange ønsker bilisterne at holde længere, hvor du så kan
        tjene mere.
      </p>
    ),
  },
  {
    id: 5,
    label: "Kan jeg annullere min udlejning?",
    description: (
      <p>
        Hvis din parkeringsplads ikke er booket af en bilist, kan du godt
        annullere din udlejningsperiode - også med kort varsel. Kontakt
        ParkShare, hvis du ønsker at annullere din udlejningsperiode.
        <br />
        Hvis din parkeringsplads til et event allerede er booket af en bilist,
        kan du ikke blot annullere din udlejning. Annullering skal finde sted
        senest 48 timer før parkeringspladsen er booket.
      </p>
    ),
  },
  {
    id: 6,
    label: "Hvis bilisten aflyser, får jeg som udlejer så stadig penge?",
    description: (
      <p>
        Hvis bilisten annullerer sin booking over 6 timer fra booking begynder
        får du som udlejer ikke noget. Annullerer bilisten 6-3 timer inden
        bookingen begynder får udlejer 25% af beløbet. Annullerer bilisten under
        3 timer fra bookingen begynder og inden bookingen starter, får udlejer
        50% af beløbet. Annullerer bilisten efter bookingen er startet får
        udlejer 100%.
        <br />
        Hvis din parkeringsplads bliver annulleret og en anden booker den, får
        du som udlejer blot et større beløb.
      </p>
    ),
  },
  {
    id: 7,
    label: "Hvornår får jeg mine penge udbetalt af ParkShare?",
    description: (
      <p>
        ParkShare udbetaler din samlede indtægt fra udlejningerne i starten af
        hver måned. Bemærk, at behandlings- og transaktionstiden er 1-5
        hverdage.
      </p>
    ),
  },
];

const companiesFAQ = [
  {
    id: 1,
    label: "Vi bruger ofte vores parkeringsplads, så hvornår kan den udlejes?",
    description: (
      <p>
        De fleste virksomheder bruger deres parkeringsplads i løbet af dagen,
        ofte i tidsrummet 8-16. Det er derfor oplagt at udleje sine
        parkeringspladser aften, nat og weekender.
        <br />
        Ligger din virksomhed i byen, særligt i centrum, eller i områder med tæt
        beboelse, så er det i tidsrummet udenfor normal arbejdstid, der er
        efterspørgsel på dine parkeringspladser
        <br />
        Hvis I har nogle ekstra parkeringspladser i løbet af dagen, kan disse
        selvfølgelig også udlejes, og give en ekstra indtægt.
      </p>
    ),
  },
  {
    id: 2,
    label:
      "Kan vi udleje kun nogle af vores parkeringspladser, og ikke dem alle?",
    description: (
      <p>
        Ja, du kan selv bestemme hvor mange parkeringspladser du vil udleje. Har
        du eksempelvis 20 parkeringspladser, kan du udleje 1, 5, 12 eller alle
        20, det er helt op til dig.
      </p>
    ),
  },
  {
    id: 3,
    label:
      "Kan vi udleje forskellige typer og tidsrum, eller skal alle parkeringspladser være udlejet ens?",
    description: (
      <p>
        I kan selv bestemme hvornår hver enkelt parkeringsplads skal være
        udlejet, og de behøver ikke være ens. I kan selv sætte typen og
        tidsrummet individuelt for hver parkeringsplads.
      </p>
    ),
  },
  {
    id: 4,
    label: "Hvor ofte bliver pengene fra udlejninger udbetalt?",
    description: (
      <p>
        På nuværende tidspunkt bliver pengene udbetalt en gang om måneden.
        Pengene udbetales i starten af hver måned, hvor I får udbetalt
        indtægterne for forgående måneds udlejninger.
      </p>
    ),
  },
  {
    id: 5,
    label:
      "Er det en fast bilist der bruger vores parkeringsplads eller skiftende?",
    description: (
      <p>
        Det kommer an på hvilken type parkering I vælger at udleje. Vælger I
        eventparkering eller timeparkering, er det ofte forskellige bilister,
        der bruger jeres parkeringsplads. Vælger I at udleje månedsparkering, så
        er det den samme bilist.
      </p>
    ),
  },
];

const faqGeneralQuestions = [
  {
    id: 1,
    label: "Hvad er ParkShare?",
    description: (
      <p>
        ParkShare er en deleøkonomisk platform for parkeringspladser. Vi ønsker
        at gøre parkering så nemt og bekvemt som muligt, og aktivere de tomme
        parkeringspladser. ParkShare er dermed et bindeled mellem udlejere (dem
        der har parkeringspladser) og lejere (ofte bilister). Ved at bruge
        ParkShare kan du være med til at afhjælpe manglen på parkeringspladser,
        bidrage til deleøkonomien og samtidig tjene en ekstra månedlig indtægt.
        Som bilist ønsker vi at det skal være nemt at parkere, og gøre det
        muligt at booke parkering allerede hjemmefra. Det er ren win-win!
      </p>
    ),
  },
  {
    id: 2,
    label: "Hvilke slags parkeringer tilbyder ParkShare?",
    description: (
      <p>
        På nuværende tidspunkt tilbyder ParkShare parkeringer på månedlig basis
        og til events. På sigt vil vi også tilbyde parkeringer på timebasis,
        hvilket betyder, at du i fremtiden vil kunne parkere med ParkShare i
        enhver given situation. Hvis du ønsker en anden type parkering, eller
        noget vi ikke har, så vil vi meget gerne høre dit ønske, så vi eventuelt
        kan lancere det i fremtiden. Vi ønsker at tilbyde lige præcis det som
        vores brugere får mest værdi ud af!
      </p>
    ),
  },
  {
    id: 3,
    label: "Hvordan fungerer ParkShare?",
    description: (
      <p>
        ParkShare fungerer som bindeled mellem udlejer og lejer. Hos ParkShare
        kan private og virksomheder udleje deres parkeringspladser, f.eks. i
        arbejdstimerne når du ikke er hjemme, i weekenden, eller fast, når
        parkeringspladserne står tomme og ubenyttede. Bilister kan nemt leje og
        booke en parkeringsplads, både på fast basis, eller per gang.
        <br /> <br />
        Når der lejes en parkeringsplads igennem ParkShare går 82% af det
        samlede beløb til udlejer. De resterende 18% går til ParkShare for at
        dække udviklings- og transaktionsomkostninger.
      </p>
    ),
  },
  {
    id: 4,
    label: "Må man udleje og parkere på private parkeringspladser?",
    description: (
      <p>
        Ja og ja! Da en privat parkeringsplads ligger på din private grund, evt.
        i din indkørsel, eller du har 100% rådighed over en parkeringsplads, så
        må du gerne udleje den, da du har råderetten over parkeringspladsen.
        <br /> <br />
        Når en udlejer har valgt at udleje sin parkeringsplads gennem ParkShare,
        har han/hun givet tilladelse til, at du gerne må parkere på deres
        parkeringsplads. Derfor er det helt tilladt!
      </p>
    ),
  },
  {
    id: 5,
    label: "Hvad er en privat parkeringsplads?",
    description: (
      <p>
        En privat parkeringsplads er en parkeringsplads som du har råderetten
        over. Det kan være i en forhave til et hus, en fast parkeringsplads til
        en lejlighed enten udenfor eller i en P-kælder, eller det kan være ved
        en virksomhed. Det vigtige er at du har fuld rådighed over
        parkeringspladsen, og dermed kan garantere at der ikke holder andre på
        parkeringspladsen.
      </p>
    ),
  },
  {
    id: 6,
    label: "Gælder ParkShare også offentlige parkeringspladser?",
    description: (
      <p>
        Nej. Offentlige parkeringspladser er kontrolleret af kommunerne, og kan
        på nuværende tidspunkt ikke lejes eller udlejes igennem ParkShare."
      </p>
    ),
  },
  {
    id: 7,
    label: "Hvad er fordelene ved at bruge ParkShare?",
    description: (
      <p>
        Der er mange fordele ved at bruge ParkShare. Der er først og fremmest de
        deleøkonomiske fordele ved allerede at bruge eksisterende ubenyttede
        ressourcer, i form af tomme parkeringspladser, til at tjene en ekstra
        månedlig indtægt. Det giver dig en større indtægt og dermed et større
        rådighedsbeløb.
        <br /> <br />
        Ved at reservere en parkeringsplads på forhånd, hjælper du også med at
        mindske trafik i søgen efter en parkeringsplads, hvilket samtidig sparer
        dig for frustrationer og tidsspild. Ved at reservere en parkeringsplads
        ved du nøjagtigt hvor du skal køre hen, og behøver derfor ikke cirkulere
        rundt og lede efter parkering, hvilket reducerer CO2 udledningen og
        dermed skåner miljøet.
        <br /> <br />
        Dette er blot nogle af fordelene ved at bruge ParkShare
      </p>
    ),
  },
  {
    id: 8,
    label: "Koster det noget at oprette sig hos ParkShare?",
    description: (
      <p>
        Det koster ikke noget at blive registreret hos ParkShare. Som udlejer
        kan du kvit og frit oprette dig som bruger og begynde at tjene penge på
        din tomme parkeringsplads. Som lejer betaler du kun for de
        parkeringspladser du lejer. Der er dermed ingen ekstra gebyr.
      </p>
    ),
  },
  {
    id: 9,
    label: "Har ParkShare en App til mobilen?",
    description: (
      <p>
        På nuværende tidspunkt opererer ParkShare udelukkende med en Web App.
        Men fortvivl ej! Mobil Appen er under udvikling, så ParkShare vil på
        sigt tilbyde vores tjeneste på tværs af flere platforme og med en mobil
        app der kan downloades. Et godt produkt tager tid, men du vil inden
        længe kunne have ParkShare med i lommen på via en app til mobilen.
      </p>
    ),
  },
  {
    id: 10,
    label: "Indberetter ParkShare fortjenesten til SKAT?",
    description: (
      <p>
        Nej. Det er udlejers eget ansvar at indberette den ekstra indkomst til
        SKAT.
      </p>
    ),
  },
];

export {
  findParkeringFAQ,
  rentOutFAQ,
  eventDriverFAQ,
  eventOwnerFAQ,
  companiesFAQ,
  faqGeneralQuestions,
};
