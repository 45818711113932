import ReactGA from "react-ga";
const trackingID = "UA-189483547-1";
export const initGA = () => {
  ReactGA.initialize(trackingID);
};
export const pageView = () => {
  ReactGA.pageview(window.location.pathname + window.location.search);
};
export const trackEvent = (category, action, value = 0, label = "") => {
  if (category !== undefined && action !== undefined) {
    ReactGA.event({
      category: category,
      action: action,
      value: value,
      label: label,
    });
  }
};
