//#region - Imports
// Libraries
import Button from "react-bootstrap/Button";
import {NavLink} from "react-router-dom";

// Styling
import "../Styles/Pages/Error.scss";

// Components
import Meta from "../Components/Meta";
import {COOKIE_NAMES, getCookieValue} from "../Components/Tracking/Cookie";
import {initGA, pageView} from "../Components/Tracking/Tracking";

// Asset
import sign from "../Assets/Compressed/roadsign.jpg";// compressed image
//#endregion

const Error = () => {
  // Track pageview
  if (getCookieValue(COOKIE_NAMES.PERFORMANCE)) {
    initGA();
    pageView();
  }
  return (
    <div className="error">
      <Meta
        title="404 Ikke Fundet - ParkShare"
        description="Siden du leder efter, kan desværre ikke findes. Vi hjælper dig gerne tilbage på rette vej, så tag endelig kontakt til os"
      />
      <img src={sign} alt="By Andersen Jensen on Unsplash" />
      <div className="overlay">
        <section>
          <div className="content">
            <h1>404</h1>
            <h2>Det ser ud til, at du er kommet til en blind vej</h2>
            <h3>Den side, du leder efter, kan desværre ikke findes</h3>
            <NavLink to="/">
              <Button className="go-home-btn hvr-buzz-out hvr-glow" size="lg">
                Gå til Forsiden
              </Button>
            </NavLink>
            <p>
              Det kan skyldes at siden ikke findes, at siden er blevet flyttet,
              eller linket er skrevet forkert.
            </p>
            <p>
              Du kan prøve at gå tilbage til <NavLink to="/">forsiden</NavLink>,
              eller <NavLink to="/kontakt">kontakt os</NavLink>
            </p>
          </div>
        </section>
      </div>
    </div>
  );
};
export default Error;
