//#region - Imports
// Libraries
import Button from "react-bootstrap/Button";
import {useRef} from "react";
import {HashLink} from "react-router-hash-link";

// Styling
import "../Styles/Pages/Event.scss";

// Components
import EventList from "../Components/ParkingList/EventList";
import EventForm from "../Components/EventForm";
import EventWaitinglist from "../Components/Waitinglist/EventWaitinglist";
import Meta from "../Components/Meta";
import FAQ from "../Components/FAQ";
import {eventDriverFAQ, eventOwnerFAQ} from "../Components/FAQWidgetDatas";
import {COOKIE_NAMES, getCookieValue} from "../Components/Tracking/Cookie";
import {initGA, pageView} from "../Components/Tracking/Tracking";

// Assets
import DK_Map from "../Assets/Compressed/grey-denmark-map-parkshare-logo.png";// compressed image
import Keys from "../Assets/Findparkering/grey-blue-pink-keys.png";
//#endregion

const Event = () => {
  if (getCookieValue(COOKIE_NAMES.PERFORMANCE)) {
    initGA();
    pageView();
  }

  const scrollRef = useRef(null);
  const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop - 50);
  const executeScroll = () => scrollToRef(scrollRef);

  return (
    <div className="event">
      <Meta
        title="Lej Parkering til Events i Aarhus - ParkShare"
        description="Til events er det ofte svært at finde parkering. Med ParkShare kan du booke parkering inden du tager hjemmefra, sådan at du ved præcist, hvor du skal parkere."
      />
      <section className="top">
        <div className="bg">
          <div className="content">
            <div className="element1">
              <h1 className="pageHeading">Event Parkering</h1>
              <p className="subHeading">
                Book hjemmefra så du kan nyde dit event
              </p>
              <Button className="topButton" onClick={executeScroll}>
                Book eventparkering
              </Button>
            </div>
            <div className="element2">
              <iframe
                src="https://www.youtube.com/embed/iCNigo2iLjI"
                title="frontpageVideo"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              ></iframe>
            </div>
          </div>
        </div>
      </section>
      <section className="mobileVideo">
        <iframe
          height="196px"
          width="350px"
          src="https://www.youtube.com/embed/iCNigo2iLjI"
          title="frontpageVideo"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        ></iframe>
      </section>
      <section className="desc spaceBelow">
        <h2 className="sectionHeading">Konceptet bag eventparkering</h2>
        <div className="content">
          <div className="element1">
            <img src={DK_Map} alt="Grå kort af Danmark med ParkShare logo" />
          </div>
          <div className="element2">
            <p className="paragraph bold">Events</p>
            <p className="iconParagraph">
              Uanset om man skal til koncert, fodbold, i Tivoli eller andre
              events, så er der én ting, der altid går igen – det er umuligt at
              finde en parkeringsplads. Man spilder tiden, bliver irriteret og
              ender måske med at sætte bilen et sted, hvor man så bagefter har
              fået en parkeringsbøde.
            </p>
            <p className="iconParagraph">
              Igennem ParkShare kan du på forhånd booke en parkeringsplads til
              diverse events, så du ved præcis, hvor du skal parkere. Du har
              allerede adressen inden du kører hjemmefra, og kan køre direkte
              hen og parkere bilen.
            </p>
            <p className="iconParagraph">
              Når eventet er slut og du skal hjem, behøver du heller ikke sidde
              i kø eller blive fanget af trafikken, der opstår på
              parkeringspladsen. Her kan du let køre ud af en privat indkørsel.
            </p>
            <p className="iconParagraph">
              Med ParkShare behøver du ikke cirkulere rundt og være stresset
              over at komme for sent eller få bøder. Du kan i stedet nyde dit
              event og tiden sammen med dine venner og familie.
            </p>
            <br />
            <p className="paragraph bold">
              <span style={{display: "none"}}>
                Hvilke byer er ParkShare i til events?
              </span>
              I hvilke byer findes ParkShare til events?
            </p>
            <p className="iconParagraph">
              Lige nu er eventparkering kun tilgængelig i Aarhus, nærmere
              bestemt Marselis området. Vi har derfor primært pladser til events
              på Ceres Park & Arena, Tivoli Friheden, Mindeparken, Tangkrogen og
              Jydsk Væddeløbsbane.
            </p>
            <br />
            <p className="paragraph bold">Hvad koster det?</p>
            <p className="iconParagraph">
              Til de fleste events koster parkering 20 kr./t., hvilket svarer
              til 50 kr. for en fodboldkamp eller de fleste koncerter i Tivoli
              Friheden.
            </p>
            <p className="iconParagraph">
              Mangler du en ekstra time eller to? Så kan du altid kontakte os,
              og vi vil finde en parkeringsplads, hvor du kan parkere længere.
              Uden for event timerne med højest spidsbelastning, er timeprisen
              lavere.
            </p>
          </div>
        </div>
      </section>
      {/* Eventlist section */}
      <section className="eventParkingList" ref={scrollRef} id="eventlist">
        <div className="content">
          <EventList />
        </div>
      </section>
      {/* WAITINGLIST COMPONENT */}
      <section className="waitinglist" id="waitinglist">
        <div className="content">
          <h2 className="sectionHeading">
            Ønsker du at komme på venteliste til en event parkeringsplads?
          </h2>
          <div className="waitinglistContainer">
            <div className="waitinglist1200pxUnder">
              <p className="paragraph">
                Hvis vi har udsolgt til det event, hvor du ønsker parkering, kan
                vi ofte skaffe ekstra parkeringspladser. Derfor har vi oprettet
                en venteliste, hvor vi kontakter dig, når vi har skaffet en
                ekstra parkeringsplads til det event du ønsker parkering.
                <br /> <br />
                Er eventet ikke på listen, er du stadig meget velkommen til at
                skrive dig op, for der er stadig stor sandsynlighed for, at vi
                kan skaffe en parkeringsplads til dig. Alle events vi tilbyder
                eventparkering til, ligger på nuværende tidspunkt kun i
                Marselisområdet, Aarhus, hvorfor det kun er der vi kan tilbyde
                eventparkering.
              </p>
              <EventWaitinglist />
            </div>
            <div className="waitinglist1200pxOver">
              <div className="element1">
                <p className="paragraph">
                  Hvis vi har udsolgt til det event, hvor du ønsker parkering,
                  kan vi ofte skaffe ekstra parkeringspladser. Derfor har vi
                  oprettet en venteliste, hvor vi kontakter dig, når vi har
                  skaffet en ekstra parkeringsplads til det event du ønsker
                  parkering.
                  <br /> <br />
                  Er eventet ikke på listen, er du stadig meget velkommen til at
                  skrive dig op, for der er stadig stor sandsynlighed for, at vi
                  kan skaffe en parkeringsplads til dig. Alle events vi tilbyder
                  eventparkering til, ligger på nuværende tidspunkt kun i
                  Marselisområdet, Aarhus, hvorfor det kun er der vi kan tilbyde
                  eventparkering.
                </p>
                <div className="keys">
                  <img
                    src={Keys}
                    alt="Tre grå blå og lyserød nøgler med tal 1,2,3 (kan ikke se)"
                  ></img>
                </div>
              </div>
              <div className="element2">
                <EventWaitinglist />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* New section - button to events */}
      <section className="eventLink spaceBelow spaceAbove">
        <div className="content">
          <h2 className="sectionHeading white">
            Vil du udleje din parkeringsplads til events i Aarhus?
          </h2>
          <div className="regular-button">
            <HashLink smooth to="/udlej-parkering#form">
              <Button id="btn-submit" type="submit">
                Klik her
              </Button>
            </HashLink>
          </div>
        </div>
      </section>
      <section className="eventForm">
        <div className="content">
          <h2 className="sectionHeading">
            Ønsker du parkering til andre events?
          </h2>
          <div className="eventformContainer">
            <EventForm />
          </div>
        </div>
      </section>
      <section className="faq spaceBelow">
        <h2 className="sectionHeading">
          Ofte stillede spørgsmål til Eventparkering
        </h2>
        <div className="content">
          <div className="bilist spaceBelow">
            <h3 className="faqHeading">For Bilist</h3>
            <div className="widget-container">
              <FAQ tabs={eventDriverFAQ}></FAQ>
            </div>
          </div>
          <div className="udlejer">
            <h3 className="faqHeading">For Udlejer</h3>
            <div className="widget-container">
              <FAQ tabs={eventOwnerFAQ}></FAQ>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Event;
