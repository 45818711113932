//#region - Imports
import "../Styles/Pages/PrivacyPolicy.scss";

import Meta from "../Components/Meta";
import {COOKIE_NAMES, getCookieValue} from "../Components/Tracking/Cookie";
import {initGA, pageView} from "../Components/Tracking/Tracking";

import logo from "../Assets/parkshare-short-logo.png";

//#endregion

const PrivacyPolicy = () => {
  // Track pageview
  if (getCookieValue(COOKIE_NAMES.PERFORMANCE)) {
    initGA();
    pageView();
  }
  return (
    <div className="privacy-policy spaceBelow">
      <Meta
        title="Privatlivs & Cookie Politik | ParkShare"
        description="Privatliv er vigtigt for mange, og dit er særligt vigtigt for os. Derfor kan du her se hvilke informationer vi bruger, samt hvordan vi behandler dem."
      />
      <section className="table-of-contents text-section">
        <h1 className="privacy-header">Privatlivs og cookie politik</h1>
        {/* TODO: Table of contents */}
      </section>
      <section className="introduction text-section">
        <h2 className="section-header">Introduktion</h2>
        <p>
          Når du besøger vores website indsamles der oplysninger om dig, som
          bruges til at tilpasse og forbedre vores indhold, samt skabe en endnu
          bedre platform for vores brugere. Ønsker du ikke, at der indsamles
          oplysninger om dig, bør du slette dine cookies og undlade videre brug
          af hjemmesiden. I de nedenstående afsnit har vi uddybet hvilke
          informationer der indsamles, deres formål, samt hvilke tredjeparter
          data deles med.
        </p>
      </section>
      <section className="collected-information text-section">
        <h2 className="section-header">Indsamlet information</h2>
        <p>
          Vi indsamler og behandler data om dig i forbindelse med følgende typer
          kontakt med os:
        </p>

        <div className="li">
          <img src={logo} alt="" />
          Brug af vores hjemmeside, herunder via cookies jf. punkt 5
        </div>
        <div className="li">
          <img src={logo} alt="" />
          Køb og udlejning gennem vores service
        </div>
        <p>
          De personlige dataoplysninger vi indsamler, vil typisk inkludere en
          eller flere af følgende:
        </p>
        <div className="li">
          <img src={logo} alt="" />
          Kontaktinformationer såsom navn, adresse og mail
        </div>
        <div className="li">
          <img src={logo} alt="" />
          Ordredata og købshistorik
        </div>
        <div className="li">
          <img src={logo} alt="" />
          Bruger adfærd på vores hjemmeside, der indsamles via cookies i
          forbindelse med brugen af vores hjemmeside
        </div>
      </section>
      <section className="reason-for-collecting-content text-section">
        <h2 className="section-header">Grunde for at opsamle information</h2>
        <p>
          Indsamling og behandling af dine personlige data sker for at kunne
          levere vores service til dig og af andre lovlige forretningsmæssige
          formål. Sådanne formål kan inkludere:
        </p>
        <div className="li">
          <img src={logo} alt="" />
          Behandling af køb igennem vores service
        </div>
        <div className="li">
          <img src={logo} alt="" />
          Opfyldelse af aftaler indgået med dig
        </div>
        <div className="li">
          <img src={logo} alt="" />
          <span style={{display: "none"}}>
            Administration af din relation til os og vores trafik som en helhed
          </span>
          Administration af din relation til os og vores overordnede trafik
        </div>
        <div className="li">
          <img src={logo} alt="" />
          Hjemmesidens drift og optimering deraf
        </div>
        <div className="li">
          <img src={logo} alt="" />
          Opfyldelse af krav ifølge lovgivningen
        </div>

        <h3 className="section-sub-header">Relevans og nødvendighed</h3>
        <p>
          Din personlige data vil udelukkende blive behandlet for den
          informerede grund, muligvis med reference til denne politik og de
          grunde der er beskrevet heri. Kun det data nødvendigt for at fuldføre
          disse grunde vil blive indsamlet og vores behandling vil derfor være
          begrænset til dette personlige data. Under designet af vores services
          undergår vi en evaluering af nødvendigheden af det data vi indsamler
          og prøver, at indsamle så lidt som muligt. Samtidig evaluerer vi
          mulighederne for at anonymisere det data vi indsamler.
        </p>
      </section>
      <section className="consent text-section">
        <h2 className="section-header">Samtykke</h2>
        <p>
          Før vi behandler din data, vil vi anmode om dit samtykke. Vi vil
          informere dig om grunden til indsamlingen og brugen af din personlige
          data, muligvis med reference til dette dokument.
        </p>
        <h3 className="section-sub-header">Tilfælde af antaget samtykke</h3>
        <p>
          I nogle tilfælde vil vi antage dit samtykke eller fortsætte uden det.
          Dette er baseret på den legale basis beskrevet nedenfor.
        </p>
        <h4 className="section-sub-sub-header">Brug af vores hjemmeside</h4>
        <p>
          Da vores hjemmeside anvender nødvendige cookies, vil den blive
          placeret uden dit samtykke i overensstemmelse med GDPR Art. 6 (f)
        </p>
        <h4 className="section-sub-sub-header">
          Køb gennem vores service uden først at lave en bruger
        </h4>
        <p>
          Ved køb gennem vores service uden at lave en bruger vil vi indsamle og
          behandle din information i overensstemmelse med GDPR Art. 6 (b)(c)
        </p>
      </section>
      <section className="cookies text-section">
        <h2 className="section-header">Cookies</h2>
        <h3 className="section-sub-header">Introduktion til cookies</h3>
        <p>
          Hjemmesiden anvender ‘cookies’, hvilket er en tekstfil, der gemmes på
          din enhed med det formål at kunne genkende enheden for at kunne levere
          personliggjort indhold. Dette tillader hjemmesiden at gøre ting såsom
          at huske brugerens præferencer og automatisk logge brugeren ind, samt
          gør det muligt for hjemmesiden at lave statistik på den indkommende
          trafik. En cookie kan ikke indeholde skadelig kode såsom virus. For
          information om hvordan man sletter en cookie, refererer vi til den
          relevante sektion i denne politik. Vi deler vores cookies op i disse
          kategorier:
        </p>
        <div className="li">
          <img src={logo} alt="" />
          Nødvendige cookies
        </div>
        <div className="li">
          <img src={logo} alt="" />
          Funktionalitets-cookies
        </div>
        <div className="li">
          <img src={logo} alt="" />
          Ydelses cookies
        </div>

        <h3 className="section-sub-header">Data i vores cookies</h3>
        <p>
          Vores cookies indeholder følgende data om dig, der under
          omstændighederne kan blive betragtet som personlig information:
        </p>

        <div className="li">
          <img src={logo} alt="" />
          Et unikt ID
        </div>
        <div className="li">
          <img src={logo} alt="" />
          Teknisk information om din enhed
        </div>
        <div className="li">
          <img src={logo} alt="" />
          IP-adresse
        </div>
        <div className="li">
          <img src={logo} alt="" />
          Geografisk lokation
        </div>
        <div className="li">
          <img src={logo} alt="" />
          Brugeraktivitet på vores hjemmeside
        </div>
        <div className="li">
          <img src={logo} alt="" />
          Købshistorik
        </div>
        <h3 className="section-sub-header">Grunde til at bruge cookies</h3>
        <p>
          Vi behandler data om dig baseret på forskellige cookies som nævnt i
          sektion 5.1 af de følgende grunde:
        </p>
        <h4 className="section-sub-sub-header">Nødvendige cookies</h4>
        <p>
          Disse cookies bruges til at opretholde den basale funktionalitet af
          hjemmesiden, så den fungerer korrekt og kan blive vist på din enheded.
        </p>
        <h4 className="section-sub-sub-header">Funktionalitets-cookies</h4>
        <p>
          Disse cookies bruges til at personliggøre vores services, så
          hjemmesiden kan give indhold skræddersyet til dig, såsom at huske dine
          indstillinger samt automatisk at logge dig ind.
        </p>
        <h4 className="section-sub-sub-header">Ydelses cookies</h4>
        <p>
          Også kaldt statistik cookies, disse cookies bruges for at følge
          hvilket indhold der bliver set så vi kan forbedre vores hjemmesides
          funktioner og services.
        </p>
        <h3 className="section-sub-header">Juridiske grundlag for cookies</h3>
        <p>
          Indsamlingen og behandlingen af dine personlige data fra nødvendige
          cookies er gjort på det juridiske grundlag af GDPR Art. 6 (f) siden
          indsamlingen er nødvendig for funktionaliteten af vores hjemmeside.
          Derfor kan du ikke fravælge denne cookie, men du kan læse mere om
          hvordan du fjerner dine cookies i sektionen forneden. Indsamlingen og
          behandlingen af personlige data fra funktionalitets- og performance
          cookies er gjort på det juridiske grundlag af GDPR Art 6 (a) (f) siden
          indsamlingen er sket på baggrund af dit samtykke til disse cookies
          samt denne politik og dermed også til behandlingen af dine personlige
          data. Yderligere indsamler vi data baseret på vores legale
          forretningsinteresse i at forbedre vores services til din fordel.
        </p>
        <h3 className="section-sub-header">Sletning af data fra cookies</h3>
        <p>
          Det er muligt for dig at fjerne eller blokere cookies. Produceren for
          dette varierer mellem enheder og browsere, men udgiveren af disse vil
          have udgivet en passende guide du kan følge. Hvis du fjerner dine
          cookies, kan det dog medfølge, at hjemmesiden ikke længere fungerer
          korrekt og du kan miste adgang til indhold du tidligere havde adgang
          til.
        </p>
      </section>
      <section className="security text-section">
        <h2 className="section-header">Sikkerhed</h2>
        <p>
          Vi tager sikkerheden af din data meget seriøst og behandler det på en
          måde, der forhindrer tab eller ikke-autoriseret ændring eller læsning
          af dataet. For yderligere at sikre mod tab af data tager vi også
          regelmæssige backups af vores data set. I tilfælde af en
          sikkerheds-brist, der udgør en trussel for din personlige data, vil vi
          kontakte dig snarest muligt, samt underrette de relevante myndigheder
          ifølge loven.
        </p>
      </section>
      <section className="deletion-of-data text-section">
        <h2 className="section-header">Sletning af data</h2>
        <p>
          Når grunden til indsamlingen og behandlingen af din data er fuldført
          vil vi slette det, medmindre der er anden lovlig grund til at opbevare
          det.
        </p>
        <h3 className="section-sub-header">Retten til at blive glemt</h3>
        <p>
          Dit samtykke er frivilligt og du kan til enhver tid trække den tilbage
          ved at kontakte os på info@parkshare.dk Vær opmærksom på, at hvis du
          vælger at trække dit samtykke tilbage, vil vi ikke længere kunne
          behandle det data medmindre vi er forpligtigede til det, enten på
          grund af en kontrakt eller lovkrav. Hvis du vælger at tilbagetrække
          dit samtykke, vil det ikke påvirke lovligheden af behandlingen af din
          data baseret på det tidligere udtrykte samtykke og indtil øjeblikket
          du trækker det tilbage. Det vil sige at hvis du fratrækker dit
          samtykke vil du kun virke fra det øjeblik.
        </p>
      </section>
      <section className="transfer-of-data text-section">
        <h2 className="section-header">Overførsel af data</h2>
        <p>
          Vi overlader eller videregiver dine oplysninger til den følgende
          kategori af modtagere
        </p>
        <h3 className="section-sub-header">Overførsler inden for EU / EØS</h3>

        <div className="li">
          <img src={logo} alt="" />
          Data udbyder som del af operation og support af vores hjemmeside og
          systemer
        </div>
        <div className="li">
          <img src={logo} alt="" />
          Partnere i form af betalings services til at behandle køb på dine
          vegne
        </div>
        <div className="li">
          <img src={logo} alt="" />
          Offentlige myndigheder hvor det er krævet pr. lov
        </div>
        <div className="li">
          <img src={logo} alt="" />
          Revisorer, advokater og andre rådgivere
        </div>
        <h3 className="section-sub-header">
          Overførsler til tredjepartslande eller internationale organisationer
        </h3>
        <ul>
          <li>
            Udbyder af analytisk service for at følge bruger adfærd baseret på
            en tredjeparts cookie. For at læse mere om dette henviser vi dig til
            cookiesektionen af denne politik.
          </li>
        </ul>
        <p>
          Modtageren af dette data er Google Analytics og overførslen er
          certificeret under EU-U.S. Privacy Shield i overensstemmelse med GDPR
          Art. 45. For at læse mere om Privacy Shield kan du gå til{" "}
          <a href="https://www.privacyshield.gov.">
            https://www.privacyshield.gov.
          </a>
          <br />
          Selvom din data er anonymiseret, vil vi som en nødvendig del af
          overførslen sende noget information om din enhed samt IP hvilket kan
          blive brugt til at identificere dig. Efter deres brug vil Google dog
          slette det, hvorefter det er anonymiseret. For mere information
          henviser vi dig til privatlivs- og/eller tilbageholdelses-politikken
          af tredjeparts modtageren.
        </p>
      </section>
    </div>
  );
};
export default PrivacyPolicy;
