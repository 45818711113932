//#region - Imports
// Libraries
import { Modal, Button } from "react-bootstrap";
import confirm from "../Assets/Reusable/confirm.svg";
import error from "../Assets/Reusable/error.svg";

// Styling
import "../Styles/Components/FormModal.scss";

//#endregion

const FormModal = ({ isValid, handleClose, show, text }) => {
  //#region - Return
  return (
    <Modal show={show} onHide={handleClose} dialogClassName="popup" centered>
      {isValid ? (
        <img src={confirm} alt="Checkmark" />
      ) : (
        <img src={error} alt="Error" />
      )}
      {isValid ? <h2>Mange tak!</h2> : <h2>Noget gik galt</h2>}
      {isValid ? (
        <p>{text}</p>
      ) : (
        <p>
          Der opstod en fejl. Venligst, prøv igen eller kontakt os direkte.
        </p>
      )}
      <Button onClick={handleClose}>OK</Button>
    </Modal>
  );
  //#endregion
};
export default FormModal;
