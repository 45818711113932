//#region - Imports
// Libraries
import {useState} from "react";
import {Modal, Button} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle} from "@fortawesome/free-solid-svg-icons";

// Styling
import "../../Styles/Components/RentPopUps/EventConfirmModal.scss";

// Components
import EventConfirmCard from "./EventConfirmCard";
import EventConfirmForm from "./EventConfirmForm";
//#endregion

const EventConfirmModal = (props) => {
  const {pSpot, img, eventStartTime, eventEndTime, eventName, eventLocation, eventPrice, eventId, className,isRented} = props;

  const [show, setShow] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [wlConfirmed, setWlConfirmed] = useState(false);

  const handleClose = () => {
    setShow(false);
    setConfirmed(false);
  };

  const handleShow = () => setShow(true);

  let rentConfirm = (
    <Modal show={show} onHide={handleClose} className="eventConfirmModal">
      <Modal.Header closeButton>
        <Modal.Title>Send ønske om leje af parkering</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <EventConfirmCard
          pSpot={pSpot}
          img={img}
          eventStartTime={eventStartTime}
          eventEndTime={eventEndTime}
          eventName ={eventName}
          eventPrice ={eventPrice}
        />

        <h2 className="paragraphHeading a">
          Udfyld felterne med dine oplysninger herunder
        </h2>

        <div className="formContainer">
          <EventConfirmForm 
            spot={pSpot}
            setConfirmed={setConfirmed}
            eventStartTime={eventStartTime}
            eventEndTime={eventEndTime}
            eventName ={eventName} 
            eventLocation={eventLocation}
            eventPrice={eventPrice}
            eventId={eventId}/>
        </div>
      </Modal.Body>
    </Modal>
  );

  let waitlistConfirm = (
    <Modal show={show} onHide={handleClose} className="eventConfirmModal">
      <Modal.Header closeButton>
        <Modal.Title>Skriv dig på venteliste til denne parkering </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <EventConfirmCard
          pSpot={pSpot}
          img={img}
          eventStartTime={eventStartTime}
          eventEndTime={eventEndTime}
          eventName ={eventName}
          eventPrice ={eventPrice}
        />

        <h2 className="paragraphHeading a">
          Udfyld felterne med dine oplysninger herunder
        </h2>

        <div className="formContainer">
          <EventConfirmForm 
            spot={pSpot}
            setConfirmed={setWlConfirmed}
            eventStartTime={eventStartTime}
            eventEndTime={eventEndTime}
            eventName ={eventName} 
            eventLocation={eventLocation}
            eventPrice={eventPrice}
            eventId={eventId}/>
        </div>
      </Modal.Body>
    </Modal>
  );

  let rentConfirmation = (
    <Modal show={show} onHide={handleClose} className="eventConfirmModal">
      <Modal.Header closeButton>
        <Modal.Title>Ønske registreret</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="checkIcon">
          <FontAwesomeIcon icon={faCheckCircle} color="#0063db" size="10x" />
        </div>
        <h2 className="paragraphHeading b">
          Du har nu sendt et ønske om leje af parkering
        </h2>
         <EventConfirmCard
          pSpot={pSpot}
          img={img}
          eventStartTime={eventStartTime}
          eventEndTime={eventEndTime}
          eventName={eventName}
          eventPrice ={eventPrice}
        />

        <div className="confirmationContainer">
          <h2 className="paragraphHeading c">Hvad nu?</h2>
          <p className="paragraph">
            ParkShare kontakter dig inden længe, hvor du vil høre nærmere om
            betaling og modtage en endelig bekræftelse på parkeringspladsen, da
            den først er din, når du har en endelig bekræftelse fra os. Vi
            ringer til dig fra nummeret 42 72 99 88.
          </p>
          <div className="regular-button">
            <Button id="btn-submit" type="submit" onClick={handleClose}>
              Videre
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );

  let waitlistConfirmation = (
    <Modal show={show} onHide={handleClose} className="eventConfirmModal">
      <Modal.Header closeButton>
        <Modal.Title>Ønske registreret</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="checkIcon">
          <FontAwesomeIcon icon={faCheckCircle} color="#0063db" size="10x" />
        </div>
        <h2 className="paragraphHeading b">
          Du har nu sendt et ønske om at komme på venteliste til parkeringspladsen
        </h2>
         <EventConfirmCard
          pSpot={pSpot}
          img={img}
          eventStartTime={eventStartTime}
          eventEndTime={eventEndTime}
          eventName={eventName}
          eventPrice ={eventPrice}
        />

        <div className="confirmationContainer">
          <h2 className="paragraphHeading c">Hvad nu?</h2>
          <p className="paragraph">
            ParkShare kontakter dig så snart parkeringspladsen er ledig, og vi kommer til din plads på ventelisten. Vi kontakter dig evt. også, hvis vi får lignende parkeringspladser ledige. Vi ringer til dig fra nummeret 42 72 99 88.
          </p>
          <div className="regular-button">
            <Button id="btn-submit" type="submit" onClick={handleClose}>
              Videre
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );

  const modalPicker = confirmed? rentConfirmation : rentConfirm ;
  const eventWaitlistModalPicker = wlConfirmed? waitlistConfirmation : waitlistConfirm ;


  return (
    <div className="eventConfirmPopUp">
      {
        isRented
        ?

        <>
          <Button variant="primary" onClick={handleShow} className="lej">
            Venteliste
          </Button>

          {eventWaitlistModalPicker}
        
        </>

        :

        <>
        <Button variant="primary" onClick={handleShow} className="lej">
          Lej
        </Button>

        {modalPicker}
      
      </>
       
      
      }

    
      
    </div>
  );
};

export default EventConfirmModal;
