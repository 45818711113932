//#region - Imports
// Libraries
import { Accordion, Card, Button } from "react-bootstrap";

// Styling
import "../Styles/Components/FAQ.scss";

// Asset
import logo from "../Assets/parkshare-short-logo.png";

//#endregion

const FAQ = (props) => {
  const { tabs } = props;

  //#region - Return
  const faqs = tabs.map((tab) => (
    <Accordion key={tab.id}>
      <Card>
        <Accordion.Toggle
          className="toggle"
          as={Button}
          variant="link"
          eventKey={tab.id}
        >
          <div className="option">
            <img src={logo} alt="" id="logo" />
            <h4 className="label">{tab.label}</h4>
          </div>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey={tab.id}>
          <Card.Body>
            <p className="text">{tab.description}</p>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  ));
  return (
    <div className="faq">
      <div className="faqContainer">
        <div className="container2">{faqs}</div>
      </div>
    </div>
  );
  //#endregion
};

export default FAQ;
