import {useEffect, useRef, useState} from "react";
import useWindowDimensions from '../Hooks/useWindowDimensions'
import "../../Styles/Components/ParkingList/Pagination.scss";

const Pagination = (props) => {
  const {parkingListArray, pagination, setPagination} = props;
  const { width } = useWindowDimensions();
  const minWidth = 765;

  const [paginationBottom, setPaginationBottom] = useState("");

  const paginationRef = useRef(null);

  useEffect(() => {
    numberOfPages(parkingListArray.length);
  }, [parkingListArray]);

  useEffect(() => {
    handlePagination();
    paginationRef.current = paginationBottom;
  }, [pagination]);

  const numberOfPages = (length) => {
    let pages = [];
    const len = Math.ceil(length / pagination.postsPerPage);

    for (let i = 1; i <= len; i++) {
      pages.push(i);
    }

    setPagination({
      ...pagination,
      pageNumbers: pages,
      currentPage: 1
    });
  };

  const handlePageChange = (number) => {
    setPagination({
      ...pagination,
      currentPage: number,
    });
  };

  const prevNavButton =
    width > minWidth ? (
      <div
        className="arrowContainer"
        id="pagination-item-prev"
        onClick={() => {
          if (pagination.currentPage >= 2)
            handlePageChange(pagination.currentPage - 1);
        }}
      >
        &#60; Forrige
      </div>
    ) : (
      <div
        className="arrowContainer"
        id="pagination-item-prev"
        onClick={() => {
          if (pagination.currentPage >= 2)
            handlePageChange(pagination.currentPage - 1);
        }}
      >
        &#60;
      </div>
    );

  const nextNavButton = width > minWidth ? (
    <div
      className="arrowContainer"
      id="pagination-item-next"
      onClick={() => {
        if (pagination.currentPage < pagination.pageNumbers.length)
          handlePageChange(pagination.currentPage + 1);
      }}
    >
      Næste &#62;
    </div>
  ) : (
    <div
      className="arrowContainer"
      id="pagination-item-next"
      onClick={() => {
        if (pagination.currentPage < pagination.pageNumbers.length)
          handlePageChange(pagination.currentPage + 1);
      }}
    >
      &#62;
    </div>
  )

  const handlePagination = () => {
    // Checks whether there are enough pages to filter
    if (pagination.pageNumbers.length >= pagination.minPages) {
      // Checks whether we are in the beginning
      if (pagination.currentPage < 5) {
        setPaginationBottom(
          <div className="pagination">
            <div
              className={
                pagination.currentPage === 1
                  ? "pagination-item active"
                  : "pagination-item"
              }
              onClick={() => handlePageChange(1)}
            >
              1
            </div>
            <div
              className={
                pagination.currentPage === 2
                  ? "pagination-item active"
                  : "pagination-item"
              }
              onClick={() => handlePageChange(2)}
            >
              2
            </div>
            <div
              className={
                pagination.currentPage === 3
                  ? "pagination-item active"
                  : "pagination-item"
              }
              onClick={() => handlePageChange(3)}
            >
              3
            </div>
            <div
              className={
                pagination.currentPage === 4
                  ? "pagination-item active"
                  : "pagination-item"
              }
              onClick={() => handlePageChange(4)}
            >
              4
            </div>
            <div
              className={
                pagination.currentPage === 5
                  ? "pagination-item active"
                  : "pagination-item"
              }
              onClick={() => handlePageChange(5)}
            >
              5
            </div>
            <div className="divider">...</div>
            <div
              className="pagination-item"
              onClick={() => handlePageChange(pagination.pageNumbers.length)}
            >
              {pagination.pageNumbers.length}
            </div>
          </div>
        );
      } // Checks whether we are in the end
      else if (pagination.pageNumbers.length - pagination.currentPage < 5) {
        setPaginationBottom(
          <div className="pagination">
            <div
              className="pagination-item"
              onClick={() => handlePageChange(1)}
            >
              1
            </div>
            <div className="divider">...</div>

            <div
              className={
                pagination.currentPage === pagination.pageNumbers.length - 4
                  ? "pagination-item active"
                  : "pagination-item"
              }
              onClick={() =>
                handlePageChange(pagination.pageNumbers.length - 4)
              }
            >
              {pagination.pageNumbers.length - 4}
            </div>
            <div
              className={
                pagination.currentPage === pagination.pageNumbers.length - 3
                  ? "pagination-item active"
                  : "pagination-item"
              }
              onClick={() =>
                handlePageChange(pagination.pageNumbers.length - 3)
              }
            >
              {pagination.pageNumbers.length - 3}
            </div>
            <div
              className={
                pagination.currentPage === pagination.pageNumbers.length - 2
                  ? "pagination-item active"
                  : "pagination-item"
              }
              onClick={() =>
                handlePageChange(pagination.pageNumbers.length - 2)
              }
            >
              {pagination.pageNumbers.length - 2}
            </div>
            <div
              className={
                pagination.currentPage === pagination.pageNumbers.length - 1
                  ? "pagination-item active"
                  : "pagination-item"
              }
              onClick={() =>
                handlePageChange(pagination.pageNumbers.length - 1)
              }
            >
              {pagination.pageNumbers.length - 1}
            </div>
            <div
              className={
                pagination.currentPage === pagination.pageNumbers.length
                  ? "pagination-item active"
                  : "pagination-item"
              }
              onClick={() => handlePageChange(pagination.pageNumbers.length)}
            >
              {pagination.pageNumbers.length}
            </div>
          </div>
        );
      } else {
        setPaginationBottom(
          <div className="pagination">
            <div
              className="pagination-item"
              onClick={() => handlePageChange(1)}
            >
              1
            </div>
            <div className="divider">...</div>
            <div
              className="pagination-item"
              onClick={() => handlePageChange(pagination.currentPage - 2)}
            >
              {pagination.currentPage - 2}
            </div>
            <div
              className="pagination-item"
              onClick={() => handlePageChange(pagination.currentPage - 1)}
            >
              {pagination.currentPage - 1}
            </div>
            <div
              className="pagination-item active"
              onClick={() => handlePageChange(pagination.currentPage)}
            >
              {pagination.currentPage}
            </div>
            <div
              className="pagination-item"
              onClick={() => handlePageChange(pagination.currentPage + 1)}
            >
              {pagination.currentPage + 1}
            </div>
            <div
              className="pagination-item"
              onClick={() => handlePageChange(pagination.currentPage + 2)}
            >
              {pagination.currentPage + 2}
            </div>
            <div className="divider">...</div>
            <div
              className="pagination-item"
              onClick={() => handlePageChange(pagination.pageNumbers.length)}
            >
              {pagination.pageNumbers.length}
            </div>
          </div>
        );
      }
    } else {
      let p = [];
      for (let number = 1; number <= pagination.pageNumbers.length; number++) {
        p.push(
          <div
            key={number}
            className={
              pagination.currentPage === number
                ? "pagination-item active"
                : "pagination-item"
            }
            onClick={() => handlePageChange(number)}
          >
            {number}
          </div>
        );
      }
      setPaginationBottom(<div className="pagination">{p}</div>);
    }
  };

  return (
    <div className="paginationContainer" ref={paginationRef}>
      {prevNavButton}
      {paginationBottom}
      {nextNavButton}
    </div>
  );
};

export default Pagination;
