//#region - Imports
// Libraries
import {Button} from "react-bootstrap";
import {HashLink} from "react-router-hash-link";

// Styling
import "../Styles/Pages/Faq.scss";

// Components
import Meta from "../Components/Meta";
import {
  findParkeringFAQ,
  rentOutFAQ,
  eventDriverFAQ,
  eventOwnerFAQ,
  faqGeneralQuestions,
} from "../Components/FAQWidgetDatas";
import {COOKIE_NAMES, getCookieValue} from "../Components/Tracking/Cookie";
import {initGA, pageView} from "../Components/Tracking/Tracking";

// Asset
import logo from "../Assets/parkshare-short-logo.png";
//#endregion

const FAQPage = () => {
  if (getCookieValue(COOKIE_NAMES.PERFORMANCE)) {
    initGA();
    pageView();
  }
  const handleToggle = (e) => {
    if (e.currentTarget.classList.contains("questionAnswer")) {
      e.currentTarget.classList.remove("questionAnswer");
    } else {
      e.currentTarget.classList.add("questionAnswer");
    }
  };

  return (
    <div className="faq">
      <Meta
        title="FAQ | ParkShare"
        description="Har du spørgsmål til vores service, leje eller udlejning af en parkeringsplads? Så kan du her på siden finde vores oftest stillede spørgsmål samt svar hertil."
      />
      {/* TOP */}
      <section className="top">
        <div className="bg">
          <div className="content">
            <h1>FAQ</h1>
            <p>Her kan du finde svar på de mest almindelige spørgsmål</p>
          </div>
        </div>
      </section>
      {/* Top Button */}
      <section>
        <h2 className="sectionHeading">
          Klik på knappen og gå direkte til afsnittet du ønsker
        </h2>
        <div className="regular-button">
          <a href="#general">
            <Button className="generaltButton" id="btn-submit">
              Generelt
            </Button>
          </a>
          <a href="#bilister">
            <Button className="bilisterButton" id="btn-submit">
              For bilister
            </Button>
          </a>
          <a href="#udlejer">
            <Button className="udlejerButton" id="btn-submit">
              For udlejer
            </Button>
          </a>
        </div>
      </section>
      {/* GENERAL QUESTIONS */}
      <section className="generalQuestions">
        <div className="content" id="general">
          <h2 className="sectionHeading">Generelle Spørgsmål</h2>
          <div className="element1">
            {faqGeneralQuestions.map((generalQ) => {
              return (
                <div className="questionContainer">
                  <div className="imageContainer">
                    <img src={logo} alt="ParkShares logo" id="logo" />
                  </div>
                  <div
                    className="textContainer questionAnswer"
                    onClick={handleToggle}
                    key={generalQ}
                  >
                    <h6 className="questionLabel">{generalQ.label}</h6>
                    <p>{generalQ.description}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
      {/* FOR BILISTER QUESTIONS */}
      <section className="forBilister">
        <div className="content" id="bilister">
          <h2 className="sectionHeading">For bilister</h2>
          <div className="element">
            <h4 className="faqDivider">Generelt - for bilister</h4>
            {findParkeringFAQ.map((generalFindPQ) => {
              return (
                <div className="questionContainer">
                  <div className="imageContainer">
                    <img src={logo} alt="ParkShares logo" id="logo" />
                  </div>
                  <div
                    className="textContainer questionAnswer"
                    onClick={handleToggle}
                    key={generalFindPQ}
                  >
                    <h6 className="questionLabel">{generalFindPQ.label}</h6>
                    <p>{generalFindPQ.description}</p>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="element">
            <h4 className="faqDivider">Eventparkering - for bilister</h4>
            {eventDriverFAQ.map((eventDriverQ) => {
              return (
                <div className="questionContainer">
                  <div className="imageContainer">
                    <img src={logo} alt="ParkShares logo" id="logo" />
                  </div>
                  <div
                    className="textContainer questionAnswer"
                    onClick={handleToggle}
                    key={eventDriverQ}
                  >
                    <h6 className="questionLabel">{eventDriverQ.label}</h6>
                    <p>{eventDriverQ.description}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
      {/* FOR UDLEJER QUESTIONS */}
      <section className="forUdlejer spaceBelow">
        <div className="content" id="udlejer">
          <h2 className="sectionHeading">For udlejer</h2>
          <div className="element">
            <h4 className="faqDivider">Generelt - for udlejere</h4>
            {rentOutFAQ.map((generalRentOutPQ) => {
              return (
                <div className="questionContainer">
                  <div className="imageContainer">
                    <img src={logo} alt="ParkShares logo" id="logo" />
                  </div>
                  <div
                    className="textContainer questionAnswer"
                    onClick={handleToggle}
                    key={generalRentOutPQ}
                  >
                    <h6 className="questionLabel">{generalRentOutPQ.label}</h6>
                    <p>{generalRentOutPQ.description}</p>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="element">
            <h4 className="faqDivider">Eventparkering - for udlejere</h4>
            {eventOwnerFAQ.map((eventRentOutPQ) => {
              return (
                <div className="questionContainer">
                  <div className="imageContainer">
                    <img src={logo} alt="ParkShares logo" id="logo" />
                  </div>
                  <div
                    className="textContainer questionAnswer"
                    onClick={handleToggle}
                    key={eventRentOutPQ}
                  >
                    <h6 className="questionLabel">{eventRentOutPQ.label}</h6>
                    <p>{eventRentOutPQ.description}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
      {/* BOTTOM */}
      <section className="bottom">
        <div className="background">
          <div className="content">
            <div className="element1">
              <h3 id="headingBottom">
                Fandt du ikke svaret på dit spørgsmål? Kontakt os gerne
              </h3>
              <HashLink smooth to="/kontakt#kontaktos">
                <Button id="btn-kontakt">
                  <span id="btn-text">Kontakt os</span>
                </Button>
              </HashLink>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default FAQPage;
