// Library
import Slider from "react-slick";

// Styling
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../Styles/Components/AboutUs/Brand1.scss";

// Assets
import Img1 from "../../Assets/Aboutus/brand1/gyldnefugl2.png";
import Img2 from "../../Assets/Aboutus/brand1/ivrkstterprisen_3.png";
import Img3 from "../../Assets/Aboutus/brand1/venture.png";
import Img4 from "../../Assets/Aboutus/brand1/iwdk.jpg";
import Img5 from "../../Assets/Aboutus/brand1/via.jpg";

const Brand1 = () => {
  const settings = {
    lazyload: true,
    slidesToShow: 4,
    slidesToScroll: 2,
    autoplay: true,
    autoplaySpeed: 4000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1082,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 620,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="brand1">
      <h2 className="sectionHeading">Priser, nomineringer og konkurrencer</h2>
      <Slider {...settings}>
        <div className="itemContainer">
          <div>
            <img src={Img1} alt="gyldnefugl logo" />
          </div>
          <p>2020</p>
        </div>
        <div className="itemContainer">
          <div>
            <img src={Img1} alt="gyldnefugl logo" />
          </div>
          <p>2020</p>
        </div>
        <div className="itemContainer">
          <div>
            <img src={Img2} alt="iværksætterprisen logo" />
          </div>
          <p>2019</p>
        </div>
        <div className="itemContainer">
          <div>
            <img src={Img3} alt="venture cup logo" />
          </div>
          <p>2019</p>
        </div>
        <div className="itemContainer">
          <div>
            <img src={Img4} alt="iwdk logo" />
          </div>
          <p>2019</p>
        </div>
        <div className="itemContainer">
          <div>
            <img src={Img5} alt="via logo" />
          </div>
          <p>2019</p>
        </div>
      </Slider>
    </div>
  );
};

export default Brand1;
