import React from "react";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import { NavLink as topnav, Link } from "react-router-dom";
import Logo from "../parkshare-full-logo.png";
import "../Styles/Layouts/Header.scss";
export default class Header extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.closeNavbar = this.closeNavbar.bind(this);
    this.navRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.state = {
      isOpen: false,
    };
  }
  componentDidMount() {
    // Bind event listener
    document.addEventListener("mousedown", this.handleClickOutside);
  }
  componentWillUnmount() {
    // Unbind event listener on cleanup
    document.removeEventListener("mousedown", this.handleClickOutside);
  }
  // Check if click is outside of target
  handleClickOutside(event) {
    if (this.navRef.current && !this.navRef.current.contains(event.target)) {
      this.closeNavbar();
    }
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }
  closeNavbar() {
    this.setState({
      isOpen: false,
    });
  }
  render() {
    return (
      <header ref={this.navRef}>
        <Navbar color="light" light expand="lg" fixed="top">
          <Link to="/">
            <img src={Logo} alt="logo" />
          </Link>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="ml-auto" navbar>
              <NavItem className="navItem">
                {/* IF get.cookie ? (return NAVLINK) : (set state ) */}
                {/* onclick, run function */}
                <NavLink
                  tag={topnav}
                  className="nav-link"
                  to="/"
                  onClick={this.closeNavbar}
                  activeClassName="active"
                  exact
                  path="/"
                >
                  Forside
                </NavLink>
              </NavItem>
              <NavItem className="navItem">
                <NavLink
                  tag={topnav}
                  className="nav-link"
                  to="/find-parkering"
                  onClick={this.closeNavbar}
                  activeClassName="active"
                >
                  Find parkering
                </NavLink>
              </NavItem>
              <NavItem className="navItem">
                <NavLink
                  tag={topnav}
                  className="nav-link"
                  to="/udlej-parkering"
                  onClick={this.closeNavbar}
                  activeClassName="active"
                >
                  Udlej parkering
                </NavLink>
              </NavItem>
              <NavItem className="navItem">
                <NavLink
                  tag={topnav}
                  className="nav-link"
                  to="/event"
                  onClick={this.closeNavbar}
                  activeClassName="active"
                >
                  Events
                </NavLink>
              </NavItem>
              <NavItem className="navItem">
                <NavLink
                  tag={topnav}
                  className="nav-link"
                  to="/for-virksomheder"
                  onClick={this.closeNavbar}
                  activeClassName="active"
                >
                  Virksomheder
                </NavLink>
              </NavItem>
              <NavItem className="navItem">
                <NavLink
                  tag={topnav}
                  className="nav-link"
                  to="/om-os"
                  onClick={this.closeNavbar}
                  activeClassName="active"
                >
                  Om os
                </NavLink>
              </NavItem>
              <NavItem className="navItem">
                <NavLink
                  tag={topnav}
                  className="nav-link"
                  to="/kontakt"
                  onClick={this.closeNavbar}
                  activeClassName="active"
                >
                  Kontakt os
                </NavLink>
              </NavItem>
              <NavItem className="navItem">
                <NavLink
                  tag={topnav}
                  className="nav-link"
                  to="faq"
                  onClick={this.closeNavbar}
                  activeClassName="active"
                >
                  FAQ
                </NavLink>
              </NavItem>
              {/*     <NavItem className="navItem">
                <NavLink
                  tag={topnav}
                  className="nav-link"
                  to="/parkingList2"
                  onClick={this.closeNavbar}
                  activeClassName="active"
                >
                  ParkingList2
                </NavLink>
              </NavItem>
              <NavItem className="navItem">
                <NavLink
                  tag={topnav}
                  className="nav-link"
                  to="/eventList2"
                  onClick={this.closeNavbar}
                  activeClassName="active"
                >
                  EventList2
                </NavLink>
              </NavItem> */}
            </Nav>
          </Collapse>
        </Navbar>
      </header>
    );
  }
}
