import React from "react";
import logo from "../parkshare-full-logo.png";
import "../Styles/Layouts/Footer.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhoneAlt } from "@fortawesome/free-solid-svg-icons";
import Cookie from "../Components/Tracking/Cookie";
const Footer = () => {
  return (
    <footer>
      <section className="sectionDivider">
        <div className="leftFooter">
          <ul>
            <li className="spaceUnder">
              <div className="footerTitle">Kontakt</div>
              <a href="mailto:info@parkshare.dk">
                <FontAwesomeIcon icon={faEnvelope} color="white" />
                <span> info@parkshare.dk </span>
              </a>
              <br></br>
              <a href="tel:004542729988">
                <FontAwesomeIcon icon={faPhoneAlt} color="white" />
                <span> +45 42 72 99 88</span>
              </a>
            </li>
            <li className="spaceUnder">
              <div className="footerTitle">Telefontider</div>
              <span>Man-Fre kl. 16:00 - 21:30</span>
              <p>Lør-Søn kl. 09:00 - 21:30</p>
            </li>
            <li>
              <div className="footerTitle"> Adresse </div>
              <a
                href="https://goo.gl/maps/AVfWjBtyEQbEB8248"
                target="_blank"
                rel="noreferrer noopener"
              >
                <span>
                  Universitetsbyen 14 <br></br> 8000 Aarhus C
                </span>
              </a>
            </li>
          </ul>
        </div>
        <div className="rightFooter">
          <img src={logo} alt="logo" className="footerLogo" />
          <div className="SoMeIcons">
            <div className="iconContainer">
              <a
                href="https://www.facebook.com/ParkShareEurope/"
                target="_blank"
                rel="noreferrer noopener"
              >
                <svg
                  className="footerIcon"
                  data-name="Layer 1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 200 200"
                >
                  <circle className="cls-1" cx="100" cy="100" r="87.31" />
                  <path
                    className="cls-2"
                    d="M124.52,105.56q0-8.48.08-16.94H108.2V77.53c0-4.68,2.29-9.24,9.64-9.24h7.45V53.74a91.15,91.15,0,0,0-13.23-1.15c-13.51,0-22.34,8.18-22.34,23v13h-15v17.09h15V147H108.2V105.71Z"
                  />
                </svg>
              </a>
            </div>
            <div className="iconContainer">
              <a
                href="https://www.instagram.com/parkshare/?hl=en"
                target="_blank"
                rel="noreferrer noopener"
              >
                <svg
                  className="footerIcon"
                  data-name="Layer 1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 200 200"
                >
                  <rect
                    className="cls-1"
                    x="56.12"
                    y="56.12"
                    width="87.75"
                    height="87.75"
                    rx="22.65"
                  />
                  <circle className="cls-1" cx="100" cy="100" r="20.88" />
                  <circle className="cls-2" cx="127.87" cy="74.8" r="6.99" />
                  <circle className="cls-1" cx="100" cy="100" r="87.31" />
                </svg>
              </a>
            </div>
            <div className="iconContainer">
              <a
                href="https://www.linkedin.com/company/parkshare-europe/"
                target="_blank"
                rel="noreferrer noopener"
              >
                <svg
                  className="footerIcon"
                  data-name="Layer 1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 200 200"
                >
                  <path
                    className="cls-2"
                    d="M83.09,127H70.7V87.12H83.09ZM76.88,81.68a7.21,7.21,0,1,1,7.18-7.24A7.23,7.23,0,0,1,76.88,81.68ZM129.47,127H117.09V107.6C117.09,103,117,97,110.65,97s-7.43,5-7.43,10.23V127H90.85V87.12h11.88v5.45h.17c1.65-3.14,5.69-6.45,11.72-6.45,12.54,0,14.85,8.26,14.85,19Z"
                  />
                  <circle className="cls-1" cx="100" cy="100" r="87.31" />
                </svg>
              </a>
            </div>
            <div className="iconContainer">
              <a
                href="https://www.youtube.com/channel/UC79yPJNM4jxFII0gFEAb83Q"
                target="_blank"
                rel="noreferrer noopener"
              >
                <svg
                  className="footerIcon"
                  data-name="Layer 1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 200 200"
                >
                  <path
                    className="cls-2"
                    d="M134.89,67.78H65.11A12.89,12.89,0,0,0,52.22,80.67v38.66a12.89,12.89,0,0,0,12.89,12.89h69.78a12.89,12.89,0,0,0,12.89-12.89V80.67A12.89,12.89,0,0,0,134.89,67.78ZM91.52,114.89V82l26.07,16.15Z"
                  />
                  <circle className="cls-1" cx="100" cy="100" r="87.31" />
                </svg>
              </a>
            </div>
          </div>
        </div>
      </section>
      <Cookie />
      <h6 className="copyRight">
        <p>&copy; ParkShare 2024 - CVR: 40417796</p>
      </h6>
    </footer>
  );
};
export default Footer;
