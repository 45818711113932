import info from "../Assets/Forbusiness/info-icon.png";
import "../Styles/Components/InfoBox.scss";
const InfoBox = ({ infoText }) => {
  return (
    <span className="info">
      <img src={info} alt="Info" className="info-icon" />
      <div className="info-box">{infoText}</div>
    </span>
  );
};
export default InfoBox;
