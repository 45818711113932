//#region - Imports
// Libraries
import {Card, Button} from "react-bootstrap";
import {NavLink} from "react-router-dom";

// Styling
import "../Styles/Pages/Frontpage.scss";
import "bootstrap/dist/css/bootstrap.min.css";

// Components
import Meta from "../Components/Meta";
import ReviewCarousel from "../Components/ReviewCarousel";
import Brand2 from "../Components/AboutUsPage/Brand2";
import {COOKIE_NAMES, getCookieValue} from "../Components/Tracking/Cookie";
import {initGA, pageView} from "../Components/Tracking/Tracking";

// Assets
import FindPark_icon from "../Assets/Frontpage/location-pin-parking.png";
import RentOut_icon from "../Assets/Frontpage/house-shape-parking-rent-out.png";
import Reserved_icon from "../Assets/Frontpage/parking-sign-reserved.png";
import Passiv_icon from "../Assets/Frontpage/hand-holding-money-danish-krone.png";
import Flexible_icon from "../Assets/Frontpage/analog-clock-white.png";
import Nemt_bekvemt_icon from "../Assets/Frontpage/shield-tick-checkmark.png";
import Quote_R from "../Assets/Frontpage/quotesRight.png";
import Quote_L from "../Assets/Frontpage/quotesLeft.png";
import Animation from "../Assets/Frontpage/cars-driving-parking-house-office-animation.gif";
import logo from "../Assets/parkshare-logo.png";
//#endregion

const FrontPage = () => {
  if (getCookieValue(COOKIE_NAMES.PERFORMANCE)) {
    initGA();
    pageView();
  }

  return (
    <div className="frontpage">
      <Meta
        title="Find, Lej & Udlej Parkering - ParkShare"
        description="ParkShare er en deleøkonomi for parkering. Med ParkShare kan du både finde parkering og udleje parkering, og opnå en ekstra indtægt. Vi gør parkering nemt!"
      />
      {/* TOP */}
      <section className="top">
        <div className="bg">
          <div className="content">
            <div className="element1">
              <img src={logo} className="logo" alt="parkshare logo" />
              <h1 className="pageHeading">
                En deleøkonomi for parkeringspladser
              </h1>
              <div className="buttonContainer">
                <NavLink to="/find-parkering">
                  <Button
                    className="findPButton hvr-buzz-out hvr-glow"
                    size="lg"
                  >
                    Find Parkering
                  </Button>
                </NavLink>
                <NavLink to="/udlej-parkering">
                  <Button
                    className="rentOutButton hvr-buzz-out hvr-glow"
                    size="lg"
                  >
                    Udlej Parkering
                  </Button>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* CONCEPT */}
      <section className="concept" id="concept">
        <div className="content spaceBelow">
          <Card>
            <Card.Title className="sectionHeading">
              Hvad er ParkShare?
            </Card.Title>
            <Card.Body>
              <div className="elementFlex">
                <div className="elementLeft">
                  <div className="element1">
                    <img
                      src={FindPark_icon}
                      alt="Kortplaceringsnål med et stort P"
                    />
                    <div className="conceptText">
                      <h3 className="paragraphHeading">
                        Lej en parkeringsplads
                      </h3>
                      <p className="paragraph">
                        ParkShare kan hjælpe dig med at finde lige den
                        parkeringsplads du har brug for. Du kan både leje en
                        fast parkeringsplads, eller blot for en kortere periode
                        når du har brug for det. Du kan booke parkering allerede
                        inden du kører hjemmefra. Uden besvær kan du spare tid,
                        brændstof og køredistance
                      </p>
                    </div>
                  </div>
                  <div className="element2">
                    <img
                      src={RentOut_icon}
                      alt="Hus med et stort P og Til Leje skilt"
                    />
                    <div className="conceptText">
                      <h3 className="paragraphHeading">
                        Udlej din parkeringsplads
                      </h3>
                      <p className="paragraph">
                        Med ParkShare kan private og virksomheder udleje deres
                        parkeringsplads, når de ikke selv bruger den. Du får en
                        ekstra indtægt, og bestemmer selv hvornår og hvordan du
                        vil udleje. Du hjælper miljøet, samfundet og sikrer en
                        verden med bedre parkering
                      </p>
                    </div>
                  </div>
                </div>
                <div className="element3">
                  <iframe
                    width="597px"
                    height="336px"
                    src="https://www.youtube.com/embed/dKdnNxkuftM"
                    title="frontpageVideo"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    // allowFullScreen
                    allowFullScreen
                  ></iframe>
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>
      </section>
      {/* WHY PARKSHARE */}
      <section className="whyParkshare">
        <div className="content spaceAbove spaceBelow">
          <h2 className="sectionHeading">Hvorfor ParkShare?</h2>
          <div className="whyContainer spaceBelow">
            <div className="element">
              <p className="iconHeading">
                Få en ekstra <br /> indkomst
              </p>
              <img
                src={Passiv_icon}
                alt="Grafik af Hånd der holder en Dansk kronemønt"
              />
              <p className="iconParagraph">
                Med ParkShare kan du uden besvær udleje dine parkeringspladser i
                de timer, du ikke bruger dem. Lad ParkShare tjene penge for dig.
              </p>
            </div>
            <div className="element">
              <p className="iconHeading">
                Fleksible <br />
                parkeringstider
              </p>
              <img src={Flexible_icon} alt="Grafik af Analog ur" />
              <p className="iconParagraph">
                Du bestemmer selv, om du vil udleje din parkering på daglig
                basis <span style={{display: "none"}}>, 1 time </span> eller i
                en hel måned. Intet problem. Din plads, dine tider!
              </p>
            </div>
            <div className="element">
              <p className="iconHeading">
                Reserver din <br />
                parkeringsplads
              </p>
              <img
                src={Reserved_icon}
                alt="Grafik af Firkantet vejskilt med et stort P og står Reserveret"
              />
              <p className="iconParagraph">
                Du slipper for stresset og tidsspildet ved at køre rundt og lede
                efter parkering. Vi gør det nemt. Du kan booke på forhånd.
              </p>
            </div>
            <div className="element">
              <p className="iconHeading">
                Sikkert, nemt <br /> og bekvemt
              </p>
              <img
                src={Nemt_bekvemt_icon}
                alt="Grafik af et Skjold med flueben"
              />
              <p className="iconParagraph">
                Med ParkShares service kan du sikkert, nemt og bekvemt både leje
                og udleje parkering. Vi sikrer pålidelig køb og salg.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/*Animation*/}
      <section className="animationContent spaceBelow spaceAbove">
        <div className="content">
          <img
            src={Animation}
            alt="Udlejer forlader sit hjem for at tage på arbejde. Lejers bil parker hos udlejers hjem og udlejer begynder at tjene penge"
          />
        </div>
      </section>
      {/* REVIEWS  */}
      <section className="reviewContent">
        <div className="bg">
          <div className="content">
            <img
              className="reviewLeftImg"
              src={Quote_L}
              alt="parkshare left quote"
            />
            <ReviewCarousel />
            <img
              className="reviewRightImg"
              src={Quote_R}
              alt="parkshare right quote"
            />
          </div>
        </div>
      </section>
      <section section="brands" className="brands spaceBelow">
        <div className="content">
          <Brand2></Brand2>
        </div>
      </section>
    </div>
  );
};

export default FrontPage;
