//#region - Imports
// Styling
import "../Styles/Pages/Contact.scss";
import {
  faEnvelope,
  faHome,
  faPhoneAlt,
} from "@fortawesome/free-solid-svg-icons";

// Components
import ContactForm from "../Components/ContactForm";
import Meta from "../Components/Meta";
import {COOKIE_NAMES, getCookieValue} from "../Components/Tracking/Cookie";
import {initGA, pageView} from "../Components/Tracking/Tracking";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

// Assets
import theKitchenImg from "../Assets/Compressed/the-kitchen-aarhus.jpg"; // compressed image
import carIcon from "../Assets/Contact/parkshare-car-white.png";
import busIcon from "../Assets/Contact/parkshare-bus.png";
//#endregion

const Contact = () => {
  // Track pageview
  if (getCookieValue(COOKIE_NAMES.PERFORMANCE)) {
    initGA();
    pageView();
  }
  return (
    <div className="contact">
      <Meta
        title="Kontakt Os | ParkShare"
        description="Har du spørgsmål eller andre henvendelser til ParkShare, så er du meget velkommen til at kontakte os. Ønsker du personlig betjening, så hjælper vi dig også gerne."
      />
      {/* HERO SECTION */}
      <section className="top">
        <div className="bg">
          <div className="content">
            <h1 className="pageHeading">Kontakt os</h1>
            <p className="subHeading">
              Hvis du har spørgsmål eller andre henvendelser
            </p>
          </div>
        </div>
      </section>
      {/* CONTACT INFO */}
      <section className="contact-info" id="kontaktos">
        <div className="content">
          <div className="details">
            <h2 className="sectionHeading">Kontakt os</h2>
            <p className="paragraph">
              Udfyld formularen til højre, så hjælper vi dig gerne eller
              besvarer de spørgsmål du må have
            </p>
            <h3 className="paragraphHeading">Eller kontakt os på</h3>
            <div className="info-row">
              <FontAwesomeIcon icon={faPhoneAlt} color="black" />
              <p className="paragraph">+45 42 72 99 88</p>
            </div>
            <div className="info-row">
              <FontAwesomeIcon icon={faEnvelope} color="black" />
              <p className="paragraph">info@parkshare.dk</p>
            </div>
            <div className="info-row">
              <FontAwesomeIcon icon={faHome} color="black" />
              <div>
                <p className="paragraph">Universitetsbyen 14</p>
                <p className="paragraph">8000 Aarhus C</p>
              </div>
            </div>
            <span></span>
            <h3 className="paragraphHeading">Ønsker du at søge job hos os?</h3>
            <p className="paragraph">
              Du er altid velkommen til at sende en ansøgning og CV
            </p>
            <div className="info-row">
              <FontAwesomeIcon icon={faEnvelope} color="black" />
              <p className="paragraph">job@parkshare.dk</p>
            </div>
          </div>
          <ContactForm />
        </div>
      </section>
      {/* TRANSPORT */}
      <section className="transport">
        <div className="content">
          <div className="transport-image">
            <img
              src={theKitchenImg}
              alt="The Kitchen - ParkShares kontor omringet af biler"
            />
            <div className="overlay"></div>
          </div>
          <div className="transport-details">
            <div className="details">
              <h2 className="sectionHeading white">Har du møde med os?</h2>
              <p className="theKitchen white moreSpace">
                For at komme direkte til vores kontor, skal du søge “The
                Kitchen” på Google Maps og ikke Universitetsbyen 14
              </p>
              {/* Car */}
              <div className="transport-means">
                <div className="car-container">
                  <img src={carIcon} alt="Hvid bil" className="car" />
                </div>
                <div className="text-container white">
                  <h3 className="paragraphHeading">Kommer du med bil</h3>
                  <p className="paragraph">
                    4 timers gratis parkering lige ude foran
                  </p>
                  <p className="paragraph">Husk p-skiven!</p>
                </div>
              </div>
              {/* Bus */}
              <div className="transport-means">
                <img src={busIcon} alt="Hvid bus" className="bus" />

                <div className="text-container white">
                  <h2 className="paragraphHeading">
                    Kommer du med offentlig transport
                  </h2>
                  <p className="paragraph">Tag bus 15, 1A til Nørrebrogaade</p>
                  <p className="paragraph">
                    Tag letbanen til Universitetsparken
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default Contact;
