import { Helmet } from 'react-helmet';
import PropTypes from "prop-types";
const Meta = ({ title, description }) => {
    return (
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={description} />
        </Helmet>
    )
}
Meta.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
};
export default Meta;