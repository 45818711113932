const sortingParkingList = (props) => {
  console.log("Sorting Parking List", props);
  // Sorting throguh rentperiods
  const sortedArr = props.sort(function (a, b) {
    //YEAR
    if (a.rentPeriodStart.slice(0, 4) > b.rentPeriodStart.slice(0, 4)) {
      return 1;
    } else if (a.rentPeriodStart.slice(0, 4) < b.rentPeriodStart.slice(0, 4)) {
      return -1;
    }
    //MONTH
    else if (a.rentPeriodStart.slice(5, 7) > b.rentPeriodStart.slice(5, 7)) {
      return 1;
    } else if (a.rentPeriodStart.slice(5, 7) < b.rentPeriodStart.slice(5, 7)) {
      return -1;
    }
    //DAY
    else if (a.rentPeriodStart.slice(8, 10) > b.rentPeriodStart.slice(8, 10)) {
      return 1;
    } else if (
      a.rentPeriodStart.slice(8, 10) < b.rentPeriodStart.slice(8, 10)
    ) {
      return -1;
    }
    //HOUR
    else if (
      a.rentPeriodStart.slice(11, 13) > b.rentPeriodStart.slice(11, 13)
    ) {
      return 1;
    } else if (
      a.rentPeriodStart.slice(11, 13) < b.rentPeriodStart.slice(11, 13)
    ) {
      return -1;
    }
    return 0;
  });

  const sortedArrPrZipCode = sortingParkingListPrZipCode(sortedArr);

  return sortedArrPrZipCode;
};

const sortingParkingListPrZipCode = (props) => {
  const sortedArrayPrZipCode = props.sort(function (a, b) {
    if (a.address.zipCodeDistrict.zipcode > b.address.zipCodeDistrict.zipcode) {
      return 1;
    } else if (
      a.address.zipCodeDistrict.zipcode < b.address.zipCodeDistrict.zipcode
    ) {
      return -1;
    }
    return 0;
  });
  return sortedArrayPrZipCode;
};

const sortingEventList = (props) => {
  console.log("Sorting Event List", props);
  const sortedArr = props.sort(function (a, b) {
    //YEAR
    if (a.startTime.slice(0, 4) > b.startTime.slice(0, 4)) {
      return 1;
    } else if (a.startTime.slice(0, 4) < b.startTime.slice(0, 4)) {
      return -1;
    }
    //MONTH
    else if (a.startTime.slice(5, 7) > b.startTime.slice(5, 7)) {
      return 1;
    } else if (a.startTime.slice(5, 7) < b.startTime.slice(5, 7)) {
      return -1;
    }
    //DAY
    else if (a.startTime.slice(8, 10) > b.startTime.slice(8, 10)) {
      return 1;
    } else if (a.startTime.slice(8, 10) < b.startTime.slice(8, 10)) {
      return -1;
    }
    //HOUR
    else if (a.startTime.slice(11, 13) > b.startTime.slice(11, 13)) {
      return 1;
    } else if (a.startTime.slice(11, 13) < b.startTime.slice(11, 13)) {
      return -1;
    }
    return 0;
  });
  console.log("Sorted Array", sortedArr);

  return sortedArr;
};

export { sortingParkingList, sortingParkingListPrZipCode, sortingEventList };
