import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {format, parseISO, add, isBefore, sub} from "date-fns";
import da from "date-fns/locale/da";

import EventConfirmModal from "../RentPopUps/EventConfirmModal";
import RentConfirmModal from "../RentPopUps/RentConfirmModal";

import "../../Styles/Components/ParkingList/ParkingItem.scss";
import { useEffect, useState } from "react";
import {client} from "../Api/Client";


const ParkingItem = (props) => {
  const {spot, img, isEvent, eventStartTime, eventEndTime, key, eventName, price, eventId, isRented} = props;
  const [parkingSpotData, setParkingSpotData] = useState([])

  useEffect(()=>{
    if(isEvent)
    {
      try {
        client.get("ParkingSpot/GetParkingSpot",{ params:{parkingspotid: spot.parkingSpotId}}).then((res) => {
        console.log("Spot", res.data);
        setParkingSpotData(res.data)
        }); 
      } catch (error) {
        console.log(error);
      }

    }
    else
    {
      setParkingSpotData(spot)
    }
  },[isEvent])

  // console.log("ParkingItem - Consoling props  ", props);

  const handleEventDateTime = (start, end) => {
    let startTime = "";
    let endTime = "";

    if (start != null && start !== "") {
      const subtractionTime = sub(new Date(start), {hours: 1});
      startTime = format(subtractionTime, "dd. MMMM Y - HH:mm", {
        locale: da,
      });
    }

    if (end != null && end !== "") {
      const addTime = add(new Date(end), {minutes: 30});
      endTime = format(addTime, "dd. MMMM Y - HH:mm", {
        locale: da,
      });
    }

    return startTime + endTime;
  };

  const hoverDescription = (props) =>
    spot.description.length >= 50 ? (
      <Tooltip className="tooltip-inner" {...props}>
        {spot.description}
      </Tooltip>
    ) : (
      <Tooltip></Tooltip>
    );

  const handleRentPeriod = (start, end) => {
    console.log("Start", start)
    const startYear = format(parseISO(start), "Y") || null;
    const startMonth = format(parseISO(start), "MMMM", {locale: da}) || null;
    let endYear = format(parseISO(end), "Y") || null;
    let endMonth = format(parseISO(end), "MMMM", {locale: da}) || null;
    // Using these 2 variables to figure out whether a rentperiod is set to run unlimited or not
    const date = add(new Date(), {years: 4});
    const check = isBefore(parseISO(endYear), date) || true;
    
    let startDate = "";
    let endDate = "";

    if(check){
      endDate = " - " + endMonth + endYear;
    }
    
    if(new Date(start) <= Date.now()){
      startDate = "Dags dato"
    }
    else{
      startDate = startMonth + startYear
    }
    
    return startDate + endDate;
  };
  const handlePeriodTypeTitle = (type) => {
    switch (type) {
      case 0:
        return <p>Tider - Tilpasset tider</p>;
      case 1:
        return <p>Tider - Kontortider</p>;
      case 2:
        return <p>Tider - Udenfor kontortider</p>;
      case 3:
        return <p>Tider - 24/7</p>;
      default:
        break;
    }
  };
  const handlePeriodType = (type) => {
    switch (type) {
      case 1:
        return (
          <div className="hours paragraph">
            <div className="box1 bold">
              <p>Man-Fre:</p>
              <p>Lørdag:</p>
              <p>Søndag:</p>
            </div>
            <div className="box2">
              <p>07:30 - 16:30</p>
              <p>Ikke udlejet</p>
              <p>Ikke udlejet</p>
            </div>
          </div>
        );
      case 2:
        return (
          <div className="hours paragraph">
            <div className="box1 bold">
              <p>Man-Fre:</p>
              <p>Lørdag:</p>
              <p>Søndag:</p>
            </div>
            <div className="box2">
              <p>16:30 - 07:30</p>
              <p>Udlejes hele døgnet</p>
              <p>Udlejes hele døgnet</p>
            </div>
          </div>
        );
      case 3:
        return (
          <div className="hours paragraph">
            <div className="box1 bold">
              <p>Man-Fre:</p>
              <p>Lørdag:</p>
              <p>Søndag:</p>
            </div>
            <div className="box2">
              <p>Udlejes hele døgnet</p>
              <p>Udlejes hele døgnet</p>
              <p>Udlejes hele døgnet</p>
            </div>
          </div>
        );
      default:
        break;
    }
  };
  const handleParkingSpotHours = (hours) => {
    var rightHours = null;
    hours === null || typeof hours === "undefined"
      ? (rightHours = hours)
      : (rightHours = hours.slice(0, 5));
    return rightHours;
  };

  const eventParkingItemContainer = (
    <div className="eventParkingItemContainer" id={key}>
      {/* Image */}
      {/* //TODO Image preview on click  - New Modal component */}
      <div className="item1">
        <img src={img} alt="" />
      </div>
      {/* text + button */}
      <div className="item2">
        <div className="item2-1">
          <p className="address paragraphHeading">
            {parkingSpotData.address?.streetName} {parkingSpotData?.address?.streetNumber} -{" "}
            {parkingSpotData.address?.zipCodeDistrict?.city},{" "}
            {parkingSpotData.address?.zipCodeDistrict?.zipcode}
          </p>
          <p className="price paragraph">
            <span className="bold">Pris: </span>
            {price} DKK
          </p>
          <OverlayTrigger
            placement="bottom"
            delay={{show: 250, hide: 200}}
            overlay={hoverDescription}
          >
            <p className="description paragraph">
              <span className="bold">Beskrivelse: </span>
              {parkingSpotData?.description?.length <= 50
                ? parkingSpotData?.description
                : parkingSpotData?.description?.slice(0, 50) + "..."}
            </p>
          </OverlayTrigger>
        </div>
        <div className="item2-2">
          <p className="paragraphHeading hideHeading">Tider</p>
          <p className="start paragraph">
            <span className="bold">Start: </span>
            <br className="lineShift" />
            {isEvent? handleEventDateTime(eventStartTime, null):null}
          </p>
          <p className="end paragraph">
            <span className="bold">Slut: </span> <br className="lineShift" />
            {isEvent? handleEventDateTime(null, eventEndTime): null}
          </p>
          <EventConfirmModal
            pSpot={spot}
            img={img}
            eventStartTime={eventStartTime}
            eventEndTime={eventEndTime}
            eventName={eventName}
            eventPrice={price}
            eventId={eventId}
            className="btn"
            isRented={isRented}
          />
        </div>
      </div>
    </div>
  );

  const findParkingItemContainer = (
    <div className="findParkingItemContainer" id={spot?.parkingSpotId}>
      <div className="item1">
        <img src={img} alt="" />
      </div>
      <div className="item2">
        <div className="item2-1">
          <p className="address paragraphHeading">
            {spot?.address?.streetName} {spot?.address?.streetNumber} -{" "}
            {spot?.address?.zipCodeDistrict?.city},{" "}
            {spot?.address?.zipCodeDistrict?.zipcode}
          </p>
          <p className="lejeperiode paragraph">
            <span className="bold">Lejeperiode: </span>{" "}
            <br className="lineShift" />
            {!isEvent? handleRentPeriod(spot?.rentPeriodStart, spot?.rentPeriodEnd): null}
          </p>
          <p className="price paragraph">
            <span className="bold">Pris: </span>
            {spot.price} kr./md.
          </p>
          <OverlayTrigger
            placement="bottom"
            delay={{show: 250, hide: 200}}
            overlay={hoverDescription}
          >
            <p className="description paragraph">
              <span className="bold">Beskrivelse: </span>
              {spot?.description?.length <= 50
                ? spot?.description
                : spot?.description?.slice(0, 50) + "..."}
            </p>
          </OverlayTrigger>
        </div>
        <div className="item2-2">
          <p className="paragraphHeading hideHeading">
            {handlePeriodTypeTitle(spot?.monthlyRentPeriodType)}
          </p>
          {spot?.monthlyRentPeriodType === 0 ? (
            <div>
              <div className="hours paragraph">
                <div className="box1 bold">
                  <p>Man-Fre:</p>
                  <p>Lørdag:</p>
                  <p>Søndag:</p>
                </div>
                <div className="box2">
                  <p className="manToFre">
                    {handleParkingSpotHours(spot?.moFiStartTime) === null ? (
                      <div>
                        <p>Ikke udlejet</p>
                      </div>
                    ) : (
                      <div>
                        {handleParkingSpotHours(spot?.moFiStartTime)} -{" "}
                        {handleParkingSpotHours(spot?.moFiEndTime)}
                      </div>
                    )}
                  </p>
                  <p className="sat">
                    {handleParkingSpotHours(parkingSpotData?.satStart) === null ? (
                      <div>
                        <p>Ikke udlejet</p>
                      </div>
                    ) : (
                      <div>
                        {handleParkingSpotHours(spot?.satStart)} -{" "}
                        {handleParkingSpotHours(spot?.satEnd)}
                      </div>
                    )}
                  </p>
                  <p className="sun">
                    {handleParkingSpotHours(spot?.sunStart) === null ? (
                      <div>
                        <p>Ikke udlejet</p>
                      </div>
                    ) : (
                      <div>
                        {handleParkingSpotHours(spot?.sunStart)} -{" "}
                        {handleParkingSpotHours(spot?.sunEnd)}
                      </div>
                    )}
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <div>{handlePeriodType(spot?.monthlyRentPeriodType)}</div>
          )}
          <RentConfirmModal data={spot} img={img} className="btn" />
        </div>
      </div>
    </div>
  );
  const parkingItemContainer = isEvent
    ? eventParkingItemContainer
    : findParkingItemContainer;

  return <div className="parkingItem">{parkingItemContainer}</div>;
};

export default ParkingItem;
