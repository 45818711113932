//#region - Imports
// Libraries
import { format, parseISO, add, isBefore } from "date-fns";
import da from "date-fns/locale/da";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";

// Styling
import "../../Styles/Components/RentPopUps/RentConfirmCard.scss";
//#endregion

const RentConfirmCard = (props) => {
  const { spot, img } = props;

  //#region - Handlers
  const handleParkingSpotHours = (hours) => {
    var rightHours = null;
    hours === null || typeof hours === "undefined"
      ? (rightHours = hours)
      : (rightHours = hours.slice(0, 5));
    return rightHours;
  };

   const hoverParkingListText = (props) =>
     spot.description.length >= 55 ? (
       <Tooltip {...props}>{spot.description}</Tooltip>
     ) : (
       <Tooltip></Tooltip>
     );

  const handlePeriodTypeTitle = (type) => {
    switch (type) {
      case 0:
        return (
          <div>
            <p>Tider - Tilpasset tider</p>
          </div>
        );
      case 1:
        return (
          <div>
            <p>Tider - Kontortider</p>
          </div>
        );
      case 2:
        return (
          <div>
            <p>Tider - Udenfor kontortider</p>
          </div>
        );
      case 3:
        return (
          <div>
            <p>Tider - 24/7</p>
          </div>
        );
      default:
        break;
    }
  };
  const handlePeriodType = (type) => {
    switch (type) {
      case 1:
        return (
          <div className="hours paragraph">
            <div className="box1 bold">
              <p>Man-Fre:</p>
              <p>Lørdag:</p>
              <p>Søndag:</p>
            </div>
            <div className="box2">
              <p>07:30 - 16:30</p>
              <p>Ikke udlejet</p>
              <p>Ikke udlejet</p>
            </div>
          </div>
        );
      case 2:
        return (
          <div className="hours paragraph">
            <div className="box1 bold">
              <p>Man-Fre:</p>
              <p>Lørdag:</p>
              <p>Søndag:</p>
            </div>
            <div className="box2">
              <p>16:30 - 07:30</p>
              <p>Udlejes hele døgnet</p>
              <p>Udlejes hele døgnet</p>
            </div>
          </div>
        );
      case 3:
        return (
          <div className="hours paragraph">
            <div className="box1 bold">
              <p>Man-Fre:</p>
              <p>Lørdag:</p>
              <p>Søndag:</p>
            </div>
            <div className="box2">
              <p>Udlejes hele døgnet</p>
              <p>Udlejes hele døgnet</p>
              <p>Udlejes hele døgnet</p>
            </div>
          </div>
        );
      default:
        break;
    }
  };

  const handleRentPeriod = (start, end) => {
    const startYear = format(parseISO(start), "Y");
    const startMonth = format(parseISO(start), "MMMM", {locale: da});
    let endYear = format(parseISO(end), "Y");
    let endMonth = format(parseISO(end), "MMMM", {locale: da});
    // Using these 2 variables to figure out whether a rentperiod is set to run unlimited or not
    const date = add(new Date(), {years: 4});
    const check = isBefore(parseISO(endYear), date);

    let startDate = "";
    let endDate = "";

    if(check){
      endDate = " - " + endMonth + endYear;
    }

    if(new Date(start) <= Date.now()){
      startDate = "Dags dato"
    }
    else{
      startDate = startMonth + startYear
    }

    return startDate + endDate;
  };

  //#endregion

  //#region - Return
  const pSpotCard = (
    <div className="pSpotCardContainer" id={spot.parkingSpotId}>
      <div className="item1">
        <img src={img} alt="" />
      </div>
      <div className="item2">
        <div className="item2-1">
          <p className="address paragraphHeading">
            {spot.address.streetName} {spot.address.streetNumber} -{" "}
            {spot.address.zipCodeDistrict.city},{" "}
            {spot.address.zipCodeDistrict.zipcode}
          </p>
          <p className="lejeperiode paragraph">
            <span className="bold">Lejeperiode: </span>{" "}
            <br className="lineShift" />
            {handleRentPeriod(spot.rentPeriodStart, spot.rentPeriodEnd)}
          </p>
          <p className="price paragraph">
            <span className="bold">Pris: </span>
            {spot.price} kr./md.
          </p>
            <p className="other paragraph">
              <span className="bold">Beskrivelse: </span>
              {spot.description}
            </p>
        </div>
        <div className="item2-2">
          <p className="paragraphHeading hideHeading">
            {handlePeriodTypeTitle(spot.monthlyRentPeriodType)}
          </p>
          {spot.monthlyRentPeriodType === 0 ? (
            <div className="hours paragraph">
              <div className="box1 bold">
                <p>Man-Fre:</p>
                <p>Lørdag:</p>
                <p>Søndag:</p>
              </div>
              <div className="box2">
                <p className="manToFre">
                  {handleParkingSpotHours(spot.moFiStartTime) === null ? (
                    <div>
                      <p>Ikke udlejet</p>
                    </div>
                  ) : (
                    <div>
                      {handleParkingSpotHours(spot.moFiStartTime)} -{" "}
                      {handleParkingSpotHours(spot.moFiEndTime)}
                    </div>
                  )}
                </p>
                <p className="sat">
                  {handleParkingSpotHours(spot.satStart) === null ? (
                    <div>
                      <p>Ikke udlejet</p>
                    </div>
                  ) : (
                    <div>
                      {handleParkingSpotHours(spot.satStart)} -{" "}
                      {handleParkingSpotHours(spot.satEnd)}
                    </div>
                  )}
                </p>
                <p className="sun">
                  {handleParkingSpotHours(spot.sunStart) === null ? (
                    <div>
                      <p>Ikke udlejet</p>
                    </div>
                  ) : (
                    <div>
                      {handleParkingSpotHours(spot.sunStart)} -{" "}
                      {handleParkingSpotHours(spot.sunEnd)}
                    </div>
                  )}
                </p>
              </div>
            </div>
          ) : (
            <div className="monthlyRentPeriodTypeTime">
              {handlePeriodType(spot.monthlyRentPeriodType)}
            </div>
          )}
        </div>
      </div>
    </div>
  );

  return <div className="rentConfirmCard">{pSpotCard}</div>;

  //#endregion
};

export default RentConfirmCard;
