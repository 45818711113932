//#region - Imports
// Libraries
import {useState} from "react";
import {Form, Button, InputGroup} from "react-bootstrap";

// Styling
import "../Styles/Components/ContactForm.scss";

// Components
import FormModal from "./FormModal";
import {validateInfo} from "./Validation/InputFormValidation";
import {client} from "./Api/Client";
//#endregion

const ContactForm = () => {
  //#region - States
  const initialUserData = {
    fullName: "",
    email: "",
    phoneNumber: "",
    msg: "",
  };

  const [formData, setFormData] = useState(initialUserData);
  const [show, setShow] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  //#endregion

  //#region - Handlers
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleChange = (e) => {
    const updatedData = {...formData, [e.target.id]: e.target.value};
    setFormData(updatedData);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsValid(true);
    setLoading(true);
    handleShow();
    if (validate()) {
      try {
        client
          .post("Contact/Contact", {
            body: {
              fullName: formData.fullName,
              email: formData.email,
              phoneNumber: formData.phoneNumber,
              message: formData.msg,
            },
          })
          .then((res) => {
            console.log(res);
            setIsValid(true);
            setLoading(false);
            setFormData(initialUserData);
          });
      } catch (error) {
        setIsValid(false);
        setLoading(false);
      }
    } else {
      setIsValid(false);
      setLoading(false);
      handleClose();
    }
  };
  //#endregion

  //#region - Validation
  const validate = () => {
    let isValid = true;
    const validationObject = {
      fullName: formData.fullName,
      email: formData.email,
      phoneNumber: formData.phoneNumber ? formData.phoneNumber : null,
      msg: formData.msg,
    };
    let contactUsValidation = validateInfo(validationObject);
    if (Object.keys(contactUsValidation).length !== 0) {
      isValid = false;
    }
    setErrors(contactUsValidation);
    console.log(contactUsValidation);
    return isValid;
  };
  //#endregion

  //#region - Return
  return (
    <div className="contact-form">
      <Form onSubmit={handleSubmit} noValidate>
        <Form.Group controlId="fullName">
          <Form.Label className="formlabel">Navn</Form.Label>
          <Form.Control
            type="text"
            placeholder="Indtast dit navn"
            onChange={handleChange}
            value={formData.fullName}
            isInvalid={!!errors.fullName}
          />
          {errors.fullName ? (
            <Form.Control.Feedback type="invalid">
              {errors.fullName}
            </Form.Control.Feedback>
          ) : null}
        </Form.Group>
        <Form.Group controlId="email">
          <Form.Label className="formlabel">Email</Form.Label>
          <Form.Control
            type="email"
            placeholder="Indtast din email"
            onChange={handleChange}
            value={formData.email}
            isInvalid={!!errors.email}
          />
          {errors.email ? (
            <Form.Control.Feedback type="invalid">
              {errors.email}
            </Form.Control.Feedback>
          ) : null}
        </Form.Group>
        <Form.Group controlId="phoneNumber">
          <Form.Label className="formlabel">
            Telefonnummer <span className="formlabel-optional">(valgfri)</span>
          </Form.Label>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text>+45</InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              type="tel"
              placeholder="Indtast dit telefonnummer"
              onChange={handleChange}
              value={formData.phoneNumber}
              isInvalid={!!errors.phoneNumber}
              className={errors.phoneNumber ? "inputgroup-right-radius" : ""}
            />
            {errors.phoneNumber ? (
              <Form.Control.Feedback type="invalid">
                {errors.phoneNumber}
              </Form.Control.Feedback>
            ) : null}
          </InputGroup>
        </Form.Group>
        <Form.Group controlId="msg">
          <Form.Label className="formlabel">Besked</Form.Label>
          <Form.Control
            className="besked-box"
            as="textarea"
            rows="3"
            placeholder="Skriv besked her"
            onChange={handleChange}
            value={formData.msg}
            isInvalid={!!errors.msg}
          />
          {errors.msg ? (
            <Form.Control.Feedback type="invalid">
              {errors.msg}
            </Form.Control.Feedback>
          ) : null}
        </Form.Group>
        <Form.Group>
          <div className="regular-button">
            {loading ? (
              <Button disabled id="btn-submit" type="submit">
                Sender...
              </Button>
            ) : (
              <Button id="btn-submit" type="submit">
                Send
              </Button>
            )}
          </div>
        </Form.Group>
      </Form>
      <FormModal
        isValid={isValid}
        handleClose={handleClose}
        show={show}
        text="Din besked er nu sendt afsted. Vi kontakter dig i den nærmeste fremtid."
      />
    </div>
  );
  //#endregion
};

export default ContactForm;
