//#region - Imports
// Library
import { useRef } from "react";

// Styling
import "../Styles/Pages/ForCompanies.scss";

// Components
import CompaniesForm from "../Components/CompaniesForm";
import FAQ from "../Components/FAQ";
import Button from "react-bootstrap/Button";
import Meta from "../Components/Meta";
import { companiesFAQ } from "../Components/FAQWidgetDatas";
import { COOKIE_NAMES, getCookieValue } from "../Components/Tracking/Cookie";
import { initGA, pageView } from "../Components/Tracking/Tracking";

// Assets
import money from "../Assets/Forbusiness/white-coin-stacks-arrow-up.png";
import flexibility from "../Assets/Forbusiness/flexibility-white.png";
import city from "../Assets/Forbusiness/white-city-buildings.png";
import earth from "../Assets/Forbusiness/white-hands-holding-earth.png";
import event from "../Assets/Forbusiness/black-parking-concert-tickets.png";
import monthly from "../Assets/Forbusiness/black-parking-calendar.png";
import hourly from "../Assets/Forbusiness/black-parking-clock.png";
import dayAndNight from "../Assets/Forbusiness/247.png";
import businessHours from "../Assets/Forbusiness/inside-business.png";
import outsideBusinessHours from "../Assets/Forbusiness/outside-business.png";
import custom from "../Assets/Forbusiness/flexibility-black.png";
import plantsAndCoins from "../Assets/Compressed/plants-coins-house1.jpg";// compressed image
import cleaning from "../Assets/Forbusiness/cleaning-man-mop-bucket.png";
import lunch from "../Assets/Forbusiness/hand-platter-lunch.png";
import party from "../Assets/Forbusiness/champagne-glasses.png";
import coffee from "../Assets/Forbusiness/coffee-cup.png";
import sommerland from "../Assets/Forbusiness/carousel.png";
import map from "../Assets/Forbusiness/grey-denmark-map.svg";
import kenya from "../Assets/Compressed/raising-futures-kenya-charity.jpg";// compressed image
import nature from "../Assets/Compressed/cool-earth-charity.jpg";// compressed image
import elephants from "../Assets/Compressed/carbon-trust-charity.jpg";// compressed image
import trees from "../Assets/Compressed/antarctic-and-southern-ocean-coalition-charity.jpeg";// compressed image
//#endregion

const ForCompanies = () => {
  // Track pageview
  if (getCookieValue(COOKIE_NAMES.PERFORMANCE)) {
    initGA();
    pageView();
  }

  const scrollRef = useRef(null);
  const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop - 50);
  const executeScroll = () => scrollToRef(scrollRef);

  return (
    <div className="for-companies">
      <Meta
        title="For Virksomheder det vil udlej sin Parkering | ParkShare"
        description="Virksomheders parkering står ofte tom udenfor arbejdstid. Med ParkShare kan I udleje jeres parkeringspladser, når I ikke selv bruger dem og tjene flere penge."
      />
      {/* HERO SECTION */}
      <section className="top">
        <div className="bg">
          <div className="content">
            <div className="element1">
              <h1 className="pageHeading">
                Optimer din virksomheds parkeringsplads
              </h1>
              <p className="subHeading">
                Brug tiden hvor medarbejderne ikke er på arbejde eller bruger
                parkering, og opnå en <br></br>ekstra indtægt
              </p>
              <Button className="topButton" onClick={executeScroll}>
                Udlej parkering
              </Button>
            </div>
            <div className="element2">
              <iframe
                title="forBusinessVideo"
                src="https://www.youtube.com/embed/WT7hQrOVSZY"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                // allowFullScreen
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
      </section>
      <section className="mobileVideo">
        <iframe
          height="196px"
          width="350px"
          src="https://www.youtube.com/embed/WT7hQrOVSZY"
          title="frontpageVideo"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        ></iframe>
      </section>
      {/* HOW IT WORKS */}
      <section className="how-it-works spaceBelow">
        <div className="content">
          <h2 className="sectionHeading">Hvordan virker det?</h2>
          <div className="steps">
            <div className="step">
              <div>
                <h4>1</h4>
                <h3 className="paragraphHeading">Kontakt ParkShare </h3>
              </div>
              <p className="paragraph">
                Kontakt ParkShare over telefon eller vores kontaktformular
                længere nede på siden. Så besvarer vi gladeligt jeres spørgsmål,
                og hjælper jer i den videre proces.
              </p>
            </div>
            <div className="step">
              <div>
                <h4>2</h4>
                <h3 className="paragraphHeading">
                  Vælg hvilke pladser I vil udleje
                </h3>
              </div>
              <p className="paragraph">
                Udlej så mange, eller få, parkeringspladser som I vil. I kan
                have forskellig type og tider for hver parkeringsplads, eller
                have det ens for dem alle. Det er helt op til jer.
              </p>
            </div>
            <div className="step">
              <div>
                <h4>3</h4>
                <h3 className="paragraphHeading">
                  Vælg de tidsrum I vil udleje i
                </h3>
              </div>
              <p className="paragraph">
                De mest gængse tidsrum for virksomheder at udleje i, er på
                hverdage i tidsrummet 16:30 - 7:30, og i weekenderne er det
                døgnet rundt. I bestemmer selv, og kan også tilpasse tiderne.
              </p>
            </div>
            <div className="step">
              <div>
                <h4>4</h4>
                <h3 className="paragraphHeading">
                  ParkShare klarer resten, og jeres indkomst stiger
                </h3>
              </div>
              <p className="paragraph">
                Når I er oprettet, er der ikke mere I behøver at gøre. ParkShare
                tager sig af det resterende arbejde, og I kan blot nyde jeres
                ekstra indkomst.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* BENEFITS */}
      <section className="benefits">
        <div className="content spaceAbove spaceBelow">
          <h2 className="sectionHeading">
            Fordele ved at udleje jeres parkeringsplads
          </h2>
          <div className="flex-container spaceBelow">
            <div className="column">
              <div className="benefit">
                <img
                  src={money}
                  alt="Grafik af tre stakker mønter og en pil der pejer op"
                />
                <div>
                  <h3 className="paragraphHeading">Ekstra indkomst</h3>
                  <p className="paragraph">
                    Mange virksomheder har ofte en eller flere
                    parkeringspladser, der står tomme om aftenen, natten og i
                    weekenden. Istedet kan parkeringspladserne udnyttes
                    optimalt, og give jer en ekstra indkomst.
                  </p>
                </div>
              </div>
              <div className="benefit">
                <img
                  src={flexibility}
                  alt="Grafik af Hvidt ur med tiden der går"
                />
                <div>
                  <h3 className="paragraphHeading">Fleksibilitet</h3>
                  <p className="paragraph">
                    I har fuld kontrol over de parkeringspladser I udlejer. I
                    kan selv bestemme hvilke dage og tidsrum, I vil udleje dem
                    i. Hvis jeres situation ændrer sig, kan I også ændre jeres
                    udlejningstider, så de fortsat passer til jer.
                  </p>
                </div>
              </div>
            </div>
            <span></span>
            <div className="column">
              <div className="benefit">
                <img src={city} alt="Grafik af Hvid høj bygning i byen" />
                <div>
                  <h3 className="paragraphHeading">
                    Skaber flere parkeringspladser
                  </h3>
                  <p className="paragraph">
                    Byer er ofte overfyldte og vanskelige for bilister, når det
                    kommer til parkering. Det skaber mere trafik og farlige
                    situationer. Jeres parkering kan bidrage til et større udbud
                    af parkeringspladser og en bedre by.
                  </p>
                </div>
              </div>
              <div className="benefit">
                <img src={earth} alt="Grafik af Hvid hånd der holder jorden" />
                <div>
                  <h3 className="paragraphHeading">
                    Reducer CO<sub>2</sub> og forurening
                  </h3>
                  <p className="paragraph">
                    Når bilister kan reservere en parkeringsplads på forhånd,
                    mindsker det kørsel og tidsspilde, ved at cirkulere rundt og
                    lede efter parkering. Deleparkering hjælper klimaet og gør
                    en stor forskel for miljøet.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* TYPES OF RENTING */}
      <section className="parking-types">
        <div className="content">
          <h2 className="sectionHeading">
            Hvilken type parkering kan man udleje?
          </h2>
          <div className="types-container">
            <div className="type">
              <img src={monthly} alt="Stort sort P og en kalender" />
              <p className="iconHeading">Månedsparkering</p>
              <p className="paragraph">
                Månedsparkering er en fast parkeringsplads, der udlejes en måned
                af gangen. Den kan have en slutdato eller være ubegrænset, og
                med forskellige tidstyper og intervaller.
              </p>
            </div>
            <div className="type">
              <img
                src={event}
                alt="Stort sort P og koncert billetter med musik node"
              />
              <p className="iconHeading">Eventparkering</p>
              <p className="paragraph">
                Eventparkering er til events f.eks. fodboldkampe, koncerter,
                løb, etc. Den har højere timepris, og er ofte 1 time før og
                efter eventet.
                <span style={{ display: "none" }}>
                  I kan udlej kun til events, men også timeparkering.
                </span>
              </p>
            </div>
            <div className="type coming-soon-type">
              <h3 className="coming-soon">Kommer snart!</h3>
              <div className="bookmark">
                <div className="rectangle"></div>
                <div className="triangle"></div>
              </div>
              <img src={hourly} alt="Stort sort P og et ur" />
              <p className="iconHeading">Timeparkering</p>
              <p className="paragraph">
                Timeparkering er udlejning til bilister der ønsker parkering for
                en kortere periode, f.eks. til ærinder, møder eller indkøbsture.
                I kan selv fastsætte de dage og tidspunkter som I ønsker.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* MONTHLY PARKING */}
      <section className="monthly spaceBelow">
        <div className="content">
          <div className="intro">
            <h2 className="sectionHeading">
              Standard tidsrum for månedsparkering
            </h2>
            <p className="paragraph">
              Når I udlejer en parkeringsplads som månedsparkering, har I også
              en fast bilist som lejer. ParkShare har fastsat nogle standarder
              for månedsparkering, som vi ved der er størst efterspørgsel på. I
              kan også tilpasse jeres udlejningsperioder, så den passer til
              jeres behov.
            </p>
          </div>
          <div className="types-container">
            <div className="types">
              <div className="type">
                <img
                  src={dayAndNight}
                  alt="Grafik af sort 24/7 parkering logo"
                />
                <div className="details">
                  <h3 className="paragraphHeading">24/7 parkering</h3>
                  <div className="hours">
                    <p className="paragraph">Man-Fre:</p>
                    <p className="paragraph">Udlejet hele døgnet</p>
                  </div>
                  <div className="hours">
                    <p className="paragraph">Lørdag:</p>
                    <p className="paragraph">Udlejet hele døgnet</p>
                  </div>
                  <div className="hours">
                    <p className="paragraph">Søndag:</p>
                    <p className="paragraph">Udlejet hele døgnet</p>
                  </div>
                </div>
              </div>
              <div className="type">
                <img
                  src={businessHours}
                  alt="Grafik af medarbejder som sidder ved skrivebord"
                />
                <div className="details">
                  <h3 className="paragraphHeading">Kontortider</h3>
                  <div className="hours">
                    <p className="paragraph">Man-Fre:</p>
                    <p className="paragraph">7:30 - 16:30</p>
                  </div>
                  <div className="hours">
                    <p className="paragraph">Lørdag:</p>
                    <p className="paragraph">Ikke udlejet</p>
                  </div>
                  <div className="hours">
                    <p className="paragraph">Søndag:</p>
                    <p className="paragraph">Ikke udlejet</p>
                  </div>
                </div>
              </div>
              <div className="type">
                <img
                  src={outsideBusinessHours}
                  alt="Grafik af glad medarbejder som forlader kontor"
                />
                <div className="details">
                  <h3 className="paragraphHeading">Udenfor kontortider</h3>
                  <div className="hours">
                    <p className="paragraph">Man-Fre:</p>
                    <p className="paragraph">16:30 - 7:30</p>
                  </div>
                  <div className="hours">
                    <p className="paragraph">Lørdag:</p>
                    <p className="paragraph">Udlejet hele døgnet</p>
                  </div>
                  <div className="hours">
                    <p className="paragraph">Søndag:</p>
                    <p className="paragraph">Udlejet hele døgnet</p>
                  </div>
                </div>
              </div>
              <div className="type">
                <img src={custom} alt="Grafik af Et sort ur der går" />
                <div className="details">
                  <h3 className="paragraphHeading">Lav jeres egen</h3>
                  <p className="paragraph">
                    Tilpas selv hvilke tider og dage jeres parkeringsplads skal
                    udlejes i
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* HOW MUCH CAN YOU EARN */}
      <section className="earnings">
        <div className="content">
          <div className="earnings-image">
            <img
              src={plantsAndCoins}
              alt="Papirhus og stakker af mønter med planter"
            />
            <div className="overlay"></div>
          </div>
          <div className="earnings-details spaceBelow">
            <h2 className="sectionHeading">Hvor meget kan man tjene?</h2>
            <div className="earningsText">
              <p className="paragraph">
                Indtægterne fra parkering varierer efter hvor mange I udlejer,
                typen I udlejer, hvor ofte og hvor i landet I udlejer.
                Månedsparkering giver en fast indtægt og fast lejer.
                Timeparkering kan give en højere indtægt, men med større
                usikkerhed om hvor ofte den lejes.
              </p>
              <h3 className="paragraphHeading moreSpace">Månedsparkering</h3>
              <p className="paragraph">
                Indtægterne fra parkering varierer efter antal
                parkeringspladser, tidsrum og hvor i landet I udlejer." Udlejes
                en parkering på månedsbasis 24/7 i Aarhus C, vil det give en
                månedlig indtægt på 800-1.200 kr. alt efter placering, og ca.
                12.000 kr. årligt per parkeringsplads.
              </p>
              <h3 className="paragraphHeading">Timeparkering</h3>
              <p className="paragraph">
                Ligger jeres virksomhed eksempelvis i Aarhus C, og I udlejer en
                parkeringsplads, i de timer den ofte ikke bruges (16.30-7.30 i
                hverdagene og døgnet i weekenderne), vil I potentielt kunne
                tjene over 40.000 kr. årligt. per parkeringsplads. Hvis I
                udlejer på andre tidspunkter eller ferier, vil I kunne tjene
                endnu mere.
              </p>
              <h3 className="paragraphHeading">Eventparkering</h3>
              <p className="paragraph lastParagraph">
                Er jeres virksomhed eksempelvis i Aarhus, omkring Ceres Park og
                Tivoli Friheden, vil I kunne tjene fra 41 kr. per
                parkeringsplads til et event. Årligt vil det give ca. 3.000 kr.
                per parkeringsplads. Der er flest events om sommeren, og I
                vælger selv hvilke events I ønsker at udleje til.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* FOR YOUR EMPLOYEES */}
      <section className="for-employees">
        <div className="content spaceAbove spaceBelow">
          <h2 className="sectionHeading">
            Hvilken værdi 40.000 kr. kan give til medarbejdere
          </h2>
          <div className="items">
            <div className="item">
              <img
                src={cleaning}
                alt="Rengøringsmand der holder en spand og moppe"
              />
              <p className="iconParagraph">10 timers rengøring hver uge</p>
            </div>
            <div className="item">
              <img src={lunch} alt="En hånd der holder et fad med mad" />
              <p className="iconParagraph">800 frokoster til medarbejdere</p>
            </div>
            <div className="item">
              <img src={party} alt="2 champagneglas med champagne" />
              <p className="iconParagraph">Firmafest for 75 medarbejdere</p>
            </div>
            <div className="item">
              <img src={coffee} alt="En kop med varm kaffe" />
              <p className="iconParagraph">20.000 kopper kaffe</p>
            </div>
            <div className="item">
              <img src={sommerland} alt="En karrusel" />
              <p className="iconParagraph">
                160 entrebilletter til Djurs Sommerland
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* WHO SETS THE PRICE */}
      <section className="who-sets spaceBelow">
        <div className="content">
          <h2 className="sectionHeading">Hvem sætter prisen for parkering?</h2>
          <div className="grid">
            <div className="who box">
              <h3 className="paragraphHeading">Hvem</h3>
              <p className="paragraph">
                Prisen for parkering er fastsat af ParkShare.
              </p>
            </div>
            <div className="how box">
              <h3 className="paragraphHeading">Hvordan</h3>
              <p className="paragraph">
                Priserne for parkering er fastsat, og afhænger af flere ting.
                Det afhænger af typen for parkeringen (Månedsparkering,
                Eventparkering eller Timeparkering), samt tidspunkt på dagen.
                Derudover sætter ParkShare prisen efter hvilken by, området i
                byen og hvad andre parkeringspriser er, idet pågældende område.{" "}
              </p>
            </div>
            <div className="why box">
              <h3 className="paragraphHeading">Hvorfor</h3>
              <p className="paragraph">
                ParkShare fastsætter prisen, sådan at I som udlejer ikke behøver
                bekymre jer om, hvad jeres naboer tager, om I sætter prisen for
                højt eller lavt, eller hvad det ellers koster - det arbejde har
                ParkShare allerede lavet for jer, og vi gør hvad vi kan for at
                sikre udlejer bedst mulig pris, og samtidig flest muligt lejere.
                Samtidig gør det også at bilisterne får en fair pris, således de
                ved hvad de kan forvente og får en bedre oplevelse.
              </p>
            </div>
          </div>
          <img src={map} alt="Grå kort af Danmark" />
        </div>
      </section>
      {/* NON-PROFITS */}
      <section className="non-profits">
        <div className="content spaceAbove spaceBelow">
          <div className="description">
            <h2 className="sectionHeading">Er I en nonprofitorganisation?</h2>
            <p className="paragraph">
              Hvis jeres virksomhed ikke må have en direkte indtægt på udlejning
              af jeres parkeringsplader, kan I sagtens stadig udleje jeres
              parkeringspladser. Det kan lade sig gøre ved at jeres indtægt, i
              stedet doneres til et velgørende formål. På den måde kan I både
              hjælpe mange bilister, skabe bedre og grønnere byer, samtidig med
              at I kan støtte et godt formål. I kan enten selv donere jeres
              indtægt til en velgørenhedsorganisation, eller vi kan gøre det for
              jer.
            </p>
            <span></span>
            <p className="subHeading">Velgørenhedsorganisationer vi støtter</p>
            <p className="paragraph">
              Ønsker I, at vi donerer din indtægt til et velgørende formål, så
              gør vi meget gerne det. Hos ParkShare støtter vi nedenstående
              velgørenhedsorganisationer, og vi garanterer at hele din indtægt
              går til organisationen og gør en forskel.
            </p>
          </div>
          <div className="charities spaceBelow">
            <div className="charity">
              <img
                src={kenya}
                alt="Kenya Girls"
                className="Smilende børn der arbejder i Kenya"
              />
              <div>
                <span>
                  <h3 className="paragraphHeading">Raising Futures Kenya</h3>
                  <p className="iconParagraph">
                    Lærer udsatte børn og unge i Kenya forskellige kompetencer,
                    for at skabe jobs og muligheder for at komme ud af
                    fattigdom.
                  </p>
                </span>
                <a
                  href="https://www.raisingfutureskenya.org.uk/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <button className="paragraph">Læs mere</button>
                </a>
              </div>
            </div>
            <div className="charity">
              <img src={nature} alt="Dansk landskab med skov og hav" />
              <div>
                <span>
                  <h3 className="paragraphHeading">Den Danske Naturfond</h3>
                  <p className="iconParagraph">
                    Sikre og forbedre naturen i Danmark, ved at skabe flere
                    områder til vilde dyr og planter, samt forbedre vandmiljøet.
                  </p>
                </span>
                <a
                  href="https://naturfonden.dk/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <button className="paragraph">Læs mere</button>
                </a>
              </div>
            </div>
            <div className="charity">
              <img
                src={trees}
                alt="Rækker med træer som står på en græsplæne"
              />
              <div>
                <span>
                  <h3 className="paragraphHeading">Plant et Træ</h3>
                  <p className="iconParagraph">
                    Planter træer og sikre bedre natur i Danmark. Plant et Træ
                    planter også bytræer, som sikrer grønnere byer med bedre
                    luft.
                  </p>
                </span>
                <a
                  href="https://plant-et-trae.dk/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <button className="paragraph">Læs mere</button>
                </a>
              </div>
            </div>
            <div className="charity">
              <img
                src={elephants}
                alt="Elephants"
                className="Vilde elefanter i naturen i Kenya"
              />
              <div>
                <span>
                  <h3 className="paragraphHeading">Karen Blixen Camp Trust</h3>
                  <p className="iconParagraph">
                    Støtter bevarelsen af naturen og dyrelivet, med særligt
                    fokus på vilde elefanter, i Masai Mara og Kenya.
                  </p>
                </span>
                <a
                  href="https://www.karenblixencamptrust.org/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <button className="paragraph">Læs mere</button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* CONTACT FORM */}
      <section className="rent-out" ref={scrollRef}>
        <div className="content">
          <div className="description">
            <h2 className="sectionHeading">Udlej jeres parkeringsplads(er)</h2>
            <p className="paragraph">
              Hvis I ønsker at udleje jeres virksomheds parkeringspladser, ring
              til os på
            </p>
            <p className="pink">+45 42 72 99 88</p>
            <p className="paragraph">
              eller udfyld nedenstående formular, så kontakter vi jer snarest
            </p>
          </div>
          <CompaniesForm />
        </div>
      </section>
      {/* FAQ */}
      <section className="faq spaceBelow">
        <div className="content">
          <h2 className="sectionHeading">Ofte stillede spørgsmål</h2>
          <FAQ tabs={companiesFAQ} />
        </div>
      </section>
    </div>
  );
};
export default ForCompanies;
