import moment from "moment";
import XRegExp from "xregexp";
import { zipCodes } from "./ZipCodes";

export const validateInfo = (values) => {
  const errors = {};
  console.log("VALUUESSSS", values);

  // https://regex101.com/r/opYU0j/1
  // https://regex101.com/r/7GxnRB/1 - https://stackoverflow.com/questions/50178498/no-pl-for-javascript-regex-use-unicode-in-js-regex -
  const namePattern = XRegExp("^[\\pL ]+(?:[-' ][\\pL]+)*$");

  // https://regex101.com/r/awcgXM/2
  const emailPattern = XRegExp(
    "(?:[\\pL0-9_-]+)@(?:[\\pL0-9](?:[\\pL0-9-]*[\\pL0-9])?\\.)+[\\pL0-9](?:[\\pL0-9-]*[\\pL0-9])?"
  );

  const digitPattern = new RegExp(/\d/);
  const cityPattern = new RegExp(/^[a-zA-Z\u00c0-\u017e]+(?:[\s-][a-zA-Z]+)*$/);
  const streetNumberPattern = new RegExp(/[a-zA-Z0-9,-]/);
  const integerPattern = new RegExp(/^\d+$/);

  // FullName
  if (typeof values.fullName === "string") {
    if (values.fullName.trim() === "") {
      errors.fullName = "Inputfeltet må ikke være tomt.";
    } else if (digitPattern.test(values.fullName)) {
      errors.fullName = "Inputfeltet må ikke bestå af numeriske cifre";
    } else if (!namePattern.test(values.fullName)) {
      errors.fullName = "Inputfeltet må ikke indeholde specialtegn.";
    }
  }

  // FirstName
  if (typeof values.firstName === "string") {
    if (values.firstName.trim() === "") {
      errors.firstName = "Inputfeltet må ikke være tomt.";
    } else if (digitPattern.test(values.firstName)) {
      errors.firstName = "Inputfeltet må ikke bestå af numeriske cifre";
    } else if (!namePattern.test(values.firstName)) {
      errors.firstName = "Inputfeltet må ikke indeholde specialtegn.";
    }
  }

  // LastName
  if (typeof values.lastName === "string") {
    if (values.lastName.trim() === "") {
      errors.lastName = "Inputfeltet må ikke være tomt.";
    } else if (digitPattern.test(values.lastName)) {
      errors.lastName = "Inputfeltet må ikke bestå af numeriske cifre";
    } else if (!namePattern.test(values.lastName)) {
      errors.lastName = "Inputfeltet må ikke indeholde specialtegn.";
    }
  }

  // Email
  if (typeof values.email === "string") {
    if (values.email.trim() === "") {
      errors.email = "Inputfeltet må ikke være tomt.";
    } else if (!emailPattern.test(values.email)) {
      errors.email =
        "Ugyldigt email. En gyldig email-adresse kan f.eks. se således ud: 'xxxxxxxx@hotmail.com'.";
    }
  }

  // PhoneNumber
  if (typeof values.phoneNumber === "string") {
    if (values.phoneNumber.length !== 8) {
      errors.phoneNumber = "Telefonnummeret skal bestå af 8 cifre.";
    } else if (isNaN(values.phoneNumber) === true) {
      errors.phoneNumber = "Telefonnummeret må kun bestå af numeriske cifre.";
    }
  }
  // Address
  if (typeof values.address === "string") {
    if (values.address.trim() === "") {
      errors.address = "Inputfeltet må ikke være tomt.";
    } else if (!digitPattern.test(values.address)) {
      errors.address =
        "Hov, det ser ud til du har glemt at inkludere husnummer med i inputfeltet";
    }
  }

  // ZipCode
  if (typeof values.zipCode === "string") {
    if (values.zipCode.length !== 4) {
      errors.zipCode = "Postnummeret skal bestå af 4 cifre.";
    } else if (isNaN(values.zipCode) === true) {
      errors.zipCode = "Inputfeltet må kun bestå af 4 numeriske cifre.";
    } else if (!zipCodes.includes(Number(values.zipCode))) {
      errors.zipCode = "Postnummeret findes ikke i Danmark. Prøv igen.";
    }
  }
  // City
  if (typeof values.city === "string") {
    if (values.city.trim() === "") {
      errors.city = "Inputfeltet må ikke være tomt.";
    } else if (!cityPattern.test(values.city) || values.city.length < 2) {
      errors.city = "Bynavnet er ikke accepteret.";
    }
  }
  // Number of Pspots
  if (typeof values.numberOfPspots === "string") {
    if (values.numberOfPspots.trim() === "") {
      errors.numberOfPspots = "Inputfeltet må ikke være tomt.";
    } else if (isNaN(values.numberOfPspots) === true) {
      errors.numberOfPspots = "Inputfeltet må kun bestå af numeriske cifre.";
    } else if (values.numberOfPspots === "0") {
      errors.numberOfPspots = "Du skal som minimum udleje 1 parkeringsplads.";
    } else if (!integerPattern.test(values.numberOfPspots)) {
      errors.numberOfPspots = "Inputfeltet må kun bestå af et heltal ciffer.";
    }
  }

  // Msg
  if (typeof values.msg === "string") {
    if (values.msg.trim() === "") {
      errors.msg = "Inputfeltet må ikke være tomt.";
    }
  }

  // StreetName
  if (typeof values.streetName === "string") {
    if (values.streetName.trim() === "") {
      errors.streetName = "Inputfeltet må ikke være tomt.";
    } else if (digitPattern.test(values.streetName)) {
      errors.streetName = "Inputfeltet må ikke bestå af numeriske cifre";
    }
  }
  if (typeof values.streetNumber === "string") {
    if (!streetNumberPattern.test(values.streetNumber)) {
      errors.streetNumber = "Vejnummer ikke accepteret.";
    }
  }
  // EventPlace
  if (typeof values.eventPlace === "string") {
    if (values.eventPlace.trim() === "") {
      errors.eventPlace = "Vælg stedet hvor eventet afholdes.";
    }
  }
  // EventName
  if (typeof values.eventName === "string") {
    if (values.eventName.trim() === "") {
      errors.eventName = "Inputfeltet må ikke være tomt.";
    }
  }
  // EventDate
  if (typeof values.eventDate === "string") {
    if (values.eventDate.trim() === "") {
      errors.eventDate = "Inputfeltet må ikke være tomt.";
    } else if (values.eventDate < moment().format("YYYY-MM-DD")) {
      errors.eventDate =
        "Datoen for eventet må ikke være ældre end nuværende dato.";
    }
  }
  // Do you want us to another events - City
  if (typeof values.eventFormCity === "string") {
    if (values.eventFormCity.trim() === "") {
      errors.eventFormCity = "Inputfeltet må ikke være tomt.";
    } else if (digitPattern.test(values.eventFormCity)) {
      errors.eventFormCity = "Inputfeltet må ikke bestå af numeriske cifre";
    } else if (values.eventFormCity.length < 2) {
      errors.eventFormCity = "Bynavnet er ikke accepteret.";
    }
  }
  // Do you want us to another events - EventName
  if (typeof values.eventFormEvent === "string") {
    if (values.eventFormEvent.trim() === "") {
      errors.eventFormEvent = "Inputfeltet må ikke være tomt.";
    }
  }
  // Radius
  if (typeof values.radius === "string") {
    if (values.radius.trim() === "") {
      errors.radius =
        "Vælg en radius for hvor langt væk parkeringspladsen må være";
    }
  }
  // MonthlyType
  if (typeof values.monthlyType === "string") {
    if (values.monthlyType.trim() === "") {
      errors.monthlyType =
        "Vælg et tidsrum for hvornår du ønsker månedsparkering";
    }
  }

  // Startdato
  if (typeof values.startDato === "string") {
    const split = values.startDato.split("-");
    const year = split[0];
    if (values.startDato.trim() === "") {
      errors.startDato = "Inputfeltet må ikke være tomt.";
    } else if (year.length !== 4) {
      errors.startDato =
        "Forkert format - benyt venligst formatet 'DD-MM-YYYY'";
    } else if (!year.match(/20/g)) {
      errors.startDato = "Startdato må ikke være senere end år 2099.";
    } else if (values.startDato < moment().format("YYYY-MM-DD")) {
      errors.startDato = "Startdato må ikke være ældre end nuværende dato.";
    }
  }
  // Slutdato
  var endDato = moment(values.endDato);
  var duration = moment.duration(endDato.diff(values.startDato));

  if (typeof values.endDato === "string") {
    const split = values.endDato.split("-");
    const year = split[0];
    if (duration.asYears() > 5) {
      errors.endDato =
        "Der må maximum være 5 års afstand mellem startdato og slutdato.";
    } else if (year.length !== 4) {
      errors.endDato = "Forkert format - benyt venligst formatet 'DD-MM-YYYY'.";
    } else if (!year.match(/20/g)) {
      errors.endDato = "Slutdato må ikke være senere end år 2099.";
    } else if (new Date(values.startDato) > new Date(values.endDato)) {
      errors.startDato = "Startdato må ikke være senere end slutdato.";
    } else if (values.rentPeriodType === true) {
      if (duration.asDays() === 0) {
        errors.endDato = "Datoerne må ikke være identitiske.";
      }
    }
  }

  // onCLick validation
  if (values.btnClick === false) {
    errors.btnClickMsg = "Tidstype er ikke valgt";
  }
  
  // Custom timeslots valdiation
  if (values.timeType === 0) {
    if (values.manFre === 1) {
      if (
        values.moFiStartTime.trim() === "" ||
        values.moFiEndTime.trim() === ""
      ) {
        errors.manFre = "Err";
      }
    }
    if (values.saturday === 1) {
      if (values.satStart.trim() === "" || values.satEnd.trim() === "") {
        errors.sat = "Err";
      }
    }
    if (values.sunday === 1) {
      if (values.sunStart.trim() === "" || values.sunEnd.trim() === "") {
        errors.sun = "Err";
      }
    }
  }

  // Checkboxes
  if (values.terms === false) {
    errors.terms = "Husk at servicevilkår";
  }
  if (values.rightOfDisposal === false) {
    errors.rightOfDisposal =
      "Husk at acceptere, at du har råderetten over parkingspladsen";
  }
  console.log("ERRRORSSSSSSS", errors);
  return errors;
};


