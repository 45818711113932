// Libraries
import {useState} from "react";
import Button from "react-bootstrap/Button";
import {Form, Col, InputGroup} from "react-bootstrap";
import {format, addYears} from "date-fns";

// Styling
import "../../Styles/Components/Waitinglist/MonthlyWaitinglist.scss";

// Assets
import InfoBox from "../InfoBox2";
import FormModal from "../FormModal";
import {validateInfo} from "../Validation/InputFormValidation";
import {client} from "../Api/Client";

const MonthlyWaitinglist = () => {

  const initialUserData = {
    fullName: "",
    email: "",
    phoneNumber: "",
    streetName: "",
    streetNumber: "",
    zipCode: "",
    startDato: "",
    endDato: "",
    monthlyType: "",
    timeType: 0,
    msg: "",
    radius: "",
    currentDate: "",
  };

  const [formData, setFormData] = useState(initialUserData);
  const [zipCodeCheck, setZipCodeMsg] = useState("");
  const [show, setShow] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleChange = (e) => {
    const updatedData = {...formData, [e.target.id]: e.target.value};
    setFormData(updatedData);
  };

  const infoBoxText1 =
    "Ved at skrive vejnummer, kan vi mere præcist finde en parkeringsplads, der matcher dit ønske.";
  const infoBoxText2 =
    "24/7 er hele døgnet alle dage." +
    "\r\n" +
    "Kontortider er man-fre 7:30-16:30." +
    "\r\n" +
    "Udenfor kontortider er man-fre 16:30-7:30 og i hele weekenden.";
  const infoBoxText3 =
    "Hvis du ønsker at leje en månedsparkering ubegrænset, dvs. uden slutdato, kan du lade dette felt være tomt.";

  const handleMonthlyTypeChange = (e) => {
    console.log("You have chosen: ", e.target.value);
    switch (e.target.value) {
      case "Kontortider":
        setFormData({...formData, timeType: 1, monthlyType: e.target.value});
        break;
      case "Udenfor kontortider":
        setFormData({...formData, timeType: 2, monthlyType: e.target.value});
        break;
      case "24/7 parkering":
        setFormData({...formData, timeType: 3, monthlyType: e.target.value});
        break;
      default:
        break;
    }
  };

  const validate = () => {
    let isValid = true;
    const validationObject = {
      fullName: formData.fullName,
      email: formData.email,
      phoneNumber: formData.phoneNumber ? formData.phoneNumber : null,
      zipCode: formData.zipCode,
      streetName: formData.streetName,
      streetNumber: formData.streetNumber ? formData.streetNumber : null,
      radius: formData.radius,
      monthlyType: formData.monthlyType,
      startDato: formData.startDato,
      endDato: formData.endDato ? formData.endDato : null,
      msg: formData.msg ? formData.msg : null,
    };

    const validation = validateInfo(validationObject);

    console.log("Zipcode error   ", validation);
    if (Object.keys(validation).length !== 0) {
      isValid = false;
    }

    setErrors(validation);
    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    handleShow();

    if (validate()) {
      setIsValid(true);
      client
        .post("Contact/MonthlyWaitingList", {
          body: {
            fullName: formData.fullName,
            email: formData.email,
            phoneNumber: formData.phoneNumber,
            pSpotStreetName: formData.streetName,
            pSpotStreetNumber: formData.streetNumber,
            zipCode: formData.zipCode,
            radius: formData.radius,
            rentPeriodStart: formData.startDato,
            rentPeriodEnd: formData.endDato,
            monthlyRentPeriodType: formData.timeType,
            message: formData.msg,
          },
        })
        .then(
          (res) => {
            console.log("RESPONSE FROM REQUEST", res);

            setLoading(false);
            setFormData(initialUserData);
          },
          (error) => {
            console.log(error.response.data);
          }
        )
        .catch((e) => {
          setIsValid(false);
          setLoading(false);
        });
    } else {
      setIsValid(false);
      setLoading(false);
      handleClose();
    }
  };

  return (
    <div className="waitingList">
      <Form onSubmit={handleSubmit} noValidate>
        <Form.Row>
          <Form.Group as={Col} controlId="fullName">
            <Form.Label className="formlabel">Navn</Form.Label>
            <Form.Control
              type="text"
              placeholder="Indtast dit navn"
              onChange={handleChange}
              value={formData.fullName}
              isInvalid={!!errors.fullName}
            />
            {errors.fullName ? (
              <Form.Control.Feedback type="invalid">
                {errors.fullName}
              </Form.Control.Feedback>
            ) : null}
          </Form.Group>
          <Form.Group as={Col} controlId="email">
            <Form.Label className="formlabel">Email</Form.Label>
            <Form.Control
              type="email"
              placeholder="Indtast din email"
              onChange={handleChange}
              value={formData.email}
              isInvalid={!!errors.email}
            />
            {errors.email ? (
              <Form.Control.Feedback type="invalid">
                {errors.email}
              </Form.Control.Feedback>
            ) : null}
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} controlId="phoneNumber">
            <Form.Label className="formlabel">
              Telefonnummer{" "}
              <span className="formlabel-optional">(valgfri)</span>
            </Form.Label>
            <InputGroup hasValidation>
              <InputGroup.Prepend>
                <InputGroup.Text id="inputGroupPrepend">+45</InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                type="tel"
                placeholder="Indtast dit telefonnummer"
                aria-describedby="inputGroupPrepend"
                required
                onChange={handleChange}
                value={formData.phoneNumber}
                isInvalid={!!errors.phoneNumber}
                className={errors.phoneNumber ? "inputgroup-right-radius" : ""}
              />
              {errors.phoneNumber ? (
                <Form.Control.Feedback type="invalid">
                  {errors.phoneNumber}
                </Form.Control.Feedback>
              ) : null}
            </InputGroup>
          </Form.Group>
          <Form.Group as={Col} controlId="zipCode">
            <Form.Label className="formlabel">Postnummer</Form.Label>
            <Form.Control
              type="text"
              onChange={handleChange}
              placeholder="Indtast dit postnummer"
              value={formData.zipCode}
              isInvalid={!!errors.zipCode}
            />
            {errors.zipCode ? (
              <Form.Control.Feedback type="invalid">
                {errors.zipCode}
              </Form.Control.Feedback>
            ) : null}
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} controlId="streetName">
            <Form.Label className="formlabel">Vejnavn</Form.Label>
            <Form.Control
              placeholder="Indtast dit vejnavn"
              required
              type="text"
              onChange={handleChange}
              value={formData.streetName}
              isInvalid={!!errors.streetName}
            />
            {errors.streetName ? (
              <Form.Control.Feedback type="invalid">
                {errors.streetName}
              </Form.Control.Feedback>
            ) : null}
          </Form.Group>
          <Form.Group as={Col} controlId="streetNumber">
            <Form.Label className="formlabel">
              Vejnummer <span className="formlabel-optional">(valgfri)</span>
            </Form.Label>
            <InfoBox placement="topPlacement" infoText={infoBoxText1} />
            <Form.Control
              type="text"
              onChange={handleChange}
              placeholder="Indtast dit vejnummer"
              value={formData.streetNumber}
              isInvalid={!!errors.streetNumber}
            />
            {errors.streetNumber ? (
              <Form.Control.Feedback type="invalid">
                {errors.streetNumber}
              </Form.Control.Feedback>
            ) : null}
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} controlId="radius">
            <Form.Label className="formlabel">
              Radius en parkering må være væk
            </Form.Label>
            <Form.Control
              as="select"
              // required
              onChange={handleChange}
              value={formData.radius}
              // defaultValue="Vælg"
              isInvalid={!!errors.radius}
              className={errors.radius ? "dropdown erroricon" : "dropdown"}
            >
              <option disabled value="">
                Vælg
              </option>
              <option value="250 m">250 m</option>
              <option value="500 m">500 m</option>
              <option value="750 m">750 m</option>
              <option value="1.000 m">1.000 m</option>
              <option value="1.250 m">1.250 m</option>
              <option value="1.500 m">1.500 m</option>
              <option value="1.750 m">1.750 m</option>
              <option value="2.000 m">2.000 m</option>
              <option value="Over 2.000 m">Over 2.000 m</option>
            </Form.Control>
            {errors.radius ? (
              <Form.Control.Feedback type="invalid">
                {errors.radius}
              </Form.Control.Feedback>
            ) : null}
          </Form.Group>
          <Form.Group as={Col} controlId="monthlyType">
            <Form.Label className="formlabel">
              Tidsrum du ønsker månedsparkering
              <span className="formlabel-optional"></span>
            </Form.Label>
            <InfoBox placement="topPlacement" infoText={infoBoxText2} />
            <Form.Control
              as="select"
              required
              onChange={handleMonthlyTypeChange}
              value={formData.monthlyType}
              isInvalid={!!errors.monthlyType}
              className={errors.monthlyType ? "erroricon" : ""}
            >
              <option disabled value="">
                Vælg
              </option>
              <option value="24/7 parkering">24/7 parkering</option>
              <option value="Kontortider">Kontortider</option>
              <option value="Udenfor kontortider">Udenfor kontortider</option>
            </Form.Control>
            {errors.monthlyType ? (
              <Form.Control.Feedback type="invalid">
                {errors.monthlyType}
              </Form.Control.Feedback>
            ) : null}
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col} controlId="startDato">
            <Form.Label className="formlabel">Startdato</Form.Label>
            <Form.Control
              type="date"
              placeholder="Indtast startdato"
              required
              min={format(new Date(), "y-MM-dd")}
              max={format(addYears(new Date(), 5), "y-MM-dd")}
              onChange={handleChange}
              value={formData.startDato}
              isInvalid={!!errors.startDato}
            />
            {errors.startDato ? (
              <Form.Control.Feedback type="invalid">  
                {errors.startDato}
              </Form.Control.Feedback>
            ) : null}
          </Form.Group>
          <Form.Group as={Col} controlId="endDato">
            <Form.Label className="formlabel">
              Slutdato
              <span className="formlabel-optional"> (valgfri)</span>
            </Form.Label>
            <InfoBox placement="topPlacement" infoText={infoBoxText3} />
            <Form.Control
              type="date"
              placeholder="Indtast slutdato"
              min={format(new Date(), "y-MM-dd")}
              max={format(addYears(new Date(), 5), "y-MM-dd")}
              onChange={handleChange}
              value={formData.endDato}
              isInvalid={!!errors.endDato}
            />
            {errors.endDato ? (
              <Form.Control.Feedback type="invalid">
                {errors.endDato}
              </Form.Control.Feedback>
            ) : null}
          </Form.Group>
        </Form.Row>
        <Form.Group controlId="msg">
          <Form.Label className="formlabel">
            Besked <span className="formlabel-optional">(valgfri)</span>
          </Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            placeholder="Skriv gerne en besked, særligt hvis du har specielle ønsker, spørgsmål eller andet"
            onChange={handleChange}
            value={formData.msg}
            isInvalid={!!errors.msg}
          />
          {errors.msg ? (
            <Form.Control.Feedback type="invalid">
              {errors.msg}
            </Form.Control.Feedback>
          ) : null}
        </Form.Group>

        <Form.Group className="noSpace">
          <div className="regular-button">
            {loading ? (
              <Button disabled id="btn-submit" type="submit">
                Sender...
              </Button>
            ) : (
              <Button id="btn-submit" type="submit">
                Send
              </Button>
            )}
          </div>
        </Form.Group>
      </Form>
      {!loading ? (
        <FormModal
          isValid={isValid}
          handleClose={handleClose}
          show={show}
          text="Din besked er nu sendt afsted. Vi kontakter dig i den nærmeste fremtid."
        />
      ) : null}
      {/*  <FormModal
        isValid={isValid}
        handleClose={handleClose}
        show={show}
        text="Din besked er nu sendt afsted. Vi kontakter dig i den nærmeste fremtid."
      /> */}
    </div>
  );
};

export default MonthlyWaitinglist;
