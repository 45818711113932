import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../Styles/Components/AboutUs/Instagram.scss";
export default class Instagram extends Component {
  constructor(props) {
    super(props);
    this.state = {
      photos: [],
    };
  }
  async componentDidMount() {
    console.log(
      "Consoloing the photo array before running endpoint",
      this.state.photos
    );
    try {
      console.log("TEST TEST TEST");
      // Forked from https://codesandbox.io/s/react-instagram-gallery-forked-g7kp5?file=/src/index.js:387-474 - https://stackoverflow.com/a/47243409/2217533
      const response = await fetch(
        `https://www.instagram.com/graphql/query?query_id=17888483320059182&variables={"id":"${this.props.userId}","first":${this.props.photoCount},"after":null}`,
        {
          mode: "no-cors",
        }
      );

      console.log("AFTER THE FETCH REQUEST", response);
      const { data } = await response.json();
      console.log("Data from request", data);
      const photos = data.user.edge_owner_to_timeline_media.edges.map(
        ({ node }) => {
          const { id } = node;
          const content = node.edge_media_to_caption.edges[0].node.text;
          const likes = node.edge_media_preview_like;
          const comments = node.edge_media_to_comment;
          const thumbnail = node.thumbnail_resources.find(
            (thumbnail) => thumbnail.config_width === this.props.thumbnailWidth
          );
          const { src, config_width: width, config_height: height } = thumbnail;
          const url = `https://www.instagram.com/p/${node.shortcode}`;
          return {
            id,
            content,
            src,
            width,
            height,
            url,
            likes,
            comments,
          };
        }
      );
      this.setState({ photos });
      console.log(
        "Consoloing the photo array after running endpoint",
        this.state.photos
      );
    } catch (error) {
      console.error("Error:", error);
    }
  }
  render() {
    const settings = {
      dots: false,
      lazyload: true,
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: false,
      autoplaySpeed: 4000,
      cssEase: "linear",
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    const photos = this.state.photos.map(({ src, url }) => {
      return (
        <div className="itemContainer">
          <div className="image">
            <a href={url} target="_blank" rel="noreferrer">
              <img src={src} alt="" />
            </a>
          </div>
        </div>
      );
    });
    return (
      <div className="instagram">
        <h1 className="sectionHeading">
          Ønsker du at se mere om os og vores iværksætterrejse, <br /> kan du se
          meget mere på vores Instagram
        </h1>
        <div className="slider-container">
          <Slider {...settings}> {photos} </Slider>
        </div>
      </div>
    );
  }
}
