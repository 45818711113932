//#region - Imports
// Styling
import "../Styles/Pages/Terms.scss";

// Components
import Meta from "../Components/Meta";
import {COOKIE_NAMES, getCookieValue} from "../Components/Tracking/Cookie";
import {initGA, pageView} from "../Components/Tracking/Tracking";

// Asset
import logo from "../Assets/parkshare-short-logo.png";
//#endregion

const Terms = () => {
  // Track pageview
  if (getCookieValue(COOKIE_NAMES.PERFORMANCE)) {
    initGA();
    pageView();
  }
  return (
    <div className="terms">
      <Meta
        title="Servicevilkår | ParkShare"
        description="Bliv klogere på hvilke regler og vilkår, der gælder ved brugen af vores service. Vores servicevilkår er lavet sådan, at vi sammen får den bedste oplevelse."
      />
      <div className="content">
        <div className="intro">
          <h1>Servicevilkår</h1>
        </div>
        <h2>For lejere generelt</h2>
        <section className="for-drivers">
          <div className="column">
            <h3>Brug af ParkShare som lejer</h3>
            <p>
              Når du booker en parkering igennem ParkShare, opgiver du
              oplysninger om dig selv, dit betalingskort og dit køretøj: Navn,
              kortoplysninger, telefonnummer og registreringsnummeret på den
              gældende bil.
            </p>
            <p>
              Samtidig gemmer vi disse oplysninger på vores server, som
              opbevares efter persondataloven og GDPR-registeret.{" "}
            </p>
            <p>
              Når du booker en parkeringsplads igennem ParkShare, giver du
              samtykke til, at dit navn og registreringsnummeret på din bil,
              bliver sendt videre til udlejeren af parkeringspladsen.{" "}
            </p>
            <h3>Pris for parkering</h3>
            <p>
              Det er ParkShare og ikke udlejer, som sætter prisen på
              parkeringspladser for event- og månedsparkering.{" "}
            </p>
            <div className="li">
              <img src={logo} alt="" />
              Priserne på månedsparkeringer kan variere alt efter geografisk
              placering, tidsinterval og type af parkering.
            </div>
            <div className="li">
              <img src={logo} alt="" />
              Priserne på eventparkering kan variere alt efter geografisk
              placering, antal timer, størrelsen på eventet og efterspørgslen.
            </div>
            <p>
              Du gøres opmærksom på, at priserne kan ændres og det er på eget
              ansvar at holde sig opdateret med prisændringer. Alle anførte
              priser på ParkShare.dk er i danske kroner.{" "}
            </p>
            <h3>Betaling</h3>
            <p>
              Ved betaling af parkering sker overførslen til ParkShare.
              ParkShare tager et servicegebyr på 18%. Ved leje af ubegrænset
              månedsparkering, betaler du som lejer en måneds depositum.
            </p>
            <p>Betalingen for ubegrænset månedsparkering skal ske inden den 1. i hver måned, og skal dermed være på ParkShares konto inden måneden påbegynder.</p>
          </div>
          <div className="column">
            <h3>Hvis en booket parkeringsplads er optaget</h3>
            <p>
              Hvis du som lejer skulle komme ud for, at din bookede
              parkeringsplads er optaget når du ankommer, så vil ParkShare
              refundere det fulde beløb. Hvis du kommer ud for ovenstående
              scenarie, så kontakt ParkShare med det samme.{" "}
            </p>
            <h3>Fortrydelsesret på booking</h3>
            <p>
              Du har som lejer mulighed for at fortryde din booking, når du har
              bestilt en parkeringsplads igennem ParkShare. Hvorvidt du kan
              modtage fuld refundering for din booking, afhænger af hvornår du
              gør brug af din fortrydelsesret.{" "}
            </p>
            <p>Følgende gælder for Eventparkering:</p>
            <div className="li">
              <img src={logo} alt="" />
              Ved fortrydelse &#62;6 timer før start på parkeringen, får du
              refunderet 100% af beløbet.
            </div>
            <div className="li">
              <img src={logo} alt="" />
              Ved fortrydelse 3-6 timer før start på parkeringen, får du
              refunderet 50% af beløbet.
            </div>
            <div className="li">
              <img src={logo} alt="" />
              Ved fortrydelse &#60;3 timer før start på parkeringen, får du ikke
              refunderet noget af beløbet.
            </div>
            <p>Følgende gælder for månedsparkering:</p>
            <div className="li">
              <img src={logo} alt="" />
              Ved fortrydelse &#62;14 dage før start på parkeringen, får du
              refunderet 100% af beløbet.
            </div>
            <div className="li">
              <img src={logo} alt="" />
              Ved fortrydelse 7-14 dage før start på parkeringen, betaler du 50%
              af beløbet den første måned.
            </div>
            <div className="li">
              <img src={logo} alt="" />
              Ved fortrydelse &#60;7 dage før start på parkeringen, betaler du
              det fulde beløb af den første måned.
            </div>
          </div>
          <div className="column">
            <h3>Opsigelsesperiode på månedsparkering</h3>
            <p>
              Hvis du ønsker at opsige din ubegrænsede månedsparkering, skal der
              betales for indeværende måned, samt næste måned. Dit depositum går
              til at dække den næste måned.{" "}
            </p>
            <h3>Vilkår under og efter opsigelse</h3>
            <p>
            Det er ikke tilladt at indgå aftale med nuværende, potentiel, fremtidig eller tidligere udlejer ang. leje af parkeringsplads(er) udenom ParkShare, i en periode på 12 måneder under igangværende eller efter endt lejeperiode.
            </p>
            <h3>Ansvar ved skader eller tyveri</h3>
            <p>
              ParkShare kan ikke holdes ansvarlig for tyveri, skader på
              køretøjer, ulykker, skader på andre genstande eller lignende.
              Parkering og brug af ParkShare sker på eget ansvar, og ParkShare
              kan ikke gøres ansvarlig for økonomiske tab og skader for lejer
              eller udlejer.{" "}
            </p>
            <h3>Kvittering</h3>
            <p>
              Når du bestiller igennem ParkShare.dk tilkendegiver du hvorvidt du
              ønsker at modtage en kvittering på dit køb af parkering eller ej.
              Hvis du takker ”Ja” til at modtage en kvittering, vil denne blive
              sendt over den mailadresse du har tilkendegivet.
            </p>
            <h3>Klager eller indsigelser </h3>
            <p>
              Hvis du har nogle klager eller indsigelser til ParkShare, skal du
              kontakte ParkShare på enten telefon eller mail:
            </p>
            <div className="li">
              <img src={logo} alt="" />
              +45 42 72 99 88
            </div>
            <div className="li">
              <img src={logo} alt="" />
              info@ParkShare.dk
            </div>
            <h3>Ændringer af regler og vilkår</h3>
            <p>
              ParkShare forbeholder sig retten til at kunne ændre ”Regler og
              Vilkår” løbende. Det er derfor dig som lejers eget ansvar at holde
              sig opdateret med eventuelle ændringer på ParkShare.dk.{" "}
            </p>
            {/*    <h3>Privacy policy</h3>
               <p>
                In order to get an even better overview of ParkShare's services,
                read the <a href="/privacy-policy"> Privacy Policy. </a>
              </p> */}
            <h3>Privatlivspolitik</h3>
            <p>
              For at få en bedre overblik over ParkShare's tjenester og service,
              læs venligst vores{" "}
              <a href="/privacy-policy"> Privatlivspolitik. </a>
            </p>
          </div>
        </section>
        <h2>For udlejere generelt</h2>
        <section className="for-owners">
          <div className="column">
            <h3>Brug af ParkShare som udlejer</h3>
            <p>
              Når du tilbyder en parkering til udlejning igennem ParkShare,
              opgiver du oplysninger om dig selv, din adresse og dine
              kontooplysninger: Navn, adresse, kontooplysninger og
              telefonnummer.
            </p>
            <p>
              Samtidig gemmer vi disse oplysninger på vores server, som
              opbevares efter persondataloven og GDPR-registeret.{" "}
            </p>
            <p>
              Vi bruger dine kontooplysninger til at udbetale dine optjente
              penge igennem ParkShare. Hvorvidt du har ret til at modtage
              registreringsnummeret på bilen af lejeren, afhænger af typen af
              parkering:
            </p>
            <div className="li">
              <img src={logo} alt="" />
              Hvis du udlejer din parkeringsplads på månedlig basis igennem
              ParkShare, vil du modtage registreringsnummeret på bilen af
              lejeren, hvis du ønsker det.
            </div>
            <div className="li">
              <img src={logo} alt="" />
              Hvis du udlejer din parkeringsplads til events igennem ParkShare,
              er du ikke berettiget til at modtage registreringsnummeret på
              bilen af lejeren.
            </div>
            <p>
              Hvis du udlejer din parkeringsplads på månedlig basis og dermed
              modtager oplysninger på lejeren, er du underlagt et krav om at du
              ikke må videregive disse fortrolige oplysninger til tredjemand.{" "}
            </p>
            <p>
              Din adresse, billede af parkeringspladsen og eventuelt din bolig
              samt parkeringstype, vil blive fremvist på ParkShare.dk og
              eventuelt sociale medier, for potentielle lejere og besøgende på
              hjemmesiden som ønsker at booke en parkeringsplads.
            </p>
            <h3>Pris for parkering</h3>
            <p>
              Det er ParkShare og ikke dig som udlejer, som sætter prisen på
              parkeringspladser for event- og månedsparkering.{" "}
            </p>
            <div className="li">
              <img src={logo} alt="" />
              Priserne på månedsparkeringer kan variere alt efter geografisk
              placering, tidsinterval og type af parkering.
            </div>
            <div className="li">
              <img src={logo} alt="" />
              Priserne på eventparkering kan variere alt efter antal timer,
              geografisk placering, størrelsen på eventet og efterspørgslen.
            </div>
            <p>
              Du gøres opmærksom på, at priserne kan ændres og det er udlejers
              eget ansvar at holde sig opdateret med priserne. Alle anførte
              priser på ParkShare.dk er i danske kroner.{" "}
            </p>
            <h3>Overførsel af din optjente indtægt og servicegebyr</h3>
            <p>
              Den indtægt som du optjener igennem ParkShare, bliver opgjort den
              sidste dag i den gældende måned og sendt til dig efterfølgende.
              Der går 1-5 bankdage, før pengene er på din konto.{" "}
            </p>
            <p>
              Det er udlejers ansvar at sikre det oplyste konto nummer og registrerings nummer er korrekt.
              Er pengene overført til en forkert konto grundet forkerte oplysninger givet af Udlejer, kan ParkShare ikke stilles til ansvar og pengene er tabte.
            </p>
          </div>
          <div className="column">
            <p>
              Efter den månedlige opgørelse modtager du som udlejer 82% af den
              samlede lejeindtægt, når du udlejer din parkeringsplads igennem
              ParkShare. De resterende 18% tager ParkShare i servicegebyr for at
              kunne drive virksomhed.{" "}
            </p>
            <h3>Udlejers ansvar for parkeringspladsen</h3>
            <p>
              Når du tilbyder din parkering til udlejning igennem ParkShare.dk,
              forpligter du dig samtidig til ikke at udleje din parkeringsplads
              igennem andre platforme eller services end ParkShare.{" "}
            </p>
            <p>
              Yderligere er du som udlejer forpligtet til at sørge for komplet
              fri adgang til din parkeringsplads, når den er udlejet.
              Alle former for forhindringer, som kunne spærre adgangen til og fra parkeringspladsen skal fjernes.
              I tilfælde af sne, er det også udlejers ansvar at der er ryddet sne og saltet på parkeringspladsen, og at der er fri passage til og fra parkeringspladsen.
              Bilen og parkeringspladsen må på intet tidspunkt være spærret eller låst inde. Dette er udelukkende dit ansvar som udlejer.{" "}
            </p>
            <h3>Retten til at annullere sin udlejning af parkeringsplads</h3>
            <p>
              Du har som udlejer mulighed for at annullere din udlejning, når du
              har udlejet din parkeringsplads igennem ParkShare. Hvis din
              parkeringsplads ikke er booket af en lejer, kan du godt annullere
              din udlejningsperiode, også med kort varsel. Kontakt ParkShare
              hvis du ønsker at annullere din udlejningsperiode.{" "}
            </p>
            <p> Følgende gælder for Eventparkering hvis den er booket:</p>
            <div className="li">
              <img src={logo} alt="" />
              Hvis din parkeringsplads til et event allerede er booket af en
              bilist, kan du ikke blot annullere din udlejning. Annullering kan
              kun finde sted &#62; 48 timer før starttiden for hvornår
              parkeringspladsen er booket.
            </div>
            <p>Følgende gælder for Månedsparkering hvis den er booket:</p>
            <div className="li">
              <img src={logo} alt="" />
              Ved fortrydelse &#62;14 dage før start på bookingen, kan du frit
              annullere din udlejning på månedsparkering.
            </div>
            <div className="li">
              <img src={logo} alt="" />
              Ved fortrydelse &#8804;14 dage før start på bookingen, er du
              forpligtiget til at udleje den første måned.
            </div>
            <h3>
              Opsigelsesperiode på månedsparkering med ubegrænset slutdato
            </h3>
            <p>
              Som udlejer er der på månedsparkering to forskellige
              opsigelsesperioder. Hvis du ønsker at stoppe din udlejning og
              opsiger din udlejning i perioden 1.-14. i måneden, er den kun
              udlejet i indeværende måned, og vil være tilgængelig for dig selv
              i den efterfølgende måned.{" "}
            </p>
          </div>
          <div className="column">
            <p>
              Opsiger du udlejningen 15.-31. i måneden, er den udlejet i den
              resterende del af indeværende måned, samt næste måned. Kontakt
              ParkShare hvis du ønsker at opsige din udlejning.
            </p>
            <h3>Vilkår under og efter opsigelse</h3>
            <p>
              Det er ikke tilladt at indgå aftale med nuværende, potentiel, fremtidig eller tidligere lejer ang. leje af din plads(er) udenom ParkShare, i en periode på 12 måneder under igangværende eller efter endt lejeperiode.
            </p>
            <h3>Vilkår for udlejer når lejer annullerer en booking</h3>
            <p>Følgende gælder for Eventparkering:</p>
            <div className="li">
              <img src={logo} alt="" />
              Hvis lejer annullerer sin booking &#62;6 timer fra booking
              begynder får du som udlejer ikke noget.
            </div>
            <div className="li">
              <img src={logo} alt="" />
              Annullerer lejer 3-6 timer inden bookingen begynder får du som
              udlejer 25% af beløbet.
            </div>
            <div className="li">
              <img src={logo} alt="" />
              Annullerer lejer &#60;3 timer fra bookingen begynder og inden
              bookingen starter, får du som udlejer 50% af beløbet.
            </div>
            <div className="li">
              <img src={logo} alt="" />
              Annullerer lejer efter bookingen er startet får du som udlejer
              100%.
            </div>
            <p>
              Hvis din parkeringsplads bliver annulleret og en anden lejer
              booker den, får du som udlejer blot et større beløb.
            </p>
            <p>Følgende gælder for Månedsparkering:</p>
            <div className="li">
              <img src={logo} alt="" />
              Annullerer lejer &#62;14 dage inden bookningen begynder, får du
              som udlejer ikke noget af beløbet.
            </div>
            <div className="li">
              <img src={logo} alt="" />
              Annullerer lejer 7-14 dage inden bookningen begynder, får du som
              udlejer 25% af beløbet for den første måned.
            </div>
            <div className="li">
              <img src={logo} alt="" />
              Annullerer lejer &#60;7 dage før start på parkeringen, får du som
              udlejer 50% af beløbet for den første måned.
            </div>
            <h3>Indberetning af skat på indtjente penge igennem ParkShare</h3>
            <p>
              Som udlejer og bruger af ParkShare, vil du optjene et beløb der
              udbetales på din opgivne konto. Det er dit eget ansvar som udlejer
              at indberette til skattevæsenet.{" "}
            </p>
            <h3>Ansvar ved skader eller andet</h3>
            <p>
              ParkShare kan ikke holdes ansvarlig for skader på køretøjer,
              ejendom, ulykker, skader på andre genstande eller lignende.
              Parkering og brug af ParkShare sker på eget ansvar, og ParkShare
              kan ikke gøres ansvarlig for økonomiske tab og skader for lejer
              eller udlejer.
            </p>
            <h3>Klager eller indsigelser</h3>
            <p>
              Hvis du har nogle klager eller indsigelser til ParkShare, skal du
              kontakte ParkShare på enten mail eller telefon:
            </p>
            <div className="li">
              <img src={logo} alt="" />
              +45 42 72 99 88
            </div>
            <div className="li">
              <img src={logo} alt="" />
              info@ParkShare.dk
            </div>
            <h3>Ændringer af regler og vilkår</h3>
            <p>
              ParkShare forbeholder sig retten til at kunne ændre ”Regler og
              Vilkår” løbende. Det er derfor dig som udlejerens eget ansvar at
              holde dig opdateret med eventuelle ændringer på ParkShare.dk.{" "}
            </p>
            <h3>Privatlivspolitik</h3>
            <p>
              For at få en bedre overblik over ParkShare's tjenester og service,
              læs venligst vores{" "}
              <a href="/privacy-policy"> Privatlivspolitik. </a>
            </p>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Terms;
